// UI IMPORT
import { Button, ControlledDropDown, Input, Loader } from "../../ui";
import CopyToClipboard from "react-copy-to-clipboard";

// PROJECT IMPORT
import {
  useAddAdminMutation,
  useLazyGetAdminPermissionsQuery,
  useLazyGetAdminRolesQuery,
  useUpdateAdminMutation,
} from "./adminApi";
import { responseToaster, showToaster } from "../../helperFunctions";

// THIRD- PARTY IMPORT
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { usePermissions, useRoles } from "./adminSlice";
import { useUser } from "../auth/authSlice";

const RoleModal = (props: any) => {
  const { isOpen, data, onHide, onGet } = props;
  const sidebarOption = useUser();
  const roles = useRoles();
  const permissions = usePermissions();

  const [addAdmin, { isLoading: isAddLoading }] = useAddAdminMutation();
  const [updateAdmin, { isLoading: isUpdateLoading }] =
    useUpdateAdminMutation();
  const [getPermissions] = useLazyGetAdminPermissionsQuery();
  const [getRoles] = useLazyGetAdminRolesQuery();
  const [isCopy, setIsCopy] = useState<any>();

  const {
    handleSubmit,
    reset,
    register,
    setValue,
    control,
    formState: { errors },
  } = useForm({});

  const onClose = () => {
    reset();
    onHide();
    setIsCopy("");
  };
  const onSubmit = async (values: any) => {
    try {
      if (data?.id) {
        const payload = {
          ...values,
          id: data?.id,
        };
        const res: any = await updateAdmin(payload).unwrap();
        onGet();
        responseToaster(res);

        onClose();
      } else {
        const payload = {
          ...values,
        };
        const res: any = await addAdmin(payload).unwrap();
        onGet();
        setIsCopy(res);
        responseToaster(res);
      }
    } catch (err: any) {
      console.error("Error =-=>", err);
    }
  };

  const prepareRolesOptions = () => {
    const options =
      roles?.map((item) => ({
        value: item,
        label: item,
      })) || [];
    return [
      { value: "All", label: "All" },
      ...(options?.length ? options : []),
    ];
  };

  const preparePermissionOptions = () => {
    const options = permissions?.map((item) => ({
      value: item,
      label: item,
    }));
    return [
      { value: "All", label: "All" },
      ...(options?.length ? options : []),
    ];
  };

  const prepareSidebarPermission = () => {
    return sidebarOption?.sidebar
      ?.map((item: any) => {
        if (item?.name === "Role Manager") {
          return null; // Return null to skip this item
        }
        return {
          value: item?.name,
          label: item?.name,
        };
      })
      .filter(Boolean); // Filter out null values
  };

  useEffect(() => {
    getPermissions();
    getRoles();

    if (isOpen) {
      getPermissions();
      getRoles();

      const preparePermision = () => {
        return data?.permissions?.map((item: any) => {
          return item?.name;
        });
      };
      const preparesRoles = () => {
        return data?.roles?.map((item: any) => {
          return item?.name;
        });
      };
      const prepareSidebar = () => {
        return data?.sidebar?.map((item: any) => {
          return item;
        });
      };

      if (data?.id) {
        setValue("name", data?.name);
        setValue("email", data?.email);
        setValue("roles", preparesRoles());
        setValue("permissions", preparePermision());
        setValue("sidebar", prepareSidebar());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isOpen]);

  if (!isOpen) return null;

  return (
    <>
      {isCopy?.password ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div
              className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
              onClick={() => {
                onClose();
              }}
            />
            <div className="modal-container bg-white w-5/12 md:w-8/12 sm:w-10/12 md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
              <div className="p-6 text-left px-6">
                <div className="pb-3 text-start">
                  <p className="text-2xl font-bold pb-2">Password</p>
                </div>
                <div
                  style={{
                    padding: 50,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span>
                    <span>Password :- </span>
                    <span className="bold">{isCopy?.password}</span>
                  </span>
                </div>
                <div className="flex justify-center pt-2 items-center gap-3">
                  <CopyToClipboard text={`${isCopy?.password}`}>
                    <Button
                      onClick={() => {
                        onClose();
                        showToaster("Copied");
                      }}
                    >
                      Copy & Close
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <form
          className="fixed inset-0 z-50 flex items-center justify-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
            onClick={() => {
              onClose();
            }}
          />

          <div className="modal-container bg-white w-5/12 md:w-8/12 sm:w-10/12 md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
            <div className="p-6 text-left px-6">
              <div className="pb-3 text-start">
                <p className="text-2xl font-bold pb-2">
                  {data?.id ? "Edit" : "Add"} Role
                </p>
              </div>

              <div className="w-full grid grid-cols-2 gap-2 ">
                <Input
                  className="w-full"
                  name="name"
                  label="Name"
                  placeholder="Enter a name"
                  register={register}
                  errors={errors}
                />
                <Input
                  className="w-full"
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Enter a email"
                  register={register}
                  errors={errors}
                />

                <ControlledDropDown
                  name="roles"
                  label="Roles"
                  isMulti
                  isClearable={true}
                  options={prepareRolesOptions()}
                  control={control}
                  errors={errors}
                  onChange={(e: any) => {
                    if (e?.includes("All")) {
                      setValue("roles", roles);
                    } else {
                      setValue("roles", e);
                    }
                  }}
                />
                <ControlledDropDown
                  name="permissions"
                  label="Permissions"
                  isMulti
                  isClearable={true}
                  options={preparePermissionOptions()}
                  control={control}
                  errors={errors}
                  onChange={(e: any) => {
                    if (e?.includes("All")) {
                      setValue("permissions", permissions);
                    } else {
                      setValue("permissions", e);
                    }
                  }}
                />
                <ControlledDropDown
                  name="sidebar"
                  label="Sidebar"
                  placeholder="Select Sidebar"
                  isMulti
                  options={prepareSidebarPermission()}
                  control={control}
                  errors={errors}
                />
              </div>
              <div className="flex justify-end pt-2 items-center gap-3">
                <Button onClick={onClose}>Close</Button>
                {isAddLoading || isUpdateLoading ? (
                  <div className="flex item-center justify-center">
                    <Loader />
                  </div>
                ) : (
                  <Button type="submit">{data?.id ? "Update" : "Add"}</Button>
                )}
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default RoleModal;
