import { api } from "../../utills/api";

export const domainApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDomain: build.mutation<{ data: any }, any>({
      query: (params) => ({ 
        url: "/getDomain",
        body: params,
        method: "POST",
      }),
    }),
    addDomain: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/addDomain",
        body: params,
        method: "POST",
      }),
    }),
    updateDomain: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateDomain",
        body: params,
        method: "POST",
      }),
    }),
    deleteDomain: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/deleteDomain",
        body: params,
        method: "POST",
      }),
    }),
    updateDomainStatus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateDomainStatus",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetDomainMutation,
  useAddDomainMutation,
  useUpdateDomainMutation,
  useDeleteDomainMutation,
  useUpdateDomainStatusMutation,
} = domainApi;
export const {
  endpoints: {
    getDomain,
    addDomain,
    updateDomain,
    deleteDomain,
    updateDomainStatus,
  },
} = domainApi;
