import { useMemo } from "react";

// PROJECT IMPORT
import { RootState } from "../../utills/store";
import { dashboardApi } from "./dashboardApi";

// THIRD - PARTY IMPORT
import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

interface DashboardState {
  summaryItems: any;
  merchantList: any;
  depositChart: any,
  withdrawalChart: any,
  userCounts:any,  
}

const initialState: DashboardState = {
  merchantList: {},
  summaryItems: {},
  depositChart: {},
  withdrawalChart: {},
  userCounts:{},
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearDashboard: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      dashboardApi.endpoints.getSummary.matchFulfilled,
      (state, { payload }) => {
        state.summaryItems = payload?.data || {};
      }
    );
    builder.addMatcher(
      dashboardApi.endpoints.getUsersCount.matchFulfilled,
      (state, { payload }) => {
        state.userCounts = payload?.data || {};
      }
    );
    builder.addMatcher(
      dashboardApi.endpoints.getMerchantList.matchFulfilled,
      (state, { payload }) => {
        state.merchantList = payload || {};
      }
    );
    builder.addMatcher(
      dashboardApi.endpoints.getDepositChart.matchFulfilled,
      (state, { payload }) => {
        state.depositChart = payload || {};
      }
    );
    builder.addMatcher(
      dashboardApi.endpoints.getWithdrawalChart.matchFulfilled,
      (state, { payload }) => {
        state.withdrawalChart = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default dashboardSlice.reducer;
export const { clearDashboard } = dashboardSlice.actions;

export const selectSummaryItems = (state: RootState) =>
  state.dashboard.summaryItems;
export const useSummaryItems = () => {
  const summaryItems = useSelector(selectSummaryItems);
  return useMemo(() => summaryItems, [summaryItems]);
};

export const selectUserCounts = (state: RootState) =>
  state.dashboard.userCounts;
export const useUserCounts = () => {
  const userCounts = useSelector(selectUserCounts);
  return useMemo(() => userCounts, [userCounts]);
};

export const selectMerchantList = (state: RootState) =>
  state.dashboard.merchantList;
export const useMerchantList = () => {
  const merchantList = useSelector(selectMerchantList);
  return useMemo(() => merchantList, [merchantList]);
};

export const selectDepositChart = (state: RootState) =>
  state.dashboard.depositChart;
export const useDepositChart = () => {
  const depositChart = useSelector(selectDepositChart);
  return useMemo(() => depositChart, [depositChart]);
};

export const selectWithdrawalChart = (state: RootState) =>
  state.dashboard.withdrawalChart;
export const useWithdrawalChart = () => {
  const withdrawalChart = useSelector(selectWithdrawalChart);
  return useMemo(() => withdrawalChart, [withdrawalChart]);
};