import { api } from "../../utills/api";

export const customApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBonus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/CampaignList",
        body: params,
        method: "POST",
      }),
    }),
    updateStatus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateCampaignStatus",
        body: params,
        method: "POST",
      }),
    }),
    deleteData: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/deleteCampaign",
        body: params,
        method: "POST",
      }),
    }),
    addCustom: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/addUpdateCustom",
        body: params,
        method: "POST",
      }),
    }),
    addUpdateCustom: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/addUpdateCustom",
        body: params,
        method: "POST",
      }),
    }),
    updateBonusStatus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateCampaignStatus",
        body: params,
        method: "POST",
      }),
    }),
    // getPlayerList: build.query<{ data: any }, void>({
    //   query: () => ({
    //     url: `/playerList`,
    //   }),
    // }),
    getPlayerList: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/playerList",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetBonusMutation,
  useUpdateStatusMutation,
  useDeleteDataMutation,
  useAddCustomMutation,
  useAddUpdateCustomMutation,
  useUpdateBonusStatusMutation,
  useGetPlayerListMutation,
} = customApi;

export const {
  endpoints: {
    getBonus,
    updateStatus,
    deleteData,
    addCustom,
    addUpdateCustom,
    updateBonusStatus,
    getPlayerList,
  },
} = customApi;
