import { useMemo } from "react";

// PROJECT IMPORT
import { RootState } from "../../utills/store";
import { adminApi } from "./adminApi";

// THIRD - PARTY IMPORT
import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

interface AdminState {
  data: any;
  roles: any[];
  permissions: any[];
  currentData: any | null;
}

const initialState: AdminState = {
  data: {},
  roles: [],
  permissions: [],
  currentData: null,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    clearAdmin: () => initialState,
    setCurrentAdmin: (state, { payload }) => {
      state.currentData = payload || null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      adminApi.endpoints.getAdmins.matchFulfilled,
      (state, { payload }) => {
        state.data = payload || {};
      }
    );
    builder.addMatcher(
      adminApi.endpoints.getAdminRoles.matchFulfilled,
      (state, { payload }) => {
        state.roles = payload?.data?.roles || {};
      }
    );
    builder.addMatcher(
      adminApi.endpoints.getAdminPermissions.matchFulfilled,
      (state, { payload }) => {
        state.permissions = payload?.data?.permissions || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default adminSlice.reducer;
export const { clearAdmin, setCurrentAdmin } = adminSlice.actions;

export const selectAdmins = (state: RootState) => state.admin.data;
export const useAdmins = () => {
  const data = useSelector(selectAdmins);
  return useMemo(() => data, [data]);
};

export const selectRoles = (state: RootState) => state.admin.roles;
export const useRoles = () => {
  const roles = useSelector(selectRoles);
  return useMemo(() => roles, [roles]);
};

export const selectPermissions = (state: RootState) => state.admin.permissions;
export const usePermissions = () => {
  const permissions = useSelector(selectPermissions);
  return useMemo(() => permissions, [permissions]);
};

export const selectCurrentAdmin = (state: RootState) => state.admin.currentData;
export const useCurrentAdmin = () => {
  const currentData = useSelector(selectCurrentAdmin);
  return useMemo(() => currentData, [currentData]);
};
