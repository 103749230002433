import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { masterApi } from "./masterApi";

interface MasterState {
  masterData: any,
}

const initialState: MasterState = {
  masterData: {},
};

export const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {
    clearSupportlog: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
        masterApi.endpoints.getMasterList.matchFulfilled,
        (state, { payload }) => {
          state.masterData = payload || {};
        }
    );
  },
});

// Action creators are generated for each case reducer function
export default masterSlice.reducer;

export const selectMasterData = (state: RootState) =>
    state.master.masterData;
export const useMasterData = () => {
  const masterData = useSelector(selectMasterData);
  return useMemo(() => masterData, [masterData]);
};