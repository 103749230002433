import { api } from "../../utills/api";

export const permissionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPermissions: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/permissions",
        body: params,
        method: "POST",
      }),
    }),
    getPermission: build.query<{ data: any }, any>({
      query: ({ id }) => ({
        url: `/permission/${id}`,
        method: "GET",
      }),
    }),
    addPermission: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/permission/store",
        body: params,
        method: "POST",
      }),
    }),
    updatePermission: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/permission/update",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetPermissionsMutation,
  useGetPermissionQuery,
  useAddPermissionMutation,
  useUpdatePermissionMutation,
} = permissionApi;

export const {
  endpoints: {
    getPermissions,
    getPermission,
    addPermission,
    updatePermission,
  },
} = permissionApi;
