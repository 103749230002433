// PROJECT IMPORT
import { clearAuth } from "./features/auth/authSlice";
import { clearCommon } from "./features/common/commonSlice";
import { clearDashboard } from "./features/dashboard/dashboardSlice";
import { clearDeposit } from "./features/deposit/depositSlice";
import { clearWithdrawal } from "./features/withdrawal/withdrawalSlice";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

// THIRD - PARTY IMPORT
import { toast } from "react-hot-toast";

const root = window.document.documentElement;

export const kFormatter = (num) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(2).replace(/\.0$/, "") + "K";
  }
  return num;
};

export const logout = async (dispatch) => {
  dispatch(clearAuth());
  dispatch(clearCommon());
  dispatch(clearDashboard());
  dispatch(clearDeposit());
  dispatch(clearWithdrawal());
};

export const downloadFile = (url, type, name) => {
  let element = document.createElement("a");
  let file = new Blob([url], { type: `${type}/*` });
  element.href = URL.createObjectURL(file);
  element.download = name;
  element.click();
};

export const showToaster = (message, type = "Success") => {
  switch (type) {
    case "Error":
      toast.error(message || "Something Went Wrong!", {
        position: "top-right",
      });
      break;
    case "Success":
      toast.success(message, {
        position: "top-right",
      });
      break;
    case "Warning":
      toast.custom(
        (t) => (
          <div className="custom-toast">
            <WarningAmberIcon />
            <span>{message}</span>
          </div>
        ),
        {
          position: "top-right",
        }
      );
      break;
    default:
      toast.success(message, {
        position: "top-right",
      });
      break;
  }
};

export const responseToaster = (res) => {
  if (res) {
    showToaster(res.message, res?.status ? "Success" : "Warning");
  } else {
    showToaster("Something Went Wrong!", "Error");
  }
};

export const changeFavicon = (newFaviconUrl) => {
  const favicon = window.document.querySelector('link[rel="icon"]') || {};
  favicon.href = newFaviconUrl;
};

export const setStyles = (varr, color) => {
  root?.style.setProperty(varr, color);
};
export const dateFormatter = (d, type, format) => {
  if (!d) {
    return null;
  }
  const date = new Date(d);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const seconds = date.getSeconds();

  if (format === "dd-mm-yy") {
    return `${day?.toString()?.padStart(2, "0")}-${month
      ?.toString()
      ?.padStart(2, "0")}-${year}${
      type === "end"
        ? " 23:59:59"
        : type === "start"
        ? " 00:00:00"
        : type === "Default"
        ? ""
        : " " +
          hour?.toString()?.padStart(2, "0") +
          ":" +
          minute?.toString()?.padStart(2, "0") +
          ":" +
          seconds?.toString()?.padStart(2, "0")
    }`;
  }

  return `${year}-${month?.toString()?.padStart(2, "0")}-${day
    ?.toString()
    ?.padStart(2, "0")}${
    type === "date"
      ? ""
      : type === "end"
      ? " 23:59:59"
      : type === "start"
      ? " 00:00:00"
      : type === "Default"
      ? ""
      : " " +
        hour?.toString()?.padStart(2, "0") +
        ":" +
        minute?.toString()?.padStart(2, "0") +
        ":" +
        seconds?.toString()?.padStart(2, "0")
  }`;
};

// export const seperator = (x: any, rupee: any = true) => {
//   const f = new Intl.NumberFormat("en-IN", {
//     style: "currency",
//     currency: "INR",
//   });
//   const val = f.format(Math.abs(+x) || 0)?.toString();

//   const newValues = val?.split(".");
//   let newVal = val;
//   if (newValues?.length === 2 && newValues?.at(-1) === "00") {
//     newVal = newValues?.[0];
//   }
//   if (!rupee) {
//     newVal = newVal?.slice(1);
//   }

//   return x?.toString()?.slice(0, 1) === "-" ? `-${newVal}` : newVal;
// };

export const seperator = (amount, isRupee = true) => {
  const f = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  const newAmount = f.format(+amount || 0);
  const newAmounts = newAmount?.split(".");
  if (newAmounts?.length > 1 && newAmounts?.[1] === "00") {
    if (!isRupee) {
      return newAmounts?.[0] ? newAmounts?.[0]?.slice(1) : "0";
    }
    return (
      newAmounts?.[0]?.slice(0, 1) + " " + newAmounts?.[0]?.slice(1) || "₹ 0"
    );
  }
  if (!isRupee) {
    return newAmount ? newAmount?.slice(1) : "0";
  }
  return newAmount?.slice(0, 1) + " " + newAmount?.slice(1);
};

export const prepareMaxDate = (startDate, endDate, maxDay = 30) => {
  if (!startDate || (startDate && endDate)) {
    return new Date();
  }
  const d = new Date(startDate);
  d.setDate(d.getDate() + maxDay);
  return d > new Date() ? new Date() : d;
};

export const prepareLastDay = (date) => {
  if (!date) {
    return 28;
  }
  const t = new Date(date);
  const lastDay = new Date(
    t.getFullYear(),
    t.getMonth() + 1,
    0,
    23,
    59,
    59
  )?.getDate();
  return lastDay;
};

export const prepareArray = (start, end) => {
  if (!start || !end) {
    return [];
  }

  const array = [];
  for (let i = start; i <= end; i++) {
    array.push(i);
  }
  return array;
};

export const prepareMinValue = (array) => {
  return array.reduce(
    (min, current) => (current < min ? current : min),
    array?.[0]
  );
};

export const prepareCommonDates = (startDate, endDate) => {
  const lastDays = [];
  const currentDate = new Date(startDate);
  const lastDate = new Date(endDate);
  currentDate.setDate(1);
  lastDate.setDate(1);

  while (currentDate <= lastDate) {
    lastDays.push(prepareLastDay(currentDate));
    currentDate.setMonth(currentDate.getMonth() + 1);
  }
  const allDays = prepareArray(1, prepareMinValue(lastDays));
  return allDays;
};

export const getAllOptions = (key, subkey) => {
  const options = {
    support: {
      usual: "Usual",
      priority: "Priority",
    },
    withdraw: {
      panel: "Panel",
      fast_withdrawals_via_vip: "Fast Withdrawals via VIP",
    },
  };
  return options[key][subkey] ?? "NA";
};
export const sortByKey = (data, name, sort = "Asce") => {
  const newData = data?.length ? [...data] : [];
  const isDesc = sort === "Desc";
  const keys = name.split(".");

  const getValue = (obj, keys) => {
    return keys.reduce((acc, key) => acc?.[key], obj);
  };

  const compare = (a, b) => {
    const s = getValue(a, keys);
    const l = getValue(b, keys);
    if (s?.toString()?.toLowerCase() < l?.toString()?.toLowerCase()) {
      return isDesc ? 1 : -1;
    }
    if (s?.toString()?.toLowerCase() > l?.toString()?.toLowerCase()) {
      return isDesc ? -1 : 1;
    }
    return 0;
  };
  return newData.sort(compare);
};

export const sortByDate = (data, name, sort = "Asce") => {
  const sortedAscending = data
    .slice()
    .sort((a, b) => new Date(a?.[name]) - new Date(b?.[name]));

  const sortedDescending = data
    .slice()
    .sort((a, b) => new Date(b?.[name]) - new Date(a?.[name]));
  return sort === "Asce" ? sortedAscending : sortedDescending;
};

export const prepareObjTotal = (obj) => {
  let total = 0;
  Object.entries(obj || {})?.forEach(([key, item]) => {
    total += +item || 0;
  });
  return total;
};

export const isNegativeZero = (num) => {
  return num === 0 && 1 / num === -Infinity;
};
export const isPositiveZero = (num) => {
  return num === 0 && 1 / num === Infinity;
};
export function isMoreThanZero(num) {
  return num === 0 && String(num).length > 1;
}

export const prepareBlobUrl = (svgString, type = "image/svg+xml") => {
  const blob = new Blob([svgString], { type });
  return URL.createObjectURL(blob);
};

export const ordinalToCardinal = (num) => {
  let suffix = "";
  if (num % 100 >= 11 && num % 100 <= 13) {
    suffix = 'th';
  } else {
    switch (num % 10) {
      case 1:
        suffix = 'st';
        break;
      case 2:
        suffix = 'nd';
        break;
      case 3:
        suffix = 'rd';
        break;
      default:
        suffix = 'th';
    }
  }
  return num + suffix;
}

export const processCSV = (str, delim = ",") => {
  const headers = str.slice(0, str.indexOf("\n")).split(delim);
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  const newArray = rows.map((row) => {
    const values = row.split(delim);
    const eachObject = headers.reduce((obj, header, i) => {
      obj[header] = values[i];
      return obj;
    }, {});
    return eachObject;
  });
  return { header: headers, data: newArray };
};
