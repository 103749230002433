import Select from "react-select";

const DropDown = (props) => {
  const {
    defaultValue,
    options = [],
    name,
    className = "",
    inputClassName = "",
    label,
    value,
    styles = {},
    isMulti,
    onChange,
    errors,
    width,
    placeholder,
    isClearable = false,
    isDisabled = false,
    ...rest
  } = props;
  const message = errors?.[name]?.message ? errors?.[name]?.message : "";
  const getOptions = () => {
    return placeholder
      ? [{ value: "", label: placeholder }, ...options]
      : options;
  };

  return (
    <div className={`${className} select-div`}>
      {label ? (
        <label className={`text-sm text-ev-secondary pb-[4px]`}>{label}</label>
      ) : null}
      <Select
        name={name}
        isDisabled={isDisabled}
        menuPosition="fixed"
        styles={{
          control: (provided) => ({
            ...provided,
            width: "width",
            minHeight: "40px",
            height: "40px",
            borderRadius: "6px",
            borderColor: message ? "#ef4444" : "#222A40",
            background: "var(--chatlook-secondary--)",
            color: "#fff",
            fontSize: 12,
            zIndex: 99999,
          }),
          valueContainer: (provided) => ({
            ...provided,
            overflowX: "scroll",
            flexWrap: "nowrap",
            marginRight: "0px",
            fontSize: 12,
          }),
          multiValue: (provided) => ({
            ...provided,
            minWidth: "unset",
            background: "#1b2130",
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: "#fff",
          }),
          menu: (style) => ({
            ...style,
            zIndex: 999999,
            borderRadius: "10px",
            width: "fit-content",
          }),
          menuList: (style) => ({
            ...style,
            // zIndex: 99999,
            padding: "9px",
            background: "var(--chatlook-secondary--)",
            border: "1px solid white",
          }),
          input: (style) => ({
            ...style,
            fontSize: 14,
            color: "#fff",
          }),
          singleValue: (style) => ({
            ...style,
            fontSize: 14,
            color: "#6e6b7b",
          }),
          placeholder: (style) => ({
            ...style,
            fontSize: 12,
            color: "#fff",
          }),
          option: (style) => ({
            ...style,
            padding: "2px 5px",
            fontSize: 13,
            whiteSpace: "nowrap",
            width: "100%",
            color: "var(--ev-secondary--)",
            backgroundColor: "var(--chatlook-secondary--)",
          }),
          menuPortal: (style) => ({
            ...style,
            zIndex: 9999999,
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: "0 5px",
          }),
          container: (style) => ({
            ...style,
            marginRight: 0,
          }),
          ...styles,
        }}
        onChange={(e, option) => {
          if (isMulti) {
            onChange?.(
              e?.map((item) => item?.value),
              option,
              e
            );
          } else {
            onChange?.(e.value, option, e);
          }
        }}
        className={`${inputClassName} w-100`}
        options={getOptions()}
        isMulti={isMulti}
        isClearable={isClearable}
        value={
          isMulti
            ? options?.filter((option) => value?.includes(option?.value))
            : options?.find((option) => option?.value === value) || ""
        }
        defaultValue={options?.[0]?.label}
        placeholder={placeholder}
        {...rest}
      />
      {message ? (
        <span style={{ color: "#ef4444", fontSize: 12 }}>{message}</span>
      ) : null}
    </div>
  );
};

export default DropDown;
