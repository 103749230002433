// UI IMPORT
import { Button } from "../../ui";

// PROJECT IMPORT
import { showToaster } from "../../helperFunctions";

// THIRD- PARTY IMPORT
import CopyToClipboard from "react-copy-to-clipboard";

const PasswordModal = (props: any) => {
  const { isOpen, onHide, isCopy } = props;
  const onClose = () => {
    onHide();
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div
          className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
          onClick={() => {
            onClose();
          }}
        />
        <div className="modal-container bg-white w-5/12 md:w-8/12 sm:w-10/12 md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
          <div className="modal-content p-5 text-left overflow-scroll max-h-[98vh] bg-chatlook-primary border rounded-[8px]">
            <div className="pb-3 text-start text-ev-white">
              <p className="text-2xl font-bold pb-2">Reset Password</p>
            </div>
            <div
              style={{
                padding: 50,
                display: "flex",
                flexDirection: "column",
                color: "var(--ev-white--)",
              }}
            >
              <span>
                <span>Email Id :- </span>
                <span className="bold">{isCopy?.email}</span>
              </span>
              <span>
                <span>Password :- </span>
                <span className="bold">{isCopy?.password}</span>
              </span>
            </div>
            <div className="flex justify-center pt-2 items-center gap-3">
              <CopyToClipboard text={`${isCopy?.email} ${isCopy?.password}`}>
                <Button
                  onClick={() => {
                    onClose();
                    showToaster("Copied");
                  }}
                >
                  Copy & Close
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordModal;
