// UI IMPORT
import { Icon, IconKeys } from "../../ui";

// PROJECT IMPORT
import { seperator } from "../../helperFunctions";

interface CardProps {
  index?: number;
  length?: number;
  value?: any;
  text?: string;
  borderBg?: string;
  icon: IconKeys;
  rupee?: boolean;
  count?: number;
  percentage?: number;
}
const Card = (props: CardProps) => {
  const {
    index,
    value,
    text,
    icon,
    rupee = true,
    count,
    percentage,
    borderBg,
    length = 1,
  } = props;

  return (
    <div key={index} className={`my-1 `}>
      <div
        className={`totaldepo-main h-full max-h-full flex justify-between ${
          index === length - 1 ? "!border-r-0" : ""
        }`}
      >
        <div>
          <div
            className={`totaldepo-text ${borderBg}`}
            title={seperator(+value)}
          >
            <div className="flex gap-1">
              <p className="text-ev-secondary font-medium">
                {text}
                <span className="font-medium text-sm">
                  {count ? ` (${count})` : null}
                </span>
              </p>
            </div>
            <h4 className="!text-ev-white flex gap-1">
              {rupee ? <Icon name="Rupee" width={10} height={7} /> : null}
              {`${seperator(+value, false)}`}
              <span className=" text-chatlook-secondary">
                {percentage ? `~ ${percentage}%` : null}
              </span>
            </h4>
          </div>
          {/* <div>
            <p className={`flex items-center pt-1 decresetext`}>
              <FaCaretUp />
              <FaCaretDown />
              6.5% since last month
            </p>
          </div> */}
        </div>
        <div className="totaldepo-img-sec">
          <div className="box-img">
            <Icon name={icon} className="max-w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
