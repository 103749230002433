import React, { PropsWithChildren } from "react";

// UI IMPORT
import { ButtonVariants, getButtonVariants } from "../theme";

// THIRD - PARTY IMPORT
import { CSVLink } from "react-csv";

interface ExportProps extends PropsWithChildren<{}> {
  className?: string;
  variant?: ButtonVariants;
  isLoading?: Boolean;
  filename?: string;
  data: any[];
  headers: any[];
  onClick?: any;
}
const Export = (props: ExportProps) => {
  const {
    className = "",
    children,
    isLoading = false,
    variant = "Primary",
    filename = "File.csv",
    ...rest
  } = props;
  const buttonClassName = getButtonVariants(variant);

  return (
    <CSVLink
      filename={filename}
      className={`${className} ${buttonClassName} ${isLoading ? "pointer-events-none" : ""} appl-btn rounded h-10 flex items-center justify-center`}
      {...rest}
    >
      {children}
    </CSVLink>
  );
};

export default Export;
