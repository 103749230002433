import React, { PropsWithChildren, useEffect, useState } from "react";
import { useLazyGetActiveDomainListQuery } from "../../features/cashback/cashbackApi";
import { useDispatch } from "react-redux";
import { logout } from "../../helperFunctions";
import { useUser } from "../../features/auth/authSlice";

interface LoaderProviderProps extends PropsWithChildren<{}> {}
interface LoaderContext {
  isLoading: any;
  setIsLoading: any;
  domainData: any;
  setDomainData: any;
}
// eslint-disable-next-line
export const LoaderContext = React.createContext<LoaderContext | null>(null);

const LoaderProvider = ({ children }: LoaderProviderProps) => {
  const [isLoading, setIsLoading] = useState<any>(true);
  const [domainData, setDomainData] = useState<any>({});

  const dispatch = useDispatch();
  const user = useUser();

  const [getActiveDomainList] = useLazyGetActiveDomainListQuery();

  const onGetDomainList = async () => {
    try {
      setIsLoading(true);
      const res: any = await getActiveDomainList().unwrap();
      setDomainData(res || {});
      setIsLoading(false);
    } catch (err) {
      await logout(dispatch);
      setIsLoading(false);
      console.error("Error =-=>", err);
    }
  };

  useEffect(() => {
    if (user?.token) {
      onGetDomainList();
    }
  }, [user]);

  return (
    <LoaderContext.Provider
      value={{ isLoading, domainData, setDomainData, setIsLoading }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderProvider;
