// PROJECT IMPORT
import authSlice from "../features/auth/authSlice";
import commonSlice from "../features/common/commonSlice";
import dashboardSlice from "../features/dashboard/dashboardSlice";

import { api } from "./api";
import depositSlice from "../features/deposit/depositSlice";
import withdrawalSlice from "../features/withdrawal/withdrawalSlice";
import { logout, showToaster } from "../helperFunctions";

// THIRD - PARTY IMPORT
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import {
  Middleware,
  MiddlewareAPI,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import settlementSlice from "../features/settlement/settlementSlice";
import manualSlice from "../features/manual/manualSlice";
import bonusSlice from "../features/bonus/bonusSlice";
import userSlice from "../features/user/userSlice";
import cashbackSlice from "../features/cashback/cashbackSlice";
import domainIdSlice from "../features/domainId/domainIdSlice";
import bonusHistorySlice from "../features/bonus-history/bonusHistorySlice";
import loyaltySlice from "../features/loyalty/loyaltySlice";
import customSlice from "../features/custom/customSlice";
import supportlogSlice from "../features/supportlog/supportlogSlice";
import promotionSlice from "../features/promotion/promotionSlice";
import adminSlice from "../features/admin/adminSlice";
import roleSlice from "../features/role/roleSlice";
import permissionSlice from "../features/permission/permissionSlice";
import masterSlice from "../features/master/masterSlice";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  common: commonSlice,
  dashboard: dashboardSlice,
  auth: authSlice,
  deposit: depositSlice,
  withdrawal: withdrawalSlice,
  settlement: settlementSlice,
  manual: manualSlice,
  bonus: bonusSlice,
  bonusHistory: bonusHistorySlice,
  user: userSlice,
  cashback: cashbackSlice,
  domain: domainIdSlice,
  loyalty: loyaltySlice,
  custom: customSlice,
  supportlog: supportlogSlice,
  admin: adminSlice,
  promotion: promotionSlice,
  role: roleSlice,
  permission: permissionSlice,
  master: masterSlice,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["api"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const updateOpenModal = () => {
  localStorage.setItem("isOpen", "true");
};

export const middleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    const payload = action?.payload;
    const { dispatch } = api;

    if (payload?.data?.status === false) {
      showToaster(payload?.data?.message || "", "Error");
      if (payload?.data?.message === "Unauthorized") {
        updateOpenModal();
        logout(dispatch);
      }
    }
    return next(action);
  };

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(api.middleware)
      .concat(middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
