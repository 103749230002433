import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { domainApi } from "./domainIdApi";

interface DomainState {
  domainData: any;
  currentDomain: any | null;
}

const initialState: DomainState = {
  domainData: {},
  currentDomain: null,
};

export const domainSlice = createSlice({
  name: "domain",
  initialState,
  reducers: {
    clearDomain: () => initialState,
    setCurrentDomain: (state, { payload }) => {
      state.currentDomain = payload || null;
    },
    setDomainIdStatus: (state, { payload }) => {
      state.domainData = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      domainApi.endpoints.getDomain.matchFulfilled,
      (state, { payload }) => {
        state.domainData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default domainSlice.reducer;
export const { clearDomain,setCurrentDomain,setDomainIdStatus } = domainSlice.actions;

export const selectDomainata = (state: RootState) => state.domain.domainData;
export const useDomainData = () => {
  const domainData = useSelector(selectDomainata);
  return useMemo(() => domainData, [domainData]);
};

export const selectCurrentDomain = (state: RootState) =>
  state.domain.currentDomain;
export const useCurrentDomain = () => {
  const currentDomain = useSelector(selectCurrentDomain);
  return useMemo(() => currentDomain, [currentDomain]);
};
