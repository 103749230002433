import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { promotionApi } from "./promotionApi";

interface PromotionState {
  promotionData: any;
  currentPromotion: any | null;
}

const initialState: PromotionState = {
  promotionData: {},
  currentPromotion: null,
};

export const promotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {
    clearPromotion: () => initialState,
    setCurrentPromotion: (state, { payload }) => {
      state.currentPromotion = payload || null;
    },
    setPromotionStatus: (state, { payload }) => {
      state.promotionData = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      promotionApi.endpoints.getPromotion.matchFulfilled,
      (state, { payload }) => {
        state.promotionData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default promotionSlice.reducer;
export const { clearPromotion, setCurrentPromotion ,setPromotionStatus} = promotionSlice.actions;

export const selectPromotionData = (state: RootState) => state.promotion.promotionData;
export const usePromotionData = () => {
  const promotionData = useSelector(selectPromotionData);
  return useMemo(() => promotionData, [promotionData]);
};

export const selectCurrentPromotion = (state: RootState) =>
  state.promotion.currentPromotion;
export const useCurrentPromotion = () => {
  const currentPromotion = useSelector(selectCurrentPromotion);
  return useMemo(() => currentPromotion, [currentPromotion]);
};
