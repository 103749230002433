import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

// UI IMPORT
import { Button, ControlledDropDown, Input, Loader } from "../../ui";

// PROJECT IMPORT
import {
  useAddPermissionMutation,
  useUpdatePermissionMutation,
} from "./permissionApi";
import { setCurrentPermission, useCurrentPermission } from "./permissionSlice";
import { showToaster } from "../../helperFunctions";
import { useAppDispatch } from "../../utills/hooks";

// THIRD - PARTY IMPORT
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useGetRolesByGroupQuery } from "../role/roleApi";
import { useRolesByGroup } from "../role/roleSlice";

const PermissionForm = () => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentPermission = useCurrentPermission();
  const rolesByGroup = useRolesByGroup();

  useGetRolesByGroupQuery();
  const [addPermission, { isLoading: isAddLoading }] =
    useAddPermissionMutation();
  const [updatePermission, { isLoading: isUpdateLoading }] =
    useUpdatePermissionMutation();

  const schema = yup.object({
    group_name: yup.string().required("Please select a group name"),
    name: yup.string().required("Please enter a name"),
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  }: any = useForm<any>({
    resolver: yupResolver<any>(schema),
    defaultValues: {
      ...(id && currentPermission ? currentPermission : {}),
    },
  });

  const onSubmit = async (values: any) => {
    try {
      let res: any;
      if (id) {
        res = await updatePermission({ ...values, id: id }).unwrap();
      } else {
        res = await addPermission(values).unwrap();
      }
      showToaster(res?.message);
      onBack();
    } catch (err) {}
  };

  const onBack = () => {
    dispatch(setCurrentPermission(null));
    navigate(-1);
  };

  useEffect(() => {
    if (id) {
      if (+currentPermission?.id !== +id) {
        showToaster("Something Went Wrong!", "Error");
        onBack();
      }
    } else {
      dispatch(setCurrentPermission(null));
    }

    return () => {
      dispatch(setCurrentPermission(null));
    };
    
    // eslint-disable-next-line
  }, [id]);

  const getPermissionOptions = () => {
    const options = rolesByGroup?.map((val: any) => {
      const clientDataOption = {
        value: val?.name,
        label: val?.name,
      };
      return clientDataOption;
    });
    return options;
  };

  return (
    <>
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col space-y-5"
        >
          <div className="cardcss">
            <div className="grid grid-cols-3 items-start gap-3 pt-2">
              <ControlledDropDown
                name="group_name"
                label="Group Name"
                options={getPermissionOptions()}
                control={control}
                errors={errors}
              />
            </div>
            <div className="grid grid-cols-3 items-start gap-3 pt-2">
              <Input
                name="name"
                placeholder="Enter Name"
                className="!w-full"
                label="Name"
                register={register}
                errors={errors}
              />
            </div>
          </div>
          <div className="flex items-center justify-end gap-2">
            <Button type="submit" className="mt-[10px] !h-9 !text-sm">
              {isAddLoading || isUpdateLoading ? (
                <Loader loaderClassName="create-edit-bonus" />
              ) : id ? (
                "Update"
              ) : (
                "Add"
              )}
            </Button>
            <Button
              variant="Transaparent"
              className="mt-[10px]  !text-sm !h-9"
              onClick={() => {
                reset();
              }}
            >
              Clear
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PermissionForm;
