import { Navigate, Route, Routes } from "react-router-dom";
import { Fragment } from "react";

// PROJECT IMPORT
import { Dashboard, Header, SideBar } from "../../features";
import { useIsDrawerOpen } from "../../features/common/commonSlice";
import CashbackForm from "../../features/cashback/CashbackForm";
import { BonusHistory } from "../../features/bonus-history";
import LoyaltyForm from "../../features/loyalty/LoyaltyForm";
import { useUser } from "../../features/auth/authSlice";
import DomainId from "../../features/domainId/DomainId";
import BonusForm from "../../features/bonus/BonusForm";
import PromotionForm from "../../features/promotion/PromotionForm";
import { Cashback } from "../../features/cashback";
import { Loyalty } from "../../features/loyalty";
import { Bonus } from "../../features/bonus";
import { Promotion } from "../../features/promotion";
import { User } from "../../features/user";
import { useMedia } from "../wrappers";
import { Custom } from "../../features/custom";
import CustomForm from "../../features/custom/CustomForm";
import { SupportLog } from "../../features/supportlog";
import Admin from "../../features/admin/Admin";
import Role from "../../features/role/Role";
import RoleForm from "../../features/role/RoleForm";
import Permission from "../../features/permission/Permission";
import PermissionForm from "../../features/permission/PermissionForm";
import AdminForm from "../../features/admin/AdminForm";

const UserRoutes = () => {
  const { media } = useMedia();
  const user = useUser();

  const isDrawerOpen = useIsDrawerOpen();

  const Components: any = {
    "/dashboard": Dashboard,
    "/bonus": Bonus,
    "/promotions": Promotion,
    "/cashback": Cashback,
    "/user": User,
    "/domainid": DomainId,
    "/bonus-history": BonusHistory,
    "/loyalty": Loyalty,
    "/custom": Custom,
    "/support-logs": SupportLog,
    "/admin": Admin,
    "/roles": Role,
    "/permissions": Permission,
  };

  return (
    <div className=" h-screen sm:ml-2 sm:my-2 sm:h-[calc(100vh-0px)] sm:m-0 overflow-y-scroll main relative">
      <SideBar />
      <div
        className={`${
          isDrawerOpen && media.width < 768
            ? "pl-[10px]"
            : isDrawerOpen && media.width > 768
            ? "pl-[220px]"
            : media.width > 768
            ? "pl-[75px]"
            : "!p-0"
        } 
        ${media.width < 768 && !isDrawerOpen ? "pl-[10px]" : ""} 
        ${media.width < 768 ? "pr-[10px]" : "pr-[0px]"} 
         mainAdmin`}
      >
        <Header />
        <div className="p-[18px]">
          <Routes>
            {user?.sidebar?.map((item: any, index: number) => {
              const Element = Components?.[item?.route];

              if (!Element) {
                return <></>;
              }
              return (
                <Fragment key={index}>
                  {index === 0 ? (
                    <Route path="/" element={<Navigate to={item?.route} />} />
                  ) : null}
                  <Route path={item?.route} element={<Element />} />
                </Fragment>
              );
            })}

            {user?.sidebar?.find((item: any) => item?.route === "/bonus") ? (
              <>
                <Route path={`/bonus/create`} element={<BonusForm />} />
                <Route path="/bonus/:id" element={<BonusForm />} />
              </>
            ) : null}

            {user?.sidebar?.find(
              (item: any) => item?.route === "/promotion"
            ) ? (
              <>
                <Route path={`/promotion/create`} element={<PromotionForm />} />
                <Route path="/promotion/:id" element={<PromotionForm />} />
              </>
            ) : null}

            {user?.sidebar?.find((item: any) => item?.route === "/cashback") ? (
              <>
                <Route path="/cashback/create" element={<CashbackForm />} />
                <Route path="/cashback/:id" element={<CashbackForm />} />
              </>
            ) : null}
            {user?.sidebar?.find((item: any) => item?.route === "/loyalty") ? (
              <>
                <Route path="/loyalty/create" element={<LoyaltyForm />} />
                <Route path="/loyalty/:id" element={<LoyaltyForm />} />
              </>
            ) : null}
            {user?.sidebar?.find((item: any) => item?.route === "/custom") ? (
              <>
                <Route path={`/custom/create`} element={<CustomForm />} />
                <Route path="/custom/:id" element={<CustomForm />} />
              </>
            ) : null}
            {user?.sidebar?.find((item: any) => item?.route === "/roles") ? (
              <>
                <Route path={`/roles/create`} element={<RoleForm />} />
                <Route path="/roles/:id" element={<RoleForm />} />
              </>
            ) : null}
            {user?.sidebar?.find((item: any) => item?.route === "/permissions") ? (
              <>
                <Route path={`/permissions/create`} element={<PermissionForm />} />
                <Route path="/permissions/:id" element={<PermissionForm />} />
              </>
            ) : null}
            {user?.sidebar?.find((item: any) => item?.route === "/admin") ? (
              <>
                <Route path={`/admin/create`} element={<AdminForm />} />
                <Route path="/admin/:id" element={<AdminForm />} />
              </>
            ) : null}
            {user?.sidebar?.find((item: any) => item?.route === "/promotions") ? (
              <>
                <Route path={`/promotions/create`} element={<PromotionForm />} />
                <Route path="/promotions/:id" element={<PromotionForm />} />
              </>
            ) : null}
            <Route
              path="*"
              element={<Navigate to={user?.sidebar?.[0]?.route} />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default UserRoutes;
