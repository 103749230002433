import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Loader } from "../../ui";
import { showToaster } from "../../helperFunctions";
import { useDeleteLoyaltyMutation } from "./loyaltyApi";

const DeleteLoyaltyModal = (props: any) => {
  const { isOpen, onClose, onGet, tobeDeleteData } = props;
  const [isLoader, setIsLoader] = useState(false);
  const [deleteLoyalty] = useDeleteLoyaltyMutation();

  const onSubmit = async () => {
    setIsLoader(true);

    try {
      if (tobeDeleteData?.domain_id) {
        const res: any = await deleteLoyalty({ id: tobeDeleteData?.domain_id }).unwrap();
        showToaster(res?.message);
        await onGet();
      }
      setIsLoader(false);
      onGet();
      onHide();
    } catch (err: any) {
      console.error("Error =-=>", err);
      setIsLoader(false);
    }
  };

  const onHide = () => {
    reset();
    onClose();
  };

  const { handleSubmit, reset } = useForm<any>({});

  if (!isOpen) return null;

  return (
    <form
      className="fixed inset-0 z-50 flex items-center justify-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          onHide();
        }}
      />

      <div className="modal-container bg-white w-4/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12  md:w-7/12 sm:w-7/12 md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-9 px-6 text-center">
          <p className="text-lg">Are you sure you want to delete this?</p>
          <div className="flex justify-center pt-4 items-center gap-3">
            {!isLoader ? (
              <Button type="submit">Yes</Button>
            ) : (
              <div className="flex item-center justify-center">
                <Loader />
              </div>
            )}
            <Button variant="Transaparent" onClick={onHide}>
              No
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default DeleteLoyaltyModal;
