// THIRD - PARTY IMPORT
import { Controller } from "react-hook-form";
import TimePickers, { TimePickerProps } from "./TimePicker";

interface ControlledTimePickerProps extends TimePickerProps {
  control?: any;
}

const ControlledTimePicker = (props: ControlledTimePickerProps) => {
  const { control, name, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, ...restt } }) => {
        return <TimePickers {...restt} {...rest} />;
      }}
    />
  );
};

export default ControlledTimePicker;
