import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Loader } from "../../ui";
import { responseToaster } from "../../helperFunctions";
import { useUpdateLoyaltyUserStatusMutation } from "./userApi";
import { useDispatch } from "react-redux";
import { setBlockUser } from "./userSlice";

const LoyaltyConformationModal = (props: any) => {
  const { isOpen, userData, onClose,onGet, blockPayload } = props;
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [updateLoyaltyUserStatus] = useUpdateLoyaltyUserStatusMutation();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    try {
      setIsLoadingStatus(true);      
      const res = await updateLoyaltyUserStatus(blockPayload).unwrap();
      onGet()
      // const index = userData?.data?.findIndex(
      //   (item: any) => item?.id === blockPayload?.userId
      // );
      // if (index > -1) {
      //   const newData = [...userData.data];
      //   newData[index] = {
      //     ...newData[index],
      //     account_status: blockPayload?.status ? 0 : 1,
      //   };
      //   dispatch(setBlockUser({ ...userData, data: newData }));
      // }
      responseToaster(res);
      setIsLoadingStatus(false);
      onClose();
    } catch (err) {
      setIsLoadingStatus(false);
      console.error("Error =-=>", err);
    }
  };

  const onHide = () => {
    reset();
    onClose();
  };

  const { handleSubmit, reset } = useForm<any>({});

  if (!isOpen) return null;

  return (
    <form
      className="fixed inset-0 z-50 flex items-center justify-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          onHide();
        }}
      />

      <div className="modal-container bg-white w-4/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12  md:w-7/12 sm:w-7/12 md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
      <div className="modal-content p-5 text-left overflow-scroll max-h-[98vh] bg-chatlook-primary border rounded-[8px]">

          <p className="text-lg text-ev-secondary text-center">
            {!blockPayload?.is_block
              ? "Are you sure you want to Unblock this user for loyalty program?"
              : "Are you sure you want to Block this user for loyalty program?"}
          </p>
          <div className="flex justify-center pt-4 items-center gap-3">
            {!isLoadingStatus ? (
              <Button type="submit">Yes</Button>
            ) : (
              <div className="flex item-center justify-center">
                <Loader />
              </div>
            )}
            <Button variant="Transaparent" onClick={onHide}>
              No
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoyaltyConformationModal;
