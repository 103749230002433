import closeButton from "../../assets/images/close-button.svg";
import { useRef } from "react";

const BonusActivityModal = (props: any) => {
  const { isOpen, onClose, data } = props;
  const tableRef = useRef<HTMLDivElement>(null);
  if (!isOpen) return null;

  return (
    <form className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full  bg-gray-900 opacity-50"
        onClick={() => {
          onClose();
        }}
      />
      <div className="modal-container bg-white w-6/12 md:w-8/12 sm:w-11/12 xs:w-11/12 mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div
          className="modal-content p-5 text-left overflow-scroll max-h-[98vh] bg-chatlook-primary border rounded-[8px]"
          ref={tableRef}
        >
          <div className="flex justify-between items-center">
            <h4 className="text-2xl text-ev-white font-bold pb-2">
              Last Activity Log #{data?.id}
            </h4>
            <div
              className="modal-close cursor-pointer z-50  flex items-center gap-2"
              onClick={onClose}
            >
              <img src={closeButton} alt="Logo" />
            </div>
          </div>
          <div className="flex flex-col items-end justify-start mt-2">
            <p className="block-title mb-2 text-ev-secondary">Last Visit Site</p>
            <p className="block-data">{data?.last_visit_site || "-"}</p>
            <p className="block-data-small">
              {data?.last_visit_site_readable
                  ? `(${data.last_visit_site_readable})`
                  : null}
            </p>
          </div>
          <div className="">
            <div className="grid grid-cols-2 gap-5 py-2">
              <div>
                <p className="block-title mb-2 text-ev-secondary">Criteria</p>
                <div>
                  {data?.activity_sync ? <pre className="block-data">
<code>
    {JSON.stringify(JSON.parse(data?.activity_sync), undefined, 2)}
</code>
</pre> : <span className="block-data">No Activity Found</span>}
                </div>
              </div>
              <div>
                <p className="block-title mb-2 text-ev-secondary">Conversion</p>
                <div>
                  {data?.activity_convert ? <pre className="block-data">
<code>
    {JSON.stringify(JSON.parse(data?.activity_convert), undefined, 2)}
</code>
</pre> : <span className="block-data">No Activity Found</span>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default BonusActivityModal;
