import React, { CSSProperties, useState } from "react";
import { BiMenuAltLeft } from "react-icons/bi";
import { RiExpandUpDownFill } from "react-icons/ri";

interface TableHeaderProps {
  className?: string;
  style?: CSSProperties;
  isExpendable?: boolean;
  columns: any[];
  onSort: any;
}
const TableHeader = (props: TableHeaderProps) => {
  const {
    className = "",
    style = {},
    columns = [],
    isExpendable = true,
    onSort,
  } = props;
  const [sortType, setSortType] = useState("Asce");

  const toggleSortType = () => {
    const newSortType = sortType === "Asce" ? "Desc" : "Asce";
    setSortType(newSortType);
    return newSortType;
  };

  return (
    <thead
    className={`${className} table-header sticky text-sm font-medium text-ev-white top-[-1px] bg-chatlook-primary h-[40px] z-10`}
      style={{ ...style }}
    >
      <tr>
        {isExpendable ? (
          <th className="no-wrap">
            <BiMenuAltLeft />
          </th>
        ) : null}
        {columns?.map((column, index) => {
          return (
            <th key={index} className="text-start no-wrap">
              <div className="flex flex-row items-center gap-3">
                <p>{column?.title}</p>
                {column?.sortable ? (
                  <div
                    onClick={() => {
                      const newSortType = toggleSortType();
                      onSort(column?.sortableKey, column?.type, newSortType);
                    }}
                    className="cursor-pointer"
                  >
                    <RiExpandUpDownFill fill="#9e9393"/>
                  </div>
                ) : null}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
