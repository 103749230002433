import closeButton from "../../assets/images/close-button.svg";
import { useRef } from "react";
import {ordinalToCardinal, seperator} from "../../helperFunctions";

const CustomModal = (props: any) => {
  const { isOpen, onClose, data } = props;
  const tableRef = useRef<HTMLDivElement>(null);
  if (!isOpen) return null;

  let sportsConversion = null;
  if (data.sports_conversion) {
    sportsConversion = JSON.parse(data.sports_conversion);
  }
  let casinoConversion = null;
  if (data.casino_conversion) {
    casinoConversion = JSON.parse(data.casino_conversion);
  }

  return (
      <form className="fixed inset-0 z-50 flex items-center justify-center">
        <div
            className="modal-overlay absolute w-full h-full  bg-gray-900 opacity-50"
            onClick={() => {
              onClose();
            }}
        />

        <div className="modal-container bg-white w-6/12 md:w-8/12 sm:w-11/12 xs:w-11/12 mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
          <div
              className="modal-content p-5 text-left overflow-scroll max-h-[98vh] bg-chatlook-primary border rounded-[8px]"
              ref={tableRef}
          >
            <div className="flex justify-end items-center">
              <div
                  className="modal-close cursor-pointer z-50  flex items-center gap-2"
                  onClick={onClose}
              >
                <img src={closeButton} alt="Logo" />
              </div>
            </div>
            <div className="flex flex-col items-end justify-start mt-2">
              <p className="block-title mb-2 text-ev-secondary">Last Updated</p>
              <p className="block-data">{data?.updated_at || "-"}</p>
              <p className="block-data-small">
                {data?.updated_at_readable
                    ? `(${data.updated_at_readable})`
                    : null}
              </p>
            </div>
            <div className="">
              <div className="mt-2">
                <h4 className="text-lg font-semibold  md:text-base text-ev-white">
                  Custom Details
                </h4>
              </div>
              <div className="grid grid-cols-4 gap-5 py-2">
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Custom Type</p>
                  <p className="block-data capitalize">{data?.type}</p>
                </div>
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Custom Name</p>
                  <p className="block-data capitalize">{data?.name}</p>
                </div>
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Promo Code</p>
                  <p className="block-data">{data?.promo_code}</p>
                </div>
                {data?.max_usage === -1 ? <div>
                  <p className="block-title mb-2 text-ev-secondary">Promo Code Usage</p>
                  <p className="block-data">Unlimited</p>
                </div> : null}
                {data?.max_usage === -2 ? <div>
                  <p className="block-title mb-2 text-ev-secondary">Promo Code Usage</p>
                  <p className="block-data">{ordinalToCardinal(data?.frequency_settings)} Deposit (Once)</p>
                </div> : null}
                {data?.max_usage >= 0 ? <div>
                  <p className="block-title mb-2 text-ev-secondary">Promo Code Usage</p>
                  <p className="block-data">{data?.max_usage} <sup>times</sup> (Limited)</p>
                </div> : null}
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Domain</p>
                  <p className="block-data">{data?.domain_name}</p>
                </div>
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Is Only For New Player?</p>
                  {data?.is_only_new_player === 1 ?
                      <p className="block-data">Yes</p>
                      : <p className="block-data">No</p>}
                </div>
                <div>
                  <p className="block-title mb-2 text-ev-secondary">VIP Levels</p>
                  {data?.vip_user_level ?
                      <p className="block-data capitalize">{data?.vip_user_level}</p>
                      : <p className="block-data">All</p>}
                </div>
                {data?.type === "deposit" ? (
                    <>
                      <div>
                        <p className="block-title mb-2 text-ev-secondary">Custom Sub Type</p>
                        <p className="block-data capitalize">{data?.sub_type}</p>
                      </div>
                      <div>
                        <p className="block-title mb-2 text-ev-secondary">Deposit Amount</p>
                        {data?.max_deposit <= 0 ?
                            <p className="block-data">{`${seperator(data?.min_deposit)}+`}</p>
                            : <p className="block-data">{`${seperator(data?.min_deposit)} - ${seperator(data?.max_deposit)}`}</p>}
                        {data?.sub_type === 'number' && data?.max_usage !== -2 ? <span className="block-data">({data?.frequency_settings} <sup>times</sup>)</span> : null}
                      </div>
                    </>
                ) : null}
                {data?.criteria_name ? (
                    <>
                      <div>
                        <p className="block-title mb-2 text-ev-secondary">Criteria Name</p>
                        {data?.criteria_name === "fdeposit" ?
                            <p className="block-data">Friend 1st Deposit</p>
                            : <p className="block-data capitalize">{data?.criteria_name}</p> }
                      </div>
                      {data?.type === "deposit" ? (
                          <div>
                            <p className="block-title mb-2 text-ev-secondary">Criteria Range</p>
                            {data?.criteria_range_to <= 0 ?
                                <p className="block-data">{`${seperator(data?.criteria_range_from)}+`}</p>
                                : <p className="block-data">{`${seperator(data?.criteria_range_from)} - ${seperator(data?.criteria_range_to)}`}</p>}
                          </div>
                      ) :null}
                      {data?.type === "sports" ? (
                          <>
                            <div>
                              <p className="block-title mb-2 text-ev-secondary">Min Bet Amount</p>
                              <p className="block-data">{seperator(data?.min_bet_amount)}</p>
                            </div>
                            <div>
                              <p className="block-title mb-2 text-ev-secondary">Roll Over</p>
                              <p className="block-data">{data?.rollover}</p>
                            </div>
                            {data?.max_odds <= 0 ? <div>
                                  <p className="block-title mb-2 text-ev-secondary">Min Odds</p>
                                  <p className="block-data">{data?.min_odds}+</p>
                                </div>
                                : <>
                                  <div>
                                    <p className="block-title mb-2 text-ev-secondary">Min Odds</p>
                                    <p className="block-data">{data?.min_odds}</p>
                                  </div>
                                  <div>
                                    <p className="block-title mb-2 text-ev-secondary">Max Odds</p>
                                    <p className="block-data">{data?.max_odds}</p>
                                  </div>
                                </>
                            }
                          </>
                      ) :null}
                      {data?.type === "casino" ? (
                          <>
                            <div>
                              <p className="block-title mb-2 text-ev-secondary">Min Bet Amount</p>
                              <p className="block-data">{seperator(data?.min_bet_amount)}</p>
                            </div>
                            <div>
                              <p className="block-title mb-2 text-ev-secondary">Roll Over</p>
                              <p className="block-data">{data?.rollover}</p>
                            </div>
                          </>
                      ) :null}
                    </>
                ) : <div>
                  <p className="block-title mb-2 text-ev-secondary">Criteria</p>
                  <p className="block-data">Not Available</p>
                </div>}
                <div>
                  <p className="block-title mb-2 text-ev-secondary">In Promotion Page?</p>
                  {data?.in_promotion === 1 ?
                      <p className="block-data">Yes</p>
                      : <p className="block-data">No</p>}
                </div>
              </div>
              <div className="my-6">
                <p className="block-title mb-2 text-ev-secondary">Players</p>
                {data?.players ?
                    <div className="modal-container bg-white w-6/12 md:w-8/12 sm:w-11/12 xs:w-11/12 z-50 overflow-y-auto">
                      <div
                          className="modal-content text-left overflow-scroll max-h-[50vh] bg-chatlook-primary">
                        <table className="border-collapse border border-gray-700">
                          <thead>
                          <tr className="bg-gray-800">
                            <th className="text-ev-secondary px-4 py-2 border" key="playerId">Player ID</th>
                            <th className="text-ev-secondary px-4 py-2 border" key="username">Username</th>
                          </tr>
                          </thead>
                          <tbody>
                          {data?.players.map(
                              (row: any, rowIndex: number) =>
                                  (
                                      <tr
                                          key={rowIndex}
                                          className="bg-gray-900"
                                      >
                                        <td
                                            key={row.player_id}
                                            className="text-ev-secondary px-4 py-2 border"
                                        >
                                          {row.player_id}
                                        </td>
                                        <td
                                            key={row.username}
                                            className="text-ev-secondary px-4 py-2 border"
                                        >
                                          {row.username}
                                        </td>
                                      </tr>
                                  )
                          )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    : <p className="block-data">All</p>}
              </div>

              <div className="mt-2">
                <h4 className="text-lg font-semibold md:text-base text-ev-white">
                  Custom Insights
                </h4>
              </div>
              <div className="grid grid-cols-3 gap-5 py-2">
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Custom Category</p>
                  <p className="block-data capitalize">{data?.bonus_type}</p>
                </div>
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Custom Amount</p>
                  <p className="block-data">{seperator(data?.bonus_amount)}</p>
                </div>
                { data?.bonus_type === 'percentage' ? <div>
                  <p className="block-title mb-2 text-ev-secondary">Custom Maximum Amount</p>
                  <p className="block-data">{seperator(data?.bonus_cap_amount)}</p>
                </div> : null}
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Validity Days</p>
                  <p className="block-data">{data?.validity_days}</p>
                </div>
              </div>
              {data?.wallet_per ? <>
                <div className="mt-2">
                  <h4 className="text-lg font-semibold md:text-base text-ev-white">
                    Custom Calculation Per Wallet
                  </h4>
                </div>
                <div className="grid grid-cols-3 gap-5 py-2">
                  <div>
                    <p className="block-title mb-2 text-ev-secondary">Sports</p>
                    <p className="block-data">
                      {JSON.parse(data?.wallet_per)?.sports ? `${JSON.parse(data?.wallet_per)?.sports}%`:"-"}
                    </p>
                    <p className="block-data">
                      Is Previous Bonus Forfeited?
                      &nbsp;<span className="underline">
                            {data?.is_previous_forfeited ? JSON.parse(data?.is_previous_forfeited)?.sports == 1 ? 'Yes' : 'No' : 'Yes'}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p className="block-title mb-2 text-ev-secondary">Casino</p>
                    <p className="block-data">
                      {JSON.parse(data?.wallet_per)?.casino ? `${JSON.parse(data?.wallet_per)?.casino}%`:"-"}
                    </p>
                    <p className="block-data">
                      Is Previous Bonus Forfeited?
                      &nbsp;<span className="underline">
                            {data?.is_previous_forfeited ? JSON.parse(data?.is_previous_forfeited)?.casino == 1 ? 'Yes' : 'No' : 'Yes'}
                      </span>
                    </p>
                  </div>
                </div>
              </> : null}
              {sportsConversion ? (
                  <>
                    <div className="mt-2">
                      <h4 className="text-lg font-semibold md:text-base text-ev-white">
                        Conversion
                      </h4>
                    </div>
                    <div className="grid grid-cols-4 gap-5 py-2">
                      {/*<div>*/}
                      {/*  <p className="block-title mb-2 text-ev-secondary">Max Amount</p>*/}
                      {/*  <p className="block-data">{seperator(sportsConversion.max_converted)}</p>*/}
                      {/*</div>*/}
                      <div>
                        <p className="block-title mb-2 text-ev-secondary">Min Bet Amount</p>
                        <p className="block-data">{seperator(sportsConversion.min_bet)}</p>
                      </div>
                      <div>
                        <p className="block-title mb-2 text-ev-secondary">Roll Over</p>
                        <p className="block-data">{sportsConversion.rollover}</p>
                      </div>
                      {sportsConversion.max_odds <= 0 ?    <div>
                            <p className="block-title mb-2 text-ev-secondary">Min Odds</p>
                            <p className="block-data">{sportsConversion.min_odds}+</p>
                          </div>
                          : <>
                            <div>
                              <p className="block-title mb-2 text-ev-secondary">Min Odds</p>
                              <p className="block-data">{sportsConversion.min_odds}</p>
                            </div>
                            <div>
                              <p className="block-title mb-2 text-ev-secondary">Max Odds</p>
                              <p className="block-data">{sportsConversion.max_odds}</p>
                            </div>
                          </>
                      }
                      <div>
                        <p className="block-title mb-2 text-ev-secondary">Wagering Contribution(%)</p>
                        <p className="block-data">{sportsConversion.wagering_contribution}%</p>
                      </div>
                    </div>
                  </>
              ) : null}
              {casinoConversion ? (
                  <>
                    <div className="mt-2">
                      <h4 className="text-lg font-semibold md:text-base text-ev-white">
                        Conversion
                      </h4>
                    </div>
                    <div className="grid grid-cols-4 gap-5 py-2">
                      {/*<div>*/}
                      {/*  <p className="block-title mb-2 text-ev-secondary">Max Amount</p>*/}
                      {/*  <p className="block-data">{seperator(casinoConversion.max_converted)}</p>*/}
                      {/*</div>*/}
                      <div>
                        <p className="block-title mb-2 text-ev-secondary">Min Bet Amount</p>
                        <p className="block-data">{seperator(casinoConversion.min_bet)}</p>
                      </div>
                      <div>
                        <p className="block-title mb-2 text-ev-secondary">Roll Over</p>
                        <p className="block-data">{casinoConversion.rollover}</p>
                      </div>
                      <div>
                        <p className="block-title mb-2 text-ev-secondary">Live Casino(%)</p>
                        <p className="block-data">{casinoConversion.live_casino}%</p>
                      </div>
                      <div>
                        <p className="block-title mb-2 text-ev-secondary">Slot(%)</p>
                        <p className="block-data">{casinoConversion.slot}%</p>
                      </div>
                      <div>
                        <p className="block-title mb-2 text-ev-secondary">Table Games(%)</p>
                        <p className="block-data">{casinoConversion.table_games}%</p>
                      </div>
                      <div>
                        <p className="block-title mb-2 text-ev-secondary">Virtual Games(%)</p>
                        <p className="block-data">{casinoConversion.virtual_games}%</p>
                      </div>
                    </div>
                  </>
              ) : null}
              <div className="mt-2">
                <h4 className="text-lg font-semibold md:text-base text-ev-white">
                  Other Details
                </h4>
              </div>
              <div className="grid grid-cols-1 gap-5 py-2">
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Description</p>
                  <p className="block-data whitespace-pre">{data?.description || "-"}</p>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-5 py-2">
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Rules</p>
                  <p
                      className="block-data whitespace-pre"
                      dangerouslySetInnerHTML={{ __html: data?.rules || "-" }}
                  ></p>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-5 py-2">
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Terms & Conditions</p>
                  <p
                      className="block-data whitespace-pre"
                      dangerouslySetInnerHTML={{ __html: data?.t_c || "-" }}
                  ></p>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-5 py-2">
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Banner Image</p>
                  <img src={data?.image_url} alt="banner" />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-5 py-2">
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Start Date</p>
                  <p className="block-data">{data?.start_date || "-"}</p>
                  <p className="block-data-small">
                    {data?.start_date_readable
                        ? `(${data.start_date_readable})`
                        : null}
                  </p>
                </div>
                <div>
                  <p className="block-title mb-2 text-ev-secondary">End Date</p>
                  <p className="block-data">{data?.end_date || "-"}</p>
                  <p className="block-data-small">
                    {data?.end_date_readable
                        ? `(${data.end_date_readable})`
                        : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
  );
};

export default CustomModal;
