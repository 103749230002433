// RadioButton.tsx
import React, { ChangeEvent } from "react";

interface RadioButtonProps {
  label?: string;
  value?: string;
  checked?: boolean;
  defaultChecked?: any;
  disabled?: any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const { label, ...rest } = props;
  return (
    <label className="flex items-center space-x-2 cursor-pointer">
      <input type="radio" className="form-radio text-blue-500" {...rest} />
      <span className="text-ev-secondary">{label}</span>
    </label>
  );
};

export default RadioButton;
