import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { settlementApi } from "./settlementApi";

interface SettlementState {
  settlementData: any;
  settlementSummaryData: any;
}

const initialState: SettlementState = {
  settlementData: {},
  settlementSummaryData:{}
};

export const settlementSlice = createSlice({
  name: "settlement",
  initialState,
  reducers: {
    clearWithdrawal:() => initialState

  },
  extraReducers: (builder) => {
    builder.addMatcher(
        settlementApi.endpoints.getResellerSettlement.matchFulfilled,
      (state, { payload }) => {
        state.settlementData = payload || {};
      }
    );
    builder.addMatcher(
      settlementApi.endpoints.getResellerSettlementSummary.matchFulfilled,
    (state, { payload }) => {
      state.settlementSummaryData = payload || {};
    }
  );
  },
});

// Action creators are generated for each case reducer function
export default settlementSlice.reducer;
export const {clearWithdrawal} = settlementSlice.actions

export const selectSettlementData = (state: RootState) =>
  state.settlement.settlementData;

export const useSettlementData = () => {
  const settlementData = useSelector(selectSettlementData);
  return useMemo(() => settlementData, [settlementData]);
};

export const selectSettlementSummaryData = (state: RootState) =>
  state.settlement.settlementSummaryData;

export const useSettlementSummaryData = () => {
  const summaryData = useSelector(selectSettlementSummaryData);
  return useMemo(() => summaryData, [summaryData]);
};
