export const summaryData = [
  {
    imgSrc: "RechargeSuccess",
    key: "total_payin_txn",
    text: "Total Trasaction",
    rupee: false,
  },
  {
    imgSrc: "RechargePending",
    key: "total_payin_amount",
    text: "Total Payin Amount",
  },
  {
    imgSrc: "RechargeIntialized",
    key: "total_success_amount",
    text: "Total Success Amount",
  },
  {
    imgSrc: "RechargeProcess",
    key: "total_payin_commission",
    text: "Total Commission",
  },
];

export const filterOptions = [
  // { value: "txnId", label: "Transaction Id" },
  { value: "merhantId", label: "Merchant Id" },
  // { value: "ordId", label: "Order Id" },
  // { value: "bankRrn", label: "Bank RRN" },
  // { value: "custEmail", label: "Customer Email" },
  // { value: "custId", label: "Customer Id" },
  // { value: "custMobile", label: "Customer Mobile" },
  // { value: "paymentAmt", label: "Amount" },
  // { value: "udf1", label: "UDF 1" },
  // { value: "udf2", label: "UDF 2" },
  // { value: "udf3", label: "UDF 3" },
  // { value: "udf4", label: "UDF 4" },
  // { value: "udf5", label: "UDF 5" },
];

export const vipUserLevelOption = [
  { value: "bronze", label: "Bronze" },
  { value: "silver", label: "Silver" },
  { value: "gold", label: "Gold" },
  { value: "platinum", label: "Platinum" },
  { value: "kohinur", label: "Kohinur" },
];

export const criteriaNameOptions = [
  // {
  //   value: "stakes",
  //   label: "Stakes",
  // },
  // {
  //   value: "pnl",
  //   label: "PNL",
  // },
  // {
  //   value: "losses",
  //   label: "Losses",
  // },
  {
    value: "stakes",
    label: "Stakes",
  },
  {
    value: "profit",
    label: "Profit",
  },
  {
    value: "loss",
    label: "Loss",
  },
];

export const frequencyOptions = [
  {
    value: "limited",
    label: "Limited",
  },
  {
    value: "unlimited",
    label: "Unlimited",
  },
];

export const onlyLimitedfrequencyOptions = [
  {
    value: 'limited',
    label: "Limited",
  },
];


export const frequencyNumberDepositOptions = [
  {
    value: 'once',
    label: "Once",
  },
  {
    value: "limited",
    label: "Limited",
  },
  {
    value: "unlimited",
    label: "Unlimited",
  },
];

export const betCriteriaOptions = [
  {
    value: "stakes",
    label: "Stakes",
  },
  {
    value: "profit",
    label: "Profit",
  },
  {
    value: "loss",
    label: "Loss",
  },
];

export const referCriteriaOptions = [
  {
    value: "instant",
    label: "Instant",
  },
  {
    value: "fdeposit",
    label: "Friend Deposit",
  },
];

export const typeOptions = [
  {
    value: "deposit",
    label: "Deposit",
  },
  {
    value: "sports",
    label: "Sports",
  },
  {
    value: "casino",
    label: "Casino",
  },
  {
    value: "refer",
    label: "Refer",
  },
  {
    value: "paymentmethod",
    label: "Payment Method ",
  },
];

export const bonusTypeOptions = [
  {
    value: "flat",
    label: "Flat",
  },
  {
    value: "percentage",
    label: "Percentage",
  },
];

export const paymentMethodOptions = [
  {
    value: "manual",
    label: "Manual Deposit",
  },
  {
    value: "auto",
    label: "Auto Deposit",
  },
];

export const campaignSubTypeOptions = [
  {
    value: "next",
    label: "Next",
  },
  {
    value: "number",
    label: "Number",
  },
];

export const dateTypeOptions = [
  {
    value: "expiryDate",
    label: "Expiry Date",
  },
  {
    value: "validityDays",
    label: "Validity Days",
  },
];

export const questionOptions = [
  {
    value: 0,
    label: "No",
  },
  {
    value: 1,
    label: "Yes",
  },
];