import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { loyaltyApi } from "./loyaltyApi";

interface LoyaltyState {
  loyaltyData: any;
  currentLoyalty: any | null;
}

const initialState: LoyaltyState = {
  loyaltyData: {},
  currentLoyalty: null,
};

export const loyaltySlice = createSlice({
  name: "loyalty",
  initialState,
  reducers: {
    clearLoyalty: () => initialState,
    setCurrentLoyalty: (state, { payload }) => {
      state.currentLoyalty = payload || null;
    },
    setLoyaltyStatus: (state, { payload }) => {
      state.loyaltyData = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      loyaltyApi.endpoints.getLoyalty.matchFulfilled,
      (state, { payload }) => {
        state.loyaltyData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default loyaltySlice.reducer;
export const { clearLoyalty, setCurrentLoyalty ,setLoyaltyStatus} = loyaltySlice.actions;

export const selectLoyaltyData = (state: RootState) => state.loyalty.loyaltyData;
export const useLoyaltyData = () => {
  const loyaltyData = useSelector(selectLoyaltyData);
  return useMemo(() => loyaltyData, [loyaltyData]);
};

export const selectCurrentLoyalty = (state: RootState) =>
  state.loyalty.currentLoyalty;
export const useCurrentLoyalty = () => {
  const currentLoyalty = useSelector(selectCurrentLoyalty);
  return useMemo(() => currentLoyalty, [currentLoyalty]);
};
