import { useMemo } from "react";

// PROJECT IMPORT
import { RootState } from "../../utills/store";
import { authApi } from "./authApi";

// THIRD - PARTY IMPORT
import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

interface AuthState {
  user: any;
}

const initialState: AuthState = {
  user: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuth: () => initialState,
    setAuth: (state, { payload }) => {
      state.user = payload || initialState?.user;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {

        const accessLevel: any = {
          "/dashboard": "DASHBOARD_VIEW",
          "/bonus": "CAMPAIGN_VIEW",
          "/cashback": "CASHBACK_VIEW",
          "/bonus-history": "BONUS_HISTORY_VIEW",
          "/user": "USERS_VIEW",
          "/domainid": "DOMAIN_VIEW",
          "/loyalty": "LOYALTY_VIEW",
          "/roles": "ROLE_VIEW",
          "/permissions": "PERMISSION_VIEW",
          "/admin": "ADMIN_VIEW",
          "/support-logs": "SUPPORT_LOG_VIEW",
          "/promotions": "FPROMOTION_VIEW",
          "/custom": "CUSTOM_VIEW",
        };
        const newData = payload?.sidebar?.filter((val: any) => {
          return !!payload?.access_level?.[accessLevel?.[val?.route]];
        });
        state.user = {
          ...payload,
          sidebar:newData
        }
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default authSlice.reducer;
export const { clearAuth, setAuth } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;
export const useUser = () => {
  const user = useSelector(selectUser);
  return useMemo(() => user, [user]);
};
