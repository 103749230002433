import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Input, Loader, Select } from "../../ui";
import { responseToaster } from "../../helperFunctions";
import { useAddDomainMutation, useUpdateDomainMutation } from "./domainIdApi";

const AddDomainModal = (props: any) => {
  const { isOpen, onClose, onGet, editData } = props;
  const [addDomain] = useAddDomainMutation();
  const [updateDomain] = useUpdateDomainMutation();
  const [isLoader, setIsLoader] = useState(false);

  const onSubmit = async (values: any) => {
    setIsLoader(true);
    const payload = {
      ...values,
    };

    try {
      if (editData?.id) {
        const res: any = await updateDomain({
          ...payload,
          id: editData?.id,
        }).unwrap();
        responseToaster(res);
      } else {
        const res: any = await addDomain(payload).unwrap();
        responseToaster(res);
      }
      setIsLoader(false);
      onGet();
      onHide();
    } catch (err: any) {
      console.error("Error =-=>", err);
      setIsLoader(false);
    }
  };

  const onHide = () => {
    reset();
    onClose();
  };

  const { register, handleSubmit, reset, setValue } = useForm<any>({});

  useEffect(() => {
    if (isOpen) {
      setValue("domainName", editData?.domain_name);
      setValue("currencySymbol", editData?.currency_symbol);
      setValue("currency", editData?.currency);
    }
    
    // eslint-disable-next-line
  }, [isOpen, editData]);

  const staticOption = [
    { value: "Rupee", label: "Rupee", symbol: "₹" },
    { value: "Dollar", label: "Dollar", symbol: "$" },
    { value: "Euro", label: "Euro", symbol: "€" },
  ];

  if (!isOpen) return null;

  return (
    <form
      className="fixed inset-0 z-50 flex items-center justify-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-80"
        onClick={() => {
          onHide();
        }}
      />

      <div className="modal-container bg-chatlook-secondary w-5/12 md:w-8/12 sm:w-10/12 md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content p-6 text-left px-6">
          <div className="flex flex-wrap gap-2 items-start px-1">
            <Input
              type="text"
              className="!w-full"
              name="domainName"
              label="Domain Name"
              placeholder="Domain Name"
              register={register}
            />
            <Select
              name="currency"
              label="Currency"
              placeholder="Currency"
              className="!w-full"
              options={staticOption}
              register={register}
              onChange={(e) => {
                const selectedCurrency = staticOption.find(
                  (option) => option.value === e.target.value
                );

                setValue("currencySymbol", selectedCurrency?.symbol || "");
              }}
            />
            <Input
              type="text"
              className="!w-full"
              name="currencySymbol"
              label="Currency Symbol"
              placeholder="Currency Symbol"
              register={register}
              disabled
            />
          </div>
          <div className="flex justify-end pt-2 items-center gap-3">
            {!isLoader ? (
              <Button type="submit">{editData?.id ? "Update" : "Add"}</Button>
            ) : (
              <div className="flex item-center justify-center">
                <Loader />
              </div>
            )}
            <Button variant="Transaparent" onClick={onHide}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddDomainModal;
