import { api } from "../../utills/api";

export const loyaltyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLoyalty: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/getLoyaltyData",
        body: params,
        method: "POST",
      }),
    }),
    updateStatus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateLoyalty",
        body: params,
        method: "POST",
      }),
    }),
    deleteLoyalty: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/deleteLoyalty",
        body: params,
        method: "POST",
      }),
    }),
    addLoyalty: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/addLoyalty",
        body: params,
        method: "POST",
      }),
    }),
    updateLoyalty: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateLoyalty",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetLoyaltyMutation,
  useUpdateStatusMutation,
  useDeleteLoyaltyMutation,
  useAddLoyaltyMutation,
  useUpdateLoyaltyMutation,
} = loyaltyApi;
export const {
  endpoints: {
    getLoyalty,
    updateStatus,
    deleteLoyalty,
    addLoyalty,
    updateLoyalty,
  },
} = loyaltyApi;
