import { useLocation } from "react-router";

// UI IMPORT
import { Icon, Select } from "../../ui";

// PROJECT IMPORT
import { useUser } from "../auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../utills/hooks";
import { toggleDrawer } from "../common/commonSlice";
import { logout } from "../../helperFunctions";
import { AiOutlineLogout } from "react-icons/ai";
import { useEffect, useState } from "react";

import "@progress/kendo-date-math/tz/all";
import { useLoader, useMedia } from "../../components";
import { useUpdateChangeSiteMutation } from "../cashback/cashbackApi";
import { useDomainIdData } from "../cashback/cashbackSlice";

const Header = () => {
  const { setIsLoading, domainData } = useLoader();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [updateChangesSites] = useUpdateChangeSiteMutation();
  const user = useUser();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { media } = useMedia();

  const prepareDomainOptions = () => {
    const options: any = domainData?.data?.map((val: any) => {
      return {
        value: val?.id,
        label: val?.domain_name,
      };
    });
    return options?.length ? [...options] : [];
  };

  const handleDomainChange = async (selectedId: any) => {
    try {
      setIsLoading(true);
      const payload: any = {
        domainName: selectedId,
      };
      const res: any = await updateChangesSites(payload).unwrap();
      window.location.reload();
    } catch (err) {
      setIsLoading(false);
    }
  };

  const data = user?.sidebar?.find(
    (item: any) => item?.route === location?.pathname
  );

  return (
    <>
      <div className="mainNavbar webNav  fixed-top sticky -top-1 z-20 bg-chatlook-primary">
        <div className="navBar boxBetween flex justify-between items-center">
          <div className="navToggle title-nav flex gap-3">
            {media.width < 768 ? (
              <Icon
                className="me-2 w-6"
                name="MenuSidebar"
                onClick={() => {
                  dispatch(toggleDrawer());
                }}
              />
            ) : (
              ""
            )}
            {data?.name || ""}
            <div>
              <Select
                name="domainName"
                options={prepareDomainOptions()}
                value={domainData?.currentDomainId}
                onChange={(e) => {
                  handleDomainChange(e?.target?.value);
                }}
              />
            </div>
          </div>

          <div className="navIcons  boxBetween flex items-center md:items-start  gap-3">
            <div className="relative">
              <div
                className="w-10 h-10 m-auto items-center justify-center flex"
                onClick={handleDropdownToggle}
                style={{ background: "#3874ff47", borderRadius: "50%" }}
              >
                {user?.name?.charAt(0)?.toUpperCase()}
              </div>
              {isDropdownOpen && (
                <>
                  <div
                    className="drop-main"
                    onClick={handleDropdownToggle}
                  ></div>
                  <div className="dropdown-content">
                    <div
                      className="flex justify-between items-center"
                      onClick={async () => {
                        try {
                          await logout(dispatch);
                          navigate("/");
                        } catch (err) {
                          console.error("Logout Error =-=>", err);
                        }
                      }}
                    >
                      <p className="mb-0">Logout</p>
                      <AiOutlineLogout className="text-ev-darkblue text-xl" />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
