import { api } from "../../utills/api";

export const cashbackApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCashback: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/CampaignList",
        body: params,
        method: "POST",
      }),
    }),
    deleteData: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/deleteCampaign",
        body: params,
        method: "POST",
      }),
    }),
    addCashback: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/addCampaign",
        body: params,
        method: "POST",
      }),
    }),
    updateCashback: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateCampaign",
        body: params,
        method: "POST",
      }),
    }),
    updateCashbackStatus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateCampaignStatus",
        body: params,
        method: "POST",
      }),
    }),
    getActiveDomainList: build.query<{ data: any }, void>({
      query: () => ({
        url: `/activeDomainList`,
        method: "GET",
      }),
    }),
    updateChangeSite: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/changeSite",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetCashbackMutation,
  useDeleteDataMutation,
  useAddCashbackMutation,
  useUpdateCashbackMutation,
  useUpdateCashbackStatusMutation,
  useGetActiveDomainListQuery,
  useLazyGetActiveDomainListQuery,
  useUpdateChangeSiteMutation,
} = cashbackApi;

export const {
  endpoints: {
    getCashback,
    deleteData,
    addCashback,
    updateCashback,
    updateCashbackStatus,
    getActiveDomainList,
    updateChangeSite,
  },
} = cashbackApi;
