import { useMemo } from "react";

// PROJECT IMPORT
import { RootState } from "../../utills/store";
import { roleApi } from "./roleApi";
import { useAppSelector } from "../../utills/hooks";

// THIRD - PARTY IMPORT
import { createSlice } from "@reduxjs/toolkit";

interface RoleState {
  data: any;
  rolesByGroup: any[];
  currentData: any | null;
}

const initialState: RoleState = {
  data: {},
  rolesByGroup: [],
  currentData: null,
};

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    clearRole: () => initialState,
    setCurrentRole: (state, { payload }) => {
      state.currentData = payload || null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      roleApi.endpoints.getRoles.matchFulfilled,
      (state, { payload }) => {
        state.data = payload || {};
      }
    );
    builder.addMatcher(
      roleApi.endpoints.getRolesByGroup.matchFulfilled,
      (state, { payload }) => {
        state.rolesByGroup = payload?.data || [];
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default roleSlice.reducer;
export const { clearRole, setCurrentRole } = roleSlice.actions;

export const selectRoles = (state: RootState) => state.role.data;
export const selectRolesByGroup = (state: RootState) => state.role.rolesByGroup;
export const useRoles = () => {
  const data = useAppSelector(selectRoles);
  return useMemo(() => data, [data]);
};

export const useRolesByGroup = () => {
  const data = useAppSelector(selectRolesByGroup);
  return useMemo(() => data, [data]);
};

export const selectCurrentRole = (state: RootState) => state.role.currentData;
export const useCurrentRole = () => {
  const currentData = useAppSelector(selectCurrentRole);
  return useMemo(() => currentData, [currentData]);
};
