import { useMemo } from "react";

// PROJECT IMPORT
import { RootState } from "../../utills/store";
import { permissionApi } from "./permissionApi";
import { useAppSelector } from "../../utills/hooks";

// THIRD - PARTY IMPORT
import { createSlice } from "@reduxjs/toolkit";

interface PermissionState {
  data: any;
  currentData: any | null;
}

const initialState: PermissionState = {
  data: {},
  currentData: null,
};

export const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    clearPermission: () => initialState,
    setCurrentPermission: (state, { payload }) => {
      state.currentData = payload || null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      permissionApi.endpoints.getPermissions.matchFulfilled,
      (state, { payload }) => {
        state.data = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default permissionSlice.reducer;
export const { clearPermission, setCurrentPermission } = permissionSlice.actions;

export const selectPermissions = (state: RootState) => state.permission.data;
export const usePermissions = () => {
  const data = useAppSelector(selectPermissions);
  return useMemo(() => data, [data]);
};

export const selectCurrentPermission = (state: RootState) => state.permission.currentData;
export const useCurrentPermission = () => {
  const currentData = useAppSelector(selectCurrentPermission);
  return useMemo(() => currentData, [currentData]);
};
