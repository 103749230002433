import { api } from "../../utills/api";

export const roleApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRoles: build.mutation<{ data: any }, void>({
      query: () => ({
        url: "/roles",
        method: "POST",
      }),
    }),
    getRolesByGroup: build.query<{ data: any }, void>({
      query: () => ({
        url: "/roles-by-group",
        method: "GET",
      }),
    }),
    getRole: build.query<{ data: any }, any>({
      query: ({ id }) => ({
        url: `/role/get/${id}`,
        method: "GET",
      }),
    }),
    addRole: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/role/store",
        body: params,
        method: "POST",
      }),
    }),
    updateRole: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/role/update",
        body: params,
        method: "POST",
      }),
    }),
    deleteRole: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/role/delete",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetRolesMutation,
  useGetRolesByGroupQuery,
  useGetRoleQuery,
  useAddRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = roleApi;

export const {
  endpoints: {
    getRoles,
    getRolesByGroup,
    getRole,
    addRole,
    updateRole,
    deleteRole,
  },
} = roleApi;
