import { api } from "../../utills/api";

export const masterApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMasterList: build.query<{ data: any }, void>({
      query: (params) => ({
        url: `/master`,
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetMasterListQuery,
} = masterApi;

export const {
  endpoints: {
    getMasterList,
  },
} = masterApi;
