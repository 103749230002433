import { useContext } from "react";

// PROJECT IMPORT
import { LoaderContext } from "./LoaderProvider";

const useLoader = () => {
  const context = useContext(LoaderContext);
  if (!context) throw new Error("You are accessing context outside of wrapper");
  return context;
};

export default useLoader;
