import { api } from "../../utills/api";

export const promotionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPromotion: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/CampaignList",
        body: params,
        method: "POST",
      }),
    }),
    updateStatus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateCampaignStatus",
        body: params,
        method: "POST",
      }),
    }),
    deleteData: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/deleteCampaign",
        body: params,
        method: "POST",
      }),
    }),
    addPromotion: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/addUpdatePromotion",
        body: params,
        method: "POST",
      }),
    }),
    updatePromotion: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/addUpdatePromotion",
        body: params,
        method: "POST",
      }),
    }),
    updatePromotionStatus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateCampaignStatus",
        body: params,
        method: "POST",
      }),
    }),
    updatePromotionType: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/addUpdatePromotionType",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetPromotionMutation,
  useUpdateStatusMutation,
  useDeleteDataMutation,
  useAddPromotionMutation,
  useUpdatePromotionMutation,
  useUpdatePromotionStatusMutation,
  useUpdatePromotionTypeMutation,
} = promotionApi;
export const {
  endpoints: {
    getPromotion,
    updateStatus,
    deleteData,
    addPromotion,
    updatePromotion,
    updatePromotionStatus,
    updatePromotionType,
  },
} = promotionApi;
