import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { customApi } from "./customApi";

interface BonusState {
  bonusData: any;
  currentBonus: any | null;
  playerListOption?: any;
}

const initialState: BonusState = {
  bonusData: {},
  currentBonus: null,
  playerListOption: {},
};

export const bonusSlice = createSlice({
  name: "bonus",
  initialState,
  reducers: {
    clearBonus: () => initialState,
    setCurrentBonus: (state, { payload }) => {
      state.currentBonus = payload || null;
    },
    setBonusStatus: (state, { payload }) => {
      state.bonusData = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      customApi.endpoints.getBonus.matchFulfilled,
      (state, { payload }) => {
        state.bonusData = payload || {};
      }
    );
    builder.addMatcher(
      customApi.endpoints.getPlayerList.matchFulfilled,
      (state, { payload }) => {
        state.playerListOption = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default bonusSlice.reducer;
export const { clearBonus, setCurrentBonus, setBonusStatus } =
  bonusSlice.actions;

export const selectBonusData = (state: RootState) => state.custom.bonusData;
export const useBonusData = () => {
  const bonusData = useSelector(selectBonusData);
  return useMemo(() => bonusData, [bonusData]);
};

export const selectCurrentBonus = (state: RootState) =>
  state.custom.currentBonus;
export const useCurrentBonus = () => {
  const currentBonus = useSelector(selectCurrentBonus);
  return useMemo(() => currentBonus, [currentBonus]);
};

export const selectPlayerListOption = (state: RootState) =>
  state.custom.playerListOption;
export const usePlayerListOption = () => {
  const playerListOption = useSelector(selectPlayerListOption);
  return useMemo(() => playerListOption, [playerListOption]);
};
