import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { supportlogApi } from "./supportlogApi";

interface SupportLogState {
  supportlogData: any;
}

const initialState: SupportLogState = {
  supportlogData: {},
};

export const supportlogSlice = createSlice({
  name: "supportlog",
  initialState,
  reducers: {
    clearSupportlog: () => initialState,
    setBonusStatus: (state, { payload }) => {
      state.supportlogData = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      supportlogApi.endpoints.getSupportlog.matchFulfilled,
      (state, { payload }) => {
        state.supportlogData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default supportlogSlice.reducer;
export const { clearSupportlog, setBonusStatus } = supportlogSlice.actions;

export const selectsupportlogData = (state: RootState) =>
  state.supportlog.supportlogData;
export const useSupportlogData = () => {
  const supportlogData = useSelector(selectsupportlogData);
  return useMemo(() => supportlogData, [supportlogData]);
};
