import { Icon, IconKeys } from "../icons";
import { OptionTypes } from "../theme";

interface SelectProps {
  className?: string;
  inputClassName?: string;
  lableClassName?: string;
  name?: any;
  label?: string;
  value?: any;
  options: OptionTypes[];
  icon?: IconKeys;
  placeholder?: string;
  errors?: any;
  register?: any;
  onChange?: (e?: any) => void;
  disabled?: any;
}

const Select = (props: SelectProps) => {
  const {
    className = "",
    inputClassName = "",
    label,
    icon,
    options = [],
    errors,
    register,
    placeholder = "",
    name,
    lableClassName = "",
    disabled = false,
    ...rest
  } = props;

  const getRegister = (register: any) =>
    register && name ? { ...register?.(name) } : {};

  const message = errors?.[name]?.message || "";

  return (
    <div className={`${className} w-fit flex flex-col relative`}>
      {label ? (
        <label
          className={`${lableClassName} text-sm text-ev-secondary pb-[4px]`}
        >
          {label}
        </label>
      ) : null}
      <div className="flex flex-col justify-center items-center select-img">
        {icon ? (
          <Icon className="absolute me-4 text-xl ms-4" name={icon} />
        ) : null}
        <select
          name={name}
          id={name}
          disabled={disabled}
          placeholder={placeholder}
          className={`${inputClassName} text-xs ${
            message ? "border-red-500" : ""
          } ${
            icon ? "pl-11" : "pl-3"
          } pr-7 appearance-none w-full placeholder-ev-secondary text-ev-secondary bg-chatlook-secondary h-10 rounded-md outline-none  bg-opacity-5`}
          {...getRegister(register)}
          {...rest}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {/* <Icon className="absolute right-0 me-4 text-xl" name="ChevronDown" /> */}
      </div>
      {message ? <span className="text-red-500 text-sm">{message}</span> : null}
    </div>
  );
};

export default Select;
