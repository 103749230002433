import { useNavigate, useParams } from "react-router-dom";
// UI IMPORT
import { Button, Input, Loader, Select, Switch } from "../../ui";

// PROJECT IMPORT
import { useCurrentLoyalty } from "./loyaltySlice";
import { useAddLoyaltyMutation, useUpdateLoyaltyMutation } from "./loyaltyApi";
import { showToaster } from "../../helperFunctions";
import { supportOptions, withdrawOptions } from "./mock";

// THIRD - PARTY IMPORT
import { Controller, useForm } from "react-hook-form";
import { Fragment, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDomainIdData } from "../cashback/cashbackSlice";

const LoyaltyForm = () => {
  const domainIdData: any = useDomainIdData();
  const currentLoyalty: any = useCurrentLoyalty();
  const navigate = useNavigate();
  const { id } = useParams();
  const [createLoyalty, { isLoading: isCreateLoading }] =
    useAddLoyaltyMutation();
  const [updateLoyalty, { isLoading: isUpdateLoading }] =
    useUpdateLoyaltyMutation();
  const [tableData, setTableData] = useState({
    bronze: {
      min_deposit: "",
      max_deposit: "",
      min_wagering: "",
      max_wagering: "",
      losses_cashback: "",
      birthday_bonus: "",
      withdrawal: "",
      customer_support: "",
      vip_managers: 0,
      status: 0,
    },
    silver: {
      min_deposit: "",
      max_deposit: "",
      min_wagering: "",
      max_wagering: "",
      losses_cashback: "",
      birthday_bonus: "",
      withdrawal: "",
      customer_support: "",
      vip_managers: 0,
      status: 0,
    },
    gold: {
      min_deposit: "",
      max_deposit: "",
      min_wagering: "",
      max_wagering: "",
      losses_cashback: "",
      birthday_bonus: "",
      withdrawal: "",
      customer_support: "",
      vip_managers: 0,
      status: 0,
    },
    platinum: {
      min_deposit: "",
      max_deposit: "",
      min_wagering: "",
      max_wagering: "",
      losses_cashback: "",
      birthday_bonus: "",
      withdrawal: "",
      customer_support: "",
      vip_managers: 0,
      status: 0,
    },
    kohinur: {
      min_deposit: "",
      max_deposit: "",
      min_wagering: "",
      max_wagering: "",
      losses_cashback: "",
      birthday_bonus: "",
      withdrawal: "",
      customer_support: "",
      vip_managers: 0,
      status: 0,
    },
  });

  const schema = yup.object({
    domain_id: yup.string().required("This field is required."),
    bronze_min_deposit: yup.string().required("This field is required."),
    silver_min_deposit: yup.string().required("This field is required."),
    gold_min_deposit: yup.string().required("This field is required."),
    platinum_min_deposit: yup.string().required("This field is required."),
    bronze_min_wagering: yup.string().required("This field is required."),
    silver_min_wagering: yup.string().required("This field is required."),
    gold_min_wagering: yup.string().required("This field is required."),
    platinum_min_wagering: yup.string().required("This field is required."),
    bronze_max_deposit: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .moreThan(yup.ref("bronze_min_deposit"), "Max must be more than Min.")
      .required("This field is required."),
    silver_max_deposit: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .moreThan(yup.ref("silver_min_deposit"), "Max must be more than Min.")
      .required("This field is required."),
    gold_max_deposit: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .moreThan(yup.ref("gold_min_deposit"), "Max must be more than Min.")
      .required("This field is required."),
    platinum_max_deposit: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .moreThan(yup.ref("platinum_min_deposit"), "Max must be more than Min.")
      .required("This field is required."),
    kohinur_min_deposit: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .moreThan(
        yup.ref("platinum_min_deposit"),
        "Must be greater than Platinum."
      )
      .required("This field is required."),
    bronze_max_wagering: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .moreThan(yup.ref("bronze_min_wagering"), "Max must be more than Min.")
      .required("This field is required."),
    silver_max_wagering: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .moreThan(yup.ref("silver_min_wagering"), "Max must be more than Min.")
      .required("This field is required."),
    gold_max_wagering: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .moreThan(yup.ref("gold_min_wagering"), "Max must be more than Min.")
      .required("This field is required."),
    platinum_max_wagering: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .moreThan(yup.ref("platinum_min_wagering"), "Max must be more than Min.")
      .required("This field is required."),
    kohinur_min_wagering: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .moreThan(
        yup.ref("platinum_min_wagering"),
        "Must be greater than Platinum."
      )
      .required("This field is required."),
    bronze_losses_cashback: yup.string().required("This field is required."),
    silver_losses_cashback: yup.string().required("This field is required."),
    gold_losses_cashback: yup.string().required("This field is required."),
    platinum_losses_cashback: yup.string().required("This field is required."),
    kohinur_losses_cashback: yup.string().required("This field is required."),
    bronze_birthday_bonus: yup.string().required("This field is required."),
    silver_birthday_bonus: yup.string().required("This field is required."),
    gold_birthday_bonus: yup.string().required("This field is required."),
    platinum_birthday_bonus: yup.string().required("This field is required."),
    kohinur_birthday_bonus: yup.string().required("This field is required."),
    bronze_withdrawal: yup.string().required("This field is required."),
    silver_withdrawal: yup.string().required("This field is required."),
    gold_withdrawal: yup.string().required("This field is required."),
    platinum_withdrawal: yup.string().required("This field is required."),
    kohinur_withdrawal: yup.string().required("This field is required."),
    bronze_customer_support: yup.string().required("This field is required."),
    silver_customer_support: yup.string().required("This field is required."),
    gold_customer_support: yup.string().required("This field is required."),
    platinum_customer_support: yup.string().required("This field is required."),
    kohinur_customer_support: yup.string().required("This field is required."),
  });

  useEffect(() => {
    const parseJsonFields = () => {
      setTableData((prevData: any) => ({
        ...prevData,
        bronze: currentLoyalty?.bronze,
        silver: currentLoyalty?.silver,
        gold: currentLoyalty?.gold,
        platinum: currentLoyalty?.platinum,
        kohinur: currentLoyalty?.kohinur,
      }));
    };

    if (currentLoyalty) {
      parseJsonFields();
    }
  }, [currentLoyalty]);

  const prepareDefaultValues = () => {
    if (id) {
      const tempVar = {
        bronze: currentLoyalty?.bronze,
        silver: currentLoyalty?.silver,
        gold: currentLoyalty?.gold,
        platinum: currentLoyalty?.platinum,
        kohinur: currentLoyalty?.kohinur,
      };

      return {
        domain_id: currentLoyalty?.domain_id,
        bronze_min_deposit: tempVar?.bronze?.min_deposit,
        bronze_max_deposit: tempVar?.bronze?.max_deposit,
        bronze_min_wagering: tempVar?.bronze?.min_wagering,
        bronze_max_wagering: tempVar?.bronze?.max_wagering,
        bronze_losses_cashback: tempVar?.bronze?.losses_cashback,
        bronze_birthday_bonus: tempVar?.bronze?.birthday_bonus,
        bronze_withdrawal: tempVar?.bronze?.withdrawal,
        bronze_customer_support: tempVar?.bronze?.customer_support,
        bronze_vip_managers: tempVar?.bronze?.vip_managers,
        bronze_status: tempVar?.bronze?.status,
        silver_min_deposit: tempVar?.silver?.min_deposit,
        silver_max_deposit: tempVar?.silver?.max_deposit,
        silver_min_wagering: tempVar?.silver?.min_wagering,
        silver_max_wagering: tempVar?.silver?.max_wagering,
        silver_losses_cashback: tempVar?.silver?.losses_cashback,
        silver_birthday_bonus: tempVar?.silver?.birthday_bonus,
        silver_withdrawal: tempVar?.silver?.withdrawal,
        silver_customer_support: tempVar?.silver?.customer_support,
        silver_vip_managers: tempVar?.silver?.vip_managers,
        silver_status: tempVar?.silver?.status,
        gold_min_deposit: tempVar?.gold?.min_deposit,
        gold_max_deposit: tempVar?.gold?.max_deposit,
        gold_min_wagering: tempVar?.gold?.min_wagering,
        gold_max_wagering: tempVar?.gold?.max_wagering,
        gold_losses_cashback: tempVar?.gold?.losses_cashback,
        gold_birthday_bonus: tempVar?.gold?.birthday_bonus,
        gold_withdrawal: tempVar?.gold?.withdrawal,
        gold_customer_support: tempVar?.gold?.customer_support,
        gold_vip_managers: tempVar?.gold?.vip_managers,
        gold_status: tempVar?.gold?.status,
        platinum_min_deposit: tempVar?.platinum?.min_deposit,
        platinum_max_deposit: tempVar?.platinum?.max_deposit,
        platinum_min_wagering: tempVar?.platinum?.min_wagering,
        platinum_max_wagering: tempVar?.platinum?.max_wagering,
        platinum_losses_cashback: tempVar?.platinum?.losses_cashback,
        platinum_birthday_bonus: tempVar?.platinum?.birthday_bonus,
        platinum_withdrawal: tempVar?.platinum?.withdrawal,
        platinum_customer_support: tempVar?.platinum?.customer_support,
        platinum_vip_managers: tempVar?.platinum?.vip_managers,
        platinum_status: tempVar?.platinum?.status,
        kohinur_min_deposit: tempVar?.kohinur?.min_deposit,
        kohinur_max_deposit: tempVar?.kohinur?.max_deposit,
        kohinur_min_wagering: tempVar?.kohinur?.min_wagering,
        kohinur_max_wagering: tempVar?.kohinur?.max_wagering,
        kohinur_losses_cashback: tempVar?.kohinur?.losses_cashback,
        kohinur_birthday_bonus: tempVar?.kohinur?.birthday_bonus,
        kohinur_withdrawal: tempVar?.kohinur?.withdrawal,
        kohinur_customer_support: tempVar?.kohinur?.customer_support,
        kohinur_vip_managers: tempVar?.kohinur?.vip_managers,
        kohinur_status: tempVar?.kohinur?.status,
      };
    } else {
      return {
        domain_id: null,
      };
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    control,
  }: any = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...prepareDefaultValues(),
    },
  });

  const convertToPayload = (formValues: any) => {
    const payload = {
      domain_id: formValues.domain_id,
      bronze: {
        min_deposit: parseInt(formValues["bronze_min_deposit"]),
        max_deposit: parseInt(formValues["bronze_max_deposit"]),
        min_wagering: parseInt(formValues["bronze_min_wagering"]),
        max_wagering: parseInt(formValues["bronze_max_wagering"]),
        losses_cashback: parseFloat(formValues["bronze_losses_cashback"]),
        birthday_bonus: parseInt(formValues["bronze_birthday_bonus"]),
        withdrawal: formValues["bronze_withdrawal"],
        customer_support: formValues["bronze_customer_support"],
        vip_managers: formValues["bronze_vip_managers"],
        status: formValues["bronze_status"],
      },
      silver: {
        min_deposit: parseInt(formValues["silver_min_deposit"]),
        max_deposit: parseInt(formValues["silver_max_deposit"]),
        min_wagering: parseInt(formValues["silver_min_wagering"]),
        max_wagering: parseInt(formValues["silver_max_wagering"]),
        losses_cashback: parseFloat(formValues["silver_losses_cashback"]),
        birthday_bonus: parseInt(formValues["silver_birthday_bonus"]),
        withdrawal: formValues["silver_withdrawal"],
        customer_support: formValues["silver_customer_support"],
        vip_managers: formValues["silver_vip_managers"],
        status: formValues["silver_status"],
      },
      gold: {
        min_deposit: parseInt(formValues["gold_min_deposit"]),
        max_deposit: parseInt(formValues["gold_max_deposit"]),
        min_wagering: parseInt(formValues["gold_min_wagering"]),
        max_wagering: parseInt(formValues["gold_max_wagering"]),
        losses_cashback: parseFloat(formValues["gold_losses_cashback"]),
        birthday_bonus: parseInt(formValues["gold_birthday_bonus"]),
        withdrawal: formValues["gold_withdrawal"],
        customer_support: formValues["gold_customer_support"],
        vip_managers: formValues["gold_vip_managers"],
        status: formValues["gold_status"],
      },
      platinum: {
        min_deposit: parseInt(formValues["platinum_min_deposit"]),
        max_deposit: parseInt(formValues["platinum_max_deposit"]),
        min_wagering: parseInt(formValues["platinum_min_wagering"]),
        max_wagering: parseInt(formValues["platinum_max_wagering"]),
        losses_cashback: parseFloat(formValues["platinum_losses_cashback"]),
        birthday_bonus: parseInt(formValues["platinum_birthday_bonus"]),
        withdrawal: formValues["platinum_withdrawal"],
        customer_support: formValues["platinum_customer_support"],
        vip_managers: formValues["platinum_vip_managers"],
        status: formValues["platinum_status"],
      },
      kohinur: {
        min_deposit: parseInt(formValues["kohinur_min_deposit"]),
        max_deposit: parseInt(formValues["kohinur_max_deposit"]),
        min_wagering: parseInt(formValues["kohinur_min_wagering"]),
        max_wagering: parseInt(formValues["kohinur_max_wagering"]),
        losses_cashback: parseFloat(formValues["kohinur_losses_cashback"]),
        birthday_bonus: parseInt(formValues["kohinur_birthday_bonus"]),
        withdrawal: formValues["kohinur_withdrawal"],
        customer_support: formValues["kohinur_customer_support"],
        vip_managers: formValues["kohinur_vip_managers"],
        status: formValues["kohinur_status"],
      },
    };

    return payload;
  };

  const onSubmit = async (values: any) => {
    const payloadd = convertToPayload(values);
    if (id) {
      try {
        const res: any = await updateLoyalty({
          ...payloadd,
          id: currentLoyalty?.id,
        }).unwrap();
        showToaster(res?.message);
        navigate(-1);
      } catch (err) {
        console.error("Update Error =-=>", err);
      }
    } else {
      try {
        const res: any = await createLoyalty(payloadd).unwrap();
        showToaster(res?.message);
        navigate(-1);
      } catch (err) {
        console.error("Create Error =-=>", err);
      }
    }
  };

  const prepareDomainOptions = () => {
    const options = domainIdData?.data?.map((val: any) => {
      return {
        value: val?.id,
        label: val?.domain_name,
      };
    });
    return options;
  };

  return (
    <>
      <div className="">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col space-y-5"
        >
          <div className="cardcss">
            <div className="flex justify-between">
              <div className="text-lg font-semibold text-ev-green">
                <h4>Domain</h4>
              </div>
            </div>
            <Select
              className="!w-full"
              placeholder="Please Select Domain"
              name="domain_id"
              disabled={id}
              options={prepareDomainOptions()}
              register={register}
              errors={errors}
            />
          </div>
          {Object?.entries(tableData)?.map?.(([k, v], rowIndex) => {
            return (
              <Fragment key={rowIndex}>
                <div className="cardcss">
                  <div className="flex justify-between">
                    <div className="text-lg font-semibold text-ev-green capitalize mb-3">
                      <h4>{k}</h4>
                    </div>
                  </div>
                  <div>
                    <table className="w-full loyltyborder">
                      <tbody>
                        <tr className="grid grid-cols-4 items-start gap-3">
                          <td className="text-base text-ev-secondary">
                            Deposit Criteria
                          </td>
                          <td>
                            <Input
                              placeholder="Enter Min Amount"
                              className="!w-full"
                              type="number"
                              label="Min"
                              name={`${k}_min_deposit`}
                              register={register}
                              errors={errors}
                            />
                          </td>
                          {k !== "kohinur" && (
                            <td>
                              <Input
                                placeholder="Enter Max Amount"
                                className="!w-full"
                                type="number"
                                name={`${k}_max_deposit`}
                                label="Max"
                                register={register}
                                errors={errors}
                              />
                            </td>
                          )}
                        </tr>
                        <tr className="grid grid-cols-4 items-start gap-3">
                          <td className="text-base text-ev-secondary">
                            Stakes
                          </td>
                          <td>
                            <Input
                              placeholder="Enter Min Amount"
                              className="!w-full"
                              type="number"
                              name={`${k}_min_wagering`}
                              label="Min"
                              register={register}
                              errors={errors}
                            />
                          </td>
                          {k !== "kohinur" && (
                            <td>
                              <Input
                                placeholder="Enter Max Amount"
                                className="!w-full"
                                type="number"
                                name={`${k}_max_wagering`}
                                label="Max"
                                register={register}
                                errors={errors}
                              />
                            </td>
                          )}
                        </tr>
                        <tr className="grid grid-cols-4 items-start gap-3">
                          <td className="text-base text-ev-secondary">
                            Losses Cashback
                          </td>
                          <td>
                            <Input
                              placeholder="Enter Percentage"
                              className="!w-full"
                              type="text"
                              name={`${k}_losses_cashback`}
                              label="Percentage (%)"
                              register={register}
                              errors={errors}
                            />
                          </td>
                        </tr>
                        <tr className="grid grid-cols-4 items-start gap-3">
                          <td className="text-base text-ev-secondary">
                            Birthday Bonus
                          </td>
                          <td>
                            <Input
                              placeholder="Enter Amount"
                              className="!w-full"
                              type="text"
                              name={`${k}_birthday_bonus`}
                              label="Amount"
                              register={register}
                              errors={errors}
                            />
                          </td>
                        </tr>
                        <tr className="grid grid-cols-4 items-start gap-3">
                          <td className="text-base text-ev-secondary">
                            Withdrawal
                          </td>
                          <td>
                            <Select
                              placeholder="Select Withdrawal"
                              className="!w-full"
                              name={`${k}_withdrawal`}
                              label="Withdrawal"
                              options={withdrawOptions}
                              register={register}
                              errors={errors}
                            />
                          </td>
                        </tr>
                        <tr className="grid grid-cols-4 items-start gap-3">
                          <td className="text-base text-ev-secondary">
                            Customer Support
                          </td>
                          <td>
                            <Select
                              placeholder="Select Customer Support"
                              className="!w-full"
                              name={`${k}_customer_support`}
                              label="Support"
                              options={supportOptions}
                              register={register}
                              errors={errors}
                            />
                          </td>
                        </tr>
                        <tr className="grid grid-cols-4 items-start gap-3">
                          <td className="text-base text-ev-secondary">
                            VIP Managers
                          </td>
                          <td>
                            <Controller
                              name={`${k}_vip_managers`}
                              control={control}
                              render={({ field }) => (
                                <Switch
                                  checked={field.value === 1}
                                  onChange={(checked: any) =>
                                    setValue(
                                      `${k}_vip_managers`,
                                      checked ? 1 : 0
                                    )
                                  }
                                />
                              )}
                            />
                          </td>
                        </tr>
                        <tr className="grid grid-cols-4 items-start gap-3">
                          <td className="text-base text-ev-secondary">
                            Status
                          </td>
                          <td>
                            <Controller
                              name={`${k}_status`}
                              control={control}
                              render={({ field }) => (
                                <Switch
                                  checked={field.value === 1}
                                  onChange={(checked: any) =>
                                    setValue(`${k}_status`, checked ? 1 : 0)
                                  }
                                />
                              )}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Fragment>
            );
          })}
          <div className="flex items-center justify-end gap-2">
            <Button type="submit" className="mt-[10px] !h-9 !text-sm">
              {isCreateLoading || isUpdateLoading ? (
                <Loader loaderClassName="create-edit-bonus" />
              ) : id ? (
                "Update"
              ) : (
                "Add"
              )}
            </Button>
            <Button
              variant="Transaparent"
              className="mt-[10px]  !text-sm !h-9"
              onClick={() => {
                reset();
              }}
            >
              Clear
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default LoyaltyForm;
