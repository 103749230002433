import { api } from "../../utills/api";

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/UserList",
        body: params,
        method: "POST",
      }),
    }),
    updateBlackListUserStatus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateBlackList",
        body: params,
        method: "POST",
      }),
    }),
    updateBlockUserStatus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateUserIsBlock",
        body: params,
        method: "POST",
      }),
    }),
    addCustomAmount: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/addCustomAmount",
        body: params,
        method: "POST",
      }),
    }),
    uploadUserCsvFile: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateMultipleIsBlock",
        body: params,
        method: "POST",
      }),
    }),
    updateLoyaltyUserStatus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateLoyaltyUserStatus",
        body: params,
        method: "POST",
      }),
    }),
    getActiveConversionBonusList: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/activeConversionBonusList",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetUserMutation,
  useUpdateBlackListUserStatusMutation,
  useUpdateBlockUserStatusMutation,
  useUpdateLoyaltyUserStatusMutation,
  useAddCustomAmountMutation,
  useUploadUserCsvFileMutation,
  useGetActiveConversionBonusListMutation,
} = userApi;
export const {
  endpoints: {
    getUser,
    updateBlackListUserStatus,
    addCustomAmount,
    updateBlockUserStatus,
    updateLoyaltyUserStatus,
    uploadUserCsvFile,
    getActiveConversionBonusList,
  },
} = userApi;
