import { useEffect, useState, Fragment } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

// UI IMPORT
import { Button, Table, TableRow } from "../../ui";

// PROJECT IMPORT
import { useDeleteRoleMutation, useGetRolesMutation } from "./roleApi";
import { responseToaster } from "../../helperFunctions";
import DeleteModal from "../common/DeleteModal";
import { FILTER } from "../../constant";
import { setCurrentRole, useRoles } from "./roleSlice";
import { useAppDispatch } from "../../utills/hooks";
import { useUser } from "../auth/authSlice";

const Role = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useUser();
  const route = useLocation()?.pathname;

  const roles: any = useRoles();

  const [filter, setFilter] = useState(FILTER);
  const [deleteId, setDeleteId] = useState<any>(false);
  const [tableData, setTableData] = useState<any>([]);

  const [getRoles, { isLoading }] = useGetRolesMutation();
  const [deleteRole, { isLoading: isDeleteLoading }] = useDeleteRoleMutation();

  const columns = [
    {
      title: "Name",
      name: "name",
      sortable: true,
      sortableKey: "name",
    },
    {
      title: "Permissions",
      name: "per",
      className: "!max-w-[300px]",
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2 flex-wrap">
            {item?.per?.map((subItem: any) => {
              return (
                <div className="bg-ev-green text-white p-1 rounded-md">
                  {subItem?.name}
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  if (
    user?.access_level?.ROLE_EDIT ||
    user?.access_level?.ROLE_DELETE
  ) {
    const newCol: any = {
      title: "Actions",
      name: "actions",
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            {user?.access_level?.ROLE_EDIT ? (
              <Button
                className="!h-7 w-8 !p-0"
                title="Edit"
                onClick={() => {
                  dispatch(setCurrentRole(item));
                  navigate(`${route}/${item?.id}`);
                }}
              >
                <MdEdit />
              </Button>
            ) : null}
            {user?.access_level?.ROLE_DELETE ? (
              <Button
                title="Delete"
                className="!h-7 w-8 !p-0"
                variant="Transaparent"
                onClick={() => {
                  setDeleteId(item?.id);
                }}
              >
                <MdDelete />
              </Button>
            ) : null}
          </div>
        );
      },
    }
    columns?.push(newCol);
  }

  const onGetData = async () => {
    try {
      const res: any = await getRoles();
      if (filter?.isFilter) {
        responseToaster(res?.data);
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setTableData(roles?.data?.length ? [...roles?.data] : []);
  }, [roles]);

  const onDelete = async () => {
    try {
      const payload = {
        id: deleteId,
      };
      const res = await deleteRole(payload).unwrap();
      responseToaster(res);
      setDeleteId(false);
      setFilter({ ...filter, isFilter: false });
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  return (
    <>
      <div className="">
        {user?.access_level?.ROLE_CREATE ? (
          <div className="cardcss mb-3 flex items-centere justify-between pb-3">
            <Button
              type="button"
              onClick={() => {
                dispatch(setCurrentRole(null));
                navigate(`${route}/create`);
              }}
            >
              Add Role
            </Button>
          </div>
        ) : null}
        <div>
          <div className="App tableborder bg-chatlook-white">
            <Table
              columns={columns}
              isLoading={isLoading}
              data={tableData}
              isExpendable={false}
              count={roles?.total_item || 0}
              pagination={filter}
              setData={setTableData}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      item={item}
                      isExpendable={false}
                    />
                  </Fragment>
                );
              })}
            </Table>
          </div>
        </div>
      </div>
      <DeleteModal
        isLoading={isDeleteLoading}
        isOpen={!!deleteId}
        onHide={() => {
          setDeleteId(false);
        }}
        onDoneClick={() => onDelete()}
      />
    </>
  );
};

export default Role;
