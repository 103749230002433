import { api } from "../../utills/api";

export const bonusApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBonus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/CampaignList",
        body: params,
        method: "POST",
      }),
    }),
    updateStatus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateCampaignStatus",
        body: params,
        method: "POST",
      }),
    }),
    deleteData: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/deleteCampaign",
        body: params,
        method: "POST",
      }),
    }),
    addBonus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/addCampaign",
        body: params,
        method: "POST",
      }),
    }),
    updateBonus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateCampaign",
        body: params,
        method: "POST",
      }),
    }),
    updateBonusStatus: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateCampaignStatus",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetBonusMutation,
  useUpdateStatusMutation,
  useDeleteDataMutation,
  useAddBonusMutation,
  useUpdateBonusMutation,
  useUpdateBonusStatusMutation,
} = bonusApi;
export const {
  endpoints: {
    getBonus,
    updateStatus,
    deleteData,
    addBonus,
    updateBonus,
    updateBonusStatus,
  },
} = bonusApi;
