import { useEffect, useState, Fragment, useRef } from "react";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { CiFilter } from "react-icons/ci";

// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  ControlledInput,
  Export,
  getStatusTypes,
  Icon,
  Select,
  Table,
  TableRow,
} from "../../ui";

// PROJECT IMPORT
import {
  dateFormatter,
  responseToaster,
  seperator,
} from "../../helperFunctions";
import {
  useGetActiveDomainListQuery,
  useGetCashbackMutation,
} from "./cashbackApi";
import {
  setCurrentCashback,
  useCashbackData,
  useDomainIdData,
} from "./cashbackSlice";
import { FILTER } from "../../constant";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CashbackModal from "./CashbackModal";
import DeleteCashbackModal from "./DeleteCashbackModal";

// const getDefaultDates = () => {
//   const date = new Date();
//   return {
//     date: [new Date(date.setDate(date.getDate() - 30)), new Date()],
//   };
// };

const initialValues = {
  client: "ALL",
  // ...getDefaultDates(),
};

const statusOptions = [
  {
    value: 1,
    label: "Active",
  },
  {
    value: 0,
    label: "Deactive",
  },
];

const Cashback = () => {
  const cashbackData = useCashbackData();
  const domainIdData = useDomainIdData();
  // const [statusManualLoading, setStatusManualLoading] = useState<any>(false);
  const [filter, setFilter] = useState(FILTER);
  const [showDiv, setShowDiv] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<any>(false);
  const [deleteData, setDeleteData] = useState({});
  useGetActiveDomainListQuery(undefined, { refetchOnMountOrArgChange: true });
  const [getCashback, { isLoading }] = useGetCashbackMutation();
  // const [updateCashbackStatus] = useUpdateCashbackStatusMutation();
  // const [deleteData] = useDeleteDataMutation();
  const [tableData, setTableData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const isInitialMount = useRef(true);
  const [isCashbackModalOpen, setIsCashbackModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const onDeleteStatus = async (payload: any) => {
  //   try {
  //     const res: any = await deleteData({ id: payload }).unwrap();
  //
  //     showToaster(res?.message);
  //
  //     await onGetData();
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // const onUpdateStatusManual = async (payload: any) => {
  //   try {
  //     setStatusManualLoading(payload?.id);

  //     const res: any = await updateCashbackStatus(payload).unwrap();

  //     const index = cashbackData?.data?.findIndex(
  //       (item: any) => item?.id === payload?.id
  //     );
  //     if (index > -1) {
  //       const newData = [...cashbackData.data];
  //       newData[index] = {
  //         ...newData[index],
  //         status: payload?.status,
  //       };
  //       dispatch(setCashbackStatus({ ...cashbackData, data: newData }));
  //     }
  //     responseToaster(res);
  //     setStatusManualLoading(false);
  //   } catch (err) {
  //     setStatusManualLoading(false);
  //   }
  // };

  const route = useLocation()?.pathname;

  const columns = [
    {
      title: "#",
      sortable: false,
      name: "View",
      Cell: (data: any) => (
        <div className="relative">
          <Icon
            name="EyeOn"
            onClick={() => {
              setIsCashbackModalOpen(true);
              setCurrentData(data);
            }}
          />
        </div>
      ),
    },
    {
      title: "Domain Name",
      name: "domain_name",
      sortable: true,
      sortableKey: "domain_name",
    },
    {
      title: "Name",
      name: "name",
      sortable: true,
      sortableKey: "name",
    },
    {
      title: "Type",
      name: "type",
      sortable: true,
      sortableKey: "type",
      Cell: (item: any) => {
        if (item.type === 'paymentmethod') {
          return (
              <span>Payment Method</span>
          );
        } else {
          return (
              <span className="capitalize">{item?.type}</span>
          );
        }
      },
    },
    {
      title: "Promo Code",
      name: "promo_code",
      sortable: true,
      sortableKey: "promo_code",
    },
    {
      title: "Cashback Amt/Per",
      name: "bonus_amount",
      sortable: true,
      sortableKey: "bonus_amount",
      Cell: (item: any) => {
        return (
          <span>
            {item?.bonus_type === "percentage"
              ? `${item?.bonus_amount}%`
              : seperator(item?.bonus_amount)}
          </span>
        );
      },
    },
    {
      title: "Start Date",
      name: "start_date",
      sortable: true,
      type: "date",
      sortableKey: "start_date",
      Cell: (item: any) => {
        return (
          <span>
            {dateFormatter(item?.start_date)}
            <br />
            <small>( {item?.start_date_readable} )</small>
          </span>
        );
      },
    },
    // {
    //   title: "End Date",
    //   name: "end_date",
    //   sortable: true,
    //   type: "date",
    //   sortableKey: "end_date",
    //   Cell: (item: any) => {
    //     return (
    //         <span>
    //           {dateFormatter(item?.end_date)}
    //           <br/>
    //           <small>( {item?.end_date_readable} )</small>
    //         </span>
    //     );
    //   },
    // },
    {
      title: "End Date",
      name: "end_date",
      sortable: true,
      type: "date",
      sortableKey: "end_date",
      Cell: (item: any) => {
        return (
            <span>
            {dateFormatter(item?.end_date)}
              <br/>
            <small>( {item?.end_date_readable} )</small>
          </span>
        );
      },
    },
    // {
    //   title: "Last Updated",
    //   name: "updated_at",
    //   Cell: (item: any) => {
    //     return (
    //         <span>
    //           {dateFormatter(item?.updated_at)}
    //           <br/>
    //           <small>( {item?.updated_at_readable} )</small>
    //         </span>
    //     );
    //   },
    // },
    {
      title: "Created",
      name: "created_at",
      Cell: (item: any) => {
        return (
          <span>
            {dateFormatter(item?.created_at)}
            <br />
            <small>( {item?.created_at_readable} )</small>
          </span>
        );
      },
    },
    {
      title: "Status",
      name: "status",
      // sortable: true,
      // sortableKey: "status",
      Cell: (data: any) => {
        return (
          <div>
            {/* <ToggleSwitch
              isLoading={isStatusLoading === data?.domain_id}
              value={data?.status ? 1 : 0}
              options={statusOption}
              onChange={async (value: any, e: any) => {
                e.stopPropagation();
                try {
                  const payload = {
                    status: value,
                    id: data?.id,
                  };
                  await onUpdateStatus(payload);
                } catch (err) {}
              }}
            /> */}
            {/*<Switch*/}
            {/*  checked={!!data?.status}*/}
            {/*  isLoading={statusManualLoading === data?.id}*/}
            {/*  onChange={async (value: any, e: any) => {*/}
            {/*    e.stopPropagation();*/}
            {/*    try {*/}
            {/*      const payload = {*/}
            {/*        status: value ? 1 : 0,*/}
            {/*        id: data?.id,*/}
            {/*      };*/}
            {/*      await onUpdateStatusManual(payload);*/}
            {/*    } catch (err) {}*/}
            {/*  }}*/}
            {/*/>*/}
            <span className={`${getStatusTypes(data?.status)}`}>
              {data?.status ? "Active" : "Deactive"}
            </span>
          </div>
        );
      },
    },
    {
      title: "Actions",
      name: "actions",
      export: false,
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            <Button
              className="!h-7 w-8 !p-0"
              onClick={() => {
                dispatch(setCurrentCashback(item));
                navigate(`${route}/${item?.id}`);
              }}
            >
              <MdEdit />
            </Button>
            <Button
              className="!h-7 w-8 !p-0"
              variant="Transaparent"
              onClick={() => {
                setIsDeleteModalOpen(true);
                setDeleteData(item);
              }}
            >
              <MdDelete />
            </Button>
          </div>
        );
      },
    },
  ];

  const { register, handleSubmit, setValue, reset, getValues, control } =
    useForm<any>({
      defaultValues: {
        ...initialValues,
      },
    });

  const onGetData = async () => {
    try {
      const values = getValues();

      const payload: any = {
        ...filter,
        filter_data: {
          searchData: searchTerm,
          category: "cashback",
          domainId: values?.domainId,
          type: values?.type,
          status: values?.status,
          createdStartDate: dateFormatter(values?.date?.[0], "date"),
          createdEndDate: dateFormatter(values?.date?.[1], "date"),
          startDate: dateFormatter(values?.startDate?.[0], "date"),
          endDate: dateFormatter(values?.startDate?.[1], "date"),
          endStartDate: dateFormatter(values?.endDate?.[0], "date"),
          endEndDate: dateFormatter(values?.endDate?.[1], "date"),
        },
      };
      delete payload["isFilter"];
      const res: any = await getCashback(payload);
      if (filter?.isFilter) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async () => {
    setFilter({ ...FILTER, isFilter: true });
  };

  const prepareDomainOptions = () => {
    const options: any = domainIdData?.data?.map((val: any) => {
      return {
        value: val?.id,
        label: val?.domain_name,
      };
    });
    return options?.length ? [{ value: "All", label: "All" }, ...options] : [];
  };

  const prepareCampaignOptions = () => {
    const options: any = cashbackData?.type?.map((val: any) => {
      return {
        value: val?.type,
        label: val?.type,
      };
    });
    return options?.length ? [{ value: "All", label: "All" }, ...options] : [];
  };

  useEffect(() => {
    setTableData(cashbackData?.data?.length ? [...cashbackData?.data] : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashbackData]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const debounceFunction = setTimeout(() => {
      setFilter({ ...filter, page_no: 1 });
    }, 1000);

    return () => clearTimeout(debounceFunction);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <>
      <div className="">
        <div className="cardcss mb-3 flex items-center justify-between  pb-3">
          <ControlledInput
            name="searchData"
            className="w-100"
            rightIcon={searchTerm !== "" ? "Closeicon" : null}
            righticonClick={() => setSearchTerm("")}
            placeholder="Enter search value"
            control={control}
            value={searchTerm}
            type="text"
            autoComplete="off"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
          <div className="flex gap-2">
            <Button
              type="button"
              variant="Transaparent"
              onClick={() => {
                setShowDiv(!showDiv); // Toggle the value
              }}
            >
              <CiFilter className="text-lg" />
            </Button>
            <Button
              onClick={() => {
                navigate(`${route}/create`);
              }}
            >
              Add Cashback
            </Button>

            <Export
              variant="Transaparent"
              filename="download.csv"
              data={cashbackData?.data || []}
              headers={
                columns
                  ?.filter((item) => item?.export !== false)
                  ?.map((val) => ({
                    label: val?.title,
                    key: val?.name,
                  })) || []
              }
            >
              Export
            </Export>
          </div>
        </div>
        {showDiv && (
          <div className="cardcss mb-3">
            <form
              className="flex flex-wrap gap-2 items-end"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Select
                name="domainId"
                label="Domain "
                options={prepareDomainOptions()}
                register={register}
              />
              <Select
                name="type"
                label="Type"
                options={prepareCampaignOptions()}
                register={register}
              />
              <ControlledDatePicker
                inputClassName="!w-52"
                name="date"
                label="Cashback Create Date"
                placeholder="Select Create Date"
                selectsRange
                maxDate={new Date()}
                control={control}
              />
              <ControlledDatePicker
                inputClassName="!w-52"
                name="startDate"
                label="Cashback Start Date"
                placeholder="Select Cashback Start Date"
                selectsRange
                control={control}
              />
              <ControlledDatePicker
                inputClassName="!w-52"
                name="endDate"
                label="Cashback End Date"
                placeholder="Select Cashback End Date"
                selectsRange
                control={control}
              />
              <Select
                name="status"
                label="Status"
                placeholder="All"
                options={statusOptions}
                register={register}
              />
              <Button type="submit" className="!h-10 !text-sm">
                Apply
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm !h-10 "
                onClick={() => {
                  reset();
                  setFilter({ ...FILTER, isFilter: true });
                  isInitialMount.current = true;
                  setSearchTerm("");
                  setValue("searchData", "");
                }}
              >
                Clear
              </Button>
            </form>
          </div>
        )}

        <div className="App tableborder bg-chatlook-white">
          <Table
            columns={columns}
            isLoading={isLoading}
            setData={setTableData}
            data={cashbackData?.data}
            isExpendable={false}
            count={cashbackData?.total_item || 0}
            pagination={filter}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
                isFilter: false,
              });
            }}
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    item={item}
                    isExpendable={false}
                  />
                </Fragment>
              );
            })}
          </Table>
        </div>
      </div>
      <CashbackModal
        isOpen={isCashbackModalOpen}
        data={currentData}
        onClose={() => {
          setIsCashbackModalOpen(false);
          setCurrentData({});
        }}
      />
      <DeleteCashbackModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setDeleteData({});
        }}
        onGet={() => setFilter({ ...filter, isFilter: false })}
        tobeDeleteData={deleteData}
      />
    </>
  );
};

export default Cashback;
