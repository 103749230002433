import { useIsDrawerOpen } from "../common/commonSlice";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "../../ui";

const SideBarMenu = (props: any) => {
  const { name, route, icon_class, onClick } = props;

  const location = useLocation();
  const isDrawerOpen = useIsDrawerOpen();

  const isSelectedRoute = () => {
    const routes = location.pathname?.split("/");
    return route === `/${routes?.[1]}`;
  };

  return (
    <ul className="mainMenu" key={name}>
      <li onClick={onClick}>
        <div title={name}>
          <Link
            to={{ pathname: route }}
            className={`${isSelectedRoute() && "activeMenu"} ${isDrawerOpen ? "justify-between" : "justify-center"
              }`}
          >
            <div className={`flex ${isDrawerOpen ? " px-5" : "py-2.5"}`}>
              <Icon
                name={icon_class}
                className={`${isDrawerOpen ? "me-4" : ""}  `}
              />
              {isDrawerOpen ? (
                <span className="text-capitalize text-base">{name}</span>
              ) : null}
            </div>
            {props?.children && <i className="fa-solid fa-angle-right"></i>}
          </Link>
        </div>
      </li>
    </ul>
  );
};

export default SideBarMenu;
