import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { cashbackApi } from "./cashbackApi";

interface CashbackState {
  cashbackData: any;
  domainIdData: any;
  currentCashback: any | null;
}

const initialState: CashbackState = {
  cashbackData: {},
  domainIdData: {},
  currentCashback: null,
};

export const cashbackSlice = createSlice({
  name: "cashback",
  initialState,
  reducers: {
    clearCashback: () => initialState,
    setCurrentCashback: (state, { payload }) => {
      state.currentCashback = payload || null;
    },
    setCashbackStatus: (state, { payload }) => {
      state.cashbackData = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      cashbackApi.endpoints.getCashback.matchFulfilled,
      (state, { payload }) => {
        state.cashbackData = payload || {};
      }
    );
    builder.addMatcher(
      cashbackApi.endpoints.getActiveDomainList.matchFulfilled,
      (state, { payload }) => {
        state.domainIdData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default cashbackSlice.reducer;
export const { clearCashback, setCurrentCashback ,setCashbackStatus} = cashbackSlice.actions;

export const selectCashbackData = (state: RootState) => state.cashback.cashbackData;
export const useCashbackData = () => {
  const cashbackData = useSelector(selectCashbackData);
  return useMemo(() => cashbackData, [cashbackData]);
};

export const selectCurrentCashback = (state: RootState) =>
  state.cashback.currentCashback;
export const useCurrentCashback = () => {
  const currentCashback = useSelector(selectCurrentCashback);
  return useMemo(() => currentCashback, [currentCashback]);
};

export const selectDomainIdData = (state: RootState) =>
  state.cashback.domainIdData;
export const useDomainIdData = () => {
  const domainIdData = useSelector(selectDomainIdData);
  return useMemo(() => domainIdData, [domainIdData]);
};
