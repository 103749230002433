import { api } from "../../utills/api";

export const adminApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAdminRoles: build.query<{ data: any }, void>({
      query: () => ({
        url: "/getAllRoles",
        method: "GET",
      }),
    }),
    getAdminPermissions: build.query<{ data: any }, void>({
      query: () => ({
        url: "/getAllPermissions",
        method: "GET",
      }),
    }),
    getAdmins: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/adminList",
        body: params,
        method: "POST",
      }),
    }),
    addAdmin: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/addAdmin",
        body: params,
        method: "POST",
      }),
    }),
    updateAdmin: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateAdmin",
        body: params,
        method: "POST",
      }),
    }),
    deleteAdmin: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/deleteAdmin",
        body: params,
        method: "POST",
      }),
    }),
    logoutAllAdmin: build.mutation<{ data: any }, void>({
      query: () => ({
        url: "/logoutAllDevices",
        method: "POST",
      }),
    }),
    resetAdminPassword: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/resetAdminPassword",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetAdminRolesQuery,
  useLazyGetAdminRolesQuery,
  useGetAdminPermissionsQuery,
  useLazyGetAdminPermissionsQuery,
  useGetAdminsMutation,
  useAddAdminMutation,
  useUpdateAdminMutation,
  useDeleteAdminMutation,
  useLogoutAllAdminMutation,
  useResetAdminPasswordMutation,
} = adminApi;

export const {
  endpoints: {
    getAdminRoles,
    getAdminPermissions,
    getAdmins,
    addAdmin,
    updateAdmin,
    deleteAdmin,
    logoutAllAdmin,
    resetAdminPassword,
  },
} = adminApi;
