import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { userApi } from "./userApi";

interface UserState {
  userData: any;
  bonusListOption?: any;
}

const initialState: UserState = {
  userData: {},
  bonusListOption: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUser: () => initialState,
    setUser: (state, { payload }) => {
      state.userData = payload;
    },
    setBlockUser: (state, { payload }) => {
      state.userData = payload;
    },
    setActiveConversionBonusListMutation: (state, { payload }) => {
      state.userData = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getUser.matchFulfilled,
      (state, { payload }) => {
        state.userData = payload || {};
      }
    );
    builder.addMatcher(
        userApi.endpoints.getActiveConversionBonusList.matchFulfilled,
        (state, { payload }) => {
          state.bonusListOption = payload || {};
        }
    );
  },
});

// Action creators are generated for each case reducer function
export default userSlice.reducer;
export const { clearUser, setUser,setBlockUser, setActiveConversionBonusListMutation } = userSlice.actions;

export const selectUserData = (state: RootState) => state.user.userData;
export const useUserData = () => {
  const userData = useSelector(selectUserData);
  return useMemo(() => userData, [userData]);
};
export const selectBonusListOption = (state: RootState) =>
    state.user.bonusListOption;
export const useBonusListOption = () => {
  const bonusListOption = useSelector(selectBonusListOption);
  return useMemo(() => bonusListOption, [bonusListOption]);
};