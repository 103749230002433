// UI IMPORT
import { Icon, IconKeys } from "../icons";

// THIRD - PARTY IMPORT
import "rc-time-picker/assets/index.css";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider, createTheme } from "@mui/material";

export interface TimePickerProps {
  className?: string;
  inputClassName?: string;
  name?: any;
  label?: string;
  value?: any;
  onChange?: (dates?: any) => void;
  placeholder?: string;
  disabled?: any;
  icon?: IconKeys;
  rightIcon?: IconKeys;
  errors?: any;
  defaultValue?: any;
  maxTime?: any;
  minTime?: any;
}

const TimePickers = (props: TimePickerProps) => {
  const {
    className = "",
    inputClassName = "",
    label,
    icon,
    rightIcon,
    errors,
    name,
    defaultValue,
    maxTime,
    minTime,
    ...rest
  } = props;

  const message = errors?.[name]?.message || "";
  const darkTheme = createTheme({
    palette: {
      // type: 'dark', // Set the theme type to 'dark'
    },
  });

  return (
    <div className={`${className} flex flex-col relative`}>
      {label ? (
        <label className="text-ev-darkblue text-sm">{label}</label>
      ) : null}
      {icon ? (
        <Icon className="absolute me-4 text-xl ms-4" name={icon} />
      ) : null}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={darkTheme}>

        <TimePicker
          className={`${inputClassName} text-xs ${
            message ? "times-error" : ""
          } w-full bg-chatlook-secondary text-ev-white text-base h-10 rounded-md  outline-none border-[1px] border-[#222A40] focus:border-chatlook-secondary timeinput`}
          closeOnSelect={false}
          slotProps={{
            actionBar: {
              actions: ["clear", "accept"],
            },
          }}
          timeSteps={{
            minutes: 1,
          }}
          maxTime={maxTime}
          minTime={minTime}
          {...rest}
        />
        </ThemeProvider>
      </LocalizationProvider>

      {rightIcon ? (
        <Icon className="absolute right-0 me-4 text-xl" name={rightIcon} />
      ) : null}
      {message ? <span className="text-red-500 text-sm">{message}</span> : null}
    </div>
  );
};

export default TimePickers;
