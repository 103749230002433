import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { manualApi } from "./manualApi";

interface ManualState {
  manualData: any;
}

const initialState: ManualState = {
  manualData: {},
};

export const manualSlice = createSlice({
  name: "manual",
  initialState,
  reducers: {
    clearManual: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      manualApi.endpoints.getManual.matchFulfilled,
      (state, { payload }) => {
        state.manualData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default manualSlice.reducer;
export const { clearManual } = manualSlice.actions;

export const selectManualData = (state: RootState) =>
  state.manual.manualData;
export const useManualData = () => {
  const depoData = useSelector(selectManualData);
  return useMemo(() => depoData, [depoData]);
};
