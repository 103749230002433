import { useLayoutEffect, useState } from "react";

// UI IMPORT
import { Loader } from "./ui";

// PROJECT IMPORT
import AllRoutes from "./components/routes/AllRoutes";
import { prepareBlobUrl, setStyles } from "./helperFunctions";
import "./App.css";

// THIRD - PARTY IMPORT
import { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";
import { useLoader } from "./components";

function App() {
  const { isLoading, setIsLoading } = useLoader();
  const color: any = process.env.REACT_APP_COLOR;
  const logo = process.env.REACT_APP_FAVICON;

  useLayoutEffect(() => {
    if (color) {
      setIsLoading(true);
      setStyles("--chatlook-secondary--", color);
    }
    setIsLoading(false);
  }, [color]);

  if (isLoading) {
    return (
      <>
        <div className="w-[100vw] h-[100vh] z-[9999] flex items-center justify-center">
          <Loader />
        </div>
      </>
    );
  }

  return (
    <div className="App">
      <Helmet>
        <link rel="icon" type="image/svg+xml" href={prepareBlobUrl(logo)} />
      </Helmet>
      <AllRoutes />
      <Toaster />
    </div>
  );
}

export default App;
