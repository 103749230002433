import { api } from "../../utills/api";

export const settlementApi = api.injectEndpoints({
  endpoints: (build) => ({
    getResellerSettlement: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/getResellerSettlement",
        body: params,
        method: "POST",
      }),
    }),
    getResellerSettlementSummary: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/getSettlementSummary",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetResellerSettlementMutation,
  useGetResellerSettlementSummaryMutation,
} = settlementApi;
export const {
  endpoints: { getResellerSettlement, getResellerSettlementSummary },
} = settlementApi;
