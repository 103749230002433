import { useState } from "react";
import { Button, Icon } from "../../ui";
import { IoMdCloseCircle } from "react-icons/io";
import { CSVLink } from "react-csv";

const sampleCSVData = [["player_id"], [123456789], [123456780]];

const UploadCSV = (props: any) => {
  const { isOpen, onClose, onPlayers } = props;
  const [csvFile, setCsvFile] = useState<any>();
  const [csvError, setCsvError] = useState<any>();
  const [playerId, setPlayerId] = useState<any>();

  const onSubmit = () => {
    onPlayers(playerId);
    onHide();
  };

  const closeUploadCSV = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setCsvFile("");
    setCsvError("");
    setPlayerId("");
  };

  const onHide = () => {
    setCsvFile("");
    setCsvError("");
    setPlayerId("");
    onClose();
  };

  const resetData = () => {
    setCsvFile("");
    setCsvError("");
    setPlayerId("");
  }

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[99999] flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          onHide();
        }}
      />

      <div className="modal-container bg-white w-4/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12  md:w-7/12 sm:w-7/12 md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content p-5 text-left overflow-scroll max-h-[98vh] bg-chatlook-primary border rounded-[8px]">
          <div className="grid items-start gap-3 pb-3">
            <div className="proof-sec">
              <div className="flex justify-between items-center text-ev-secondary">
                <label className={`text-sm pb-[4px]`}>
                  Upload CSV ( Max Players Limit: 1000 )
                </label>
                <CSVLink
                  data={sampleCSVData}
                  filename="sample.csv"
                  className="font-semibold text-sm"
                >
                  Sample
                </CSVLink>
              </div>
              <label className="label">
                <>
                  <input
                    type="file"
                    accept="text/csv"
                    onChange={(e: any) => {
                      resetData();
                      const file: any = e.target.files?.[0];
                      const isCSV: boolean = file.type === "text/csv";
                      if (isCSV) {
                        const reader = new FileReader();
                        reader.onload = (e: any) => {
                          const csvData = e.target.result;
                          const rows = (csvData.split(/\r\n|\n/)).map((row: any) => row.replace(/"/g, ''));
                          const header = rows[0].trim();
                          let playerId: any = [];
                          if (header !== 'player_id') {
                            setCsvError("Invalid CSV Format");
                          } else {
                            rows?.forEach((val: any) => {
                              const data = val.trim();
                              if (data) {
                                if (data !== 'player_id' && data) playerId.push(data);
                              }
                            });
                            const uniqueArrayFilter = playerId.filter(
                              (value: any, index: any, self: any) => {
                                return self.indexOf(value) === index;
                              }
                            );
                            if (uniqueArrayFilter.length > 1000)
                              setCsvError("Limit Exceed Error");
                            setPlayerId(uniqueArrayFilter);
                            
                          }
                        };
                        reader.readAsText(file);
                      } else {
                        setCsvError("Only CSV File Allowed");
                      }
                      if (file) {
                        setCsvFile(file.name);
                        e.target.value = null;
                      }
                    }}
                  />
                  <p className="relative h-[91px]">
                    {csvFile ? (
                      csvFile
                    ) : (
                      <Icon name="UploadIcon" className="w-10 h-10 m-auto" />
                    )}
                    {csvFile ? (
                      <IoMdCloseCircle
                        size={25}
                        className="absolute right-1 top-1"
                        style={{ color: "#037756" }}
                        onClick={(e) => closeUploadCSV(e)}
                      />
                    ) : (
                      <span className="font-semibold pt-2 text-ev-green">
                        Click to Upload CSV
                      </span>
                    )}
                    {csvError ? (
                      <span className="font-semibold pt-2 text-red-600">
                        {csvError}
                      </span>
                    ) : playerId ? (
                      <span className="font-semibold pt-2 text-ev-green">
                        ( Total Players: {playerId.length} )
                      </span>
                    ) : null}
                  </p>
                </>
              </label>
            </div>
          </div>
          <div className="flex pt-4 items-center justify-end gap-3">
            <Button type="button" onClick={onSubmit}>
              Upload
            </Button>
            <Button variant="Transaparent" onClick={onHide}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadCSV;
