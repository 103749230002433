import { useEffect, useState, Fragment } from "react";

// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  Export,
  Select,
  Table,
  TableRow,
} from "../../ui";

// PROJECT IMPORT
import {
  dateFormatter,
  prepareMaxDate,
  seperator,
  showToaster,
} from "../../helperFunctions";
import { summaryData } from "./mock";
import { useGetDepositMutation } from "./depositApi";
import { useDepositData } from "./depositSlice";
import { FILTER } from "../../constant";
import { Card } from "../common";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";

const getDefaultDates = () => {
  const date = new Date();
  return {
    date: [new Date(date.setDate(date.getDate() - 30)), new Date()],
  };
};

const initialValues = {
  client: "ALL",
  ...getDefaultDates(),
};

const Deposit = () => {
  const depoData = useDepositData();
  const [filter, setFilter] = useState(FILTER);
  const [getDeposit, { isLoading }] = useGetDepositMutation();
  const [maxDate, setMaxDate] = useState<any>(new Date());
  const [lastFilter, setLastFilter] = useState(initialValues);

  const columns = [
    {
      title: "Date",
      name: "date",
    },
    {
      title: "Total Txn",
      name: "total_txn",
      // Cell: (item: any) => {
      //   return (
      //     <div>
      //       <p>
      //         Cust# : <span className="celldetail">{item?.customer_id}</span>
      //       </p>
      //       <p>
      //         Email :
      //         <span className="celldetail">{item?.customer_email || "-"}</span>
      //       </p>
      //       <p>
      //         Phone :
      //         <span className="celldetail">{item?.customer_mobile || "-"}</span>
      //       </p>
      //     </div>
      //   );
      // },
    },
    {
      title: "Total Payin Amount",
      name: "total_payin_amount",
      Cell: (item: any) => {
        return <span>{seperator(item?.total_payin_amount)}</span>;
      },
    },
    {
      title: "Success Payin Amount",
      name: "success_payin_amount",
      Cell: (item: any) => {
        return <span>{seperator(item?.success_payin_amount)}</span>;
      },
      // Cell: (item: any) => {
      //   return (
      //     <div>
      //       <p>
      //         Payment :
      //         <span className="celldetail">
      //           {seperator(item?.payment_amount)}
      //         </span>
      //       </p>
      //       <p>
      //         PG Fees :
      //         <span className="celldetail">{seperator(item?.pg_fees)}</span>
      //       </p>
      //       <p>
      //         Assoc Fees :
      //         <span className="celldetail">
      //           {seperator(item?.associate_fees)}
      //         </span>
      //       </p>
      //       <p>
      //         Settled :
      //         <span className="celldetail">
      //           {seperator(item?.payable_amount)}
      //         </span>
      //       </p>
      //     </div>
      //   );
      // },
    },
    {
      title: "Commission",
      name: "commission",
      Cell: (item: any) => {
        return <span>{seperator(item?.commission)}</span>;
      },
    },
  ];
  const depositeColumns = [
    {
      title: "Name",
      name: "merchant_name",
    },
    {
      title: "Total Txn",
      name: "total_txn",
      // Cell: (item: any) => {
      //   return (
      //     <div>
      //       <p>
      //         Cust# : <span className="celldetail">{item?.customer_id}</span>
      //       </p>
      //       <p>
      //         Email :
      //         <span className="celldetail">{item?.customer_email || "-"}</span>
      //       </p>
      //       <p>
      //         Phone :
      //         <span className="celldetail">{item?.customer_mobile || "-"}</span>
      //       </p>
      //     </div>
      //   );
      // },
    },
    {
      title: "Total Payin Amount",
      name: "total_payin_amount",
      Cell: (item: any) => {
        return <span>{seperator(item?.total_payin_amount)}</span>;
      },
      // Cell: (item: any) => {
      //   return (
      //     <span className={`${getStatusTypes(item?.payment_status)}`}>
      //       {item?.payment_status}
      //     </span>
      //   );
      // },
    },
    {
      title: "Success Payin Amount",
      name: "success_payin_amount",
      Cell: (item: any) => {
        return <span>{seperator(item?.success_payin_amount)}</span>;
      },
      // Cell: (item: any) => {
      //   return (
      //     <div>
      //       <p>
      //         Payment :
      //         <span className="celldetail">
      //           {seperator(item?.payment_amount)}
      //         </span>
      //       </p>
      //       <p>
      //         PG Fees :
      //         <span className="celldetail">{seperator(item?.pg_fees)}</span>
      //       </p>
      //       <p>
      //         Assoc Fees :
      //         <span className="celldetail">
      //           {seperator(item?.associate_fees)}
      //         </span>
      //       </p>
      //       <p>
      //         Settled :
      //         <span className="celldetail">
      //           {seperator(item?.payable_amount)}
      //         </span>
      //       </p>
      //     </div>
      //   );
      // },
    },
    {
      title: "Commission",
      name: "commission",
      Cell: (item: any) => {
        return <span>{seperator(item?.commission)}</span>;
      },
    },
    {
      title: "Commission Per",
      name: "commission_per",
      Cell: (item: any) => {
        return <span>{item?.commission_per}%</span>;
      },
    },
  ];

  const { register, handleSubmit, reset, getValues, control, watch } =
    useForm<any>({
      defaultValues: {
        ...initialValues,
      },
    });

  const onGetData = async () => {
    const values = getValues();

    const payload: any = {
      ...filter,
      filter_data: {
        startDate: dateFormatter(values?.date?.[0] || new Date(), "start"),
        endDate: dateFormatter(values?.date?.[1] || new Date(), "end"),
        merchantId: values.client,
      },
    };
    delete payload["isFilter"];
    getDeposit(payload);
  };

  const isExportable = () => {
    const values = getValues();

    return (
      values?.date?.[0] &&
      values?.date?.[1] &&
      values?.client !== "ALL" &&
      values?.client &&
      values?.client === lastFilter?.client &&
      !isLoading
    );
  };
  const prepareCsvProps = () => {
    const filename = "Reseller Deposit.csv";
    const data =
      Object.entries(depoData?.data || {})?.map(([key, item]: any) => {
        let inner = {};
        Object.entries(item?.data || {})?.forEach(([_, subItem]) => {
          Object.entries(subItem || {})?.forEach(([_, subItem2]) => {
            inner = { ...subItem2 };
          });
        });
        return {
          ...item,
          date: key,
          ...inner,
        };
      }) || [];
    const headers =
      depositeColumns?.map((val) => ({
        label: val?.title,
        key: val?.name,
      })) || [];
    headers.unshift({
      label: "Date",
      key: "date",
    });
    return {
      filename,
      data,
      headers,
    };
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    watch((values) => {
      setMaxDate(prepareMaxDate(values?.date?.[0], values?.date?.[1]));
    });
  }, [watch]);

  const onSubmit = async (values: any) => {
    setLastFilter(values);
    setFilter({ ...FILTER, isFilter: true });
  };

  const getClientOptions = () => {
    const options = depoData?.merchantList
      ?.slice()
      ?.sort((a: any, b: any) =>
        a?.merchant_name?.localeCompare(b?.merchant_name)
      )
      ?.map((val: any) => ({
        value: val?.merchant_id,
        label: val?.merchant_details?.merchant_name,
      }));

    return options?.length
      ? [{ value: "ALL", label: "ALL" }, ...options]
      : [{ value: "ALL", label: "ALL" }];
  };

  return (
    <>
      <div className="">
        <form
          className="flex flex-wrap gap-2 items-start justify-end px-1"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Select
            name="client"
            label="Client"
            options={getClientOptions()}
            register={register}
          />
          <ControlledDatePicker
            name="date"
            label="Date"
            placeholder="Date"
            selectsRange
            maxDate={maxDate}
            control={control}
          />
          <Button type="submit" className="mt-[22px] !h-9 !text-sm">
            Apply
          </Button>
          <Button
            variant="Transaparent"
            className="mt-[22px]  !text-sm !h-9 "
            onClick={() => {
              reset();
              setFilter({ ...FILTER });
            }}
          >
            Clear
          </Button>
          <span onClick={() => showToaster("Please select client", "Error")}>
            <Export
              {...prepareCsvProps()}
              className={`btn btn-primary rounded mt-[22px]  !text-sm !h-9 ${
                isExportable() ? "" : "disabled opacity-50"
              }`}
            >
              Download
            </Export>
          </span>
        </form>

        <div className="mt-2">
          <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-2 gap-4 mainbox">
            {summaryData?.map((item: any, index) => {
              return (
                <Fragment key={index}>
                  <Card
                    length={summaryData.length}
                    index={index}
                    value={depoData?.summary?.[item.key]}
                    text={item?.text}
                    icon={item?.imgSrc}
                    rupee={item?.rupee}
                  />
                </Fragment>
              );
            })}
          </div>
          <div className="App mt-3 tableborder bg-chatlook-white">
            <Table
              columns={columns}
              isLoading={isLoading}
              data={Object.entries(depoData?.data || {})}
            >
              {Object.entries(depoData?.data || {})?.map(
                ([key, item]: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      <TableRow columns={columns} item={{ ...item, date: key }}>
                        <Table
                          className="subtable"
                          isExpendable={false}
                          columns={depositeColumns}
                          data={Object.entries(item?.data || {})}
                          count={depoData?.total_item}
                          minHeight={50}
                        >
                          {Object.entries(item?.data || {})?.map(
                            ([subKey, subItem]: any, subIndex: number) => {
                              return (
                                <Fragment key={subIndex}>
                                  {Object.entries(subItem || {})?.map(
                                    (
                                      [subKey2, subItem2]: any,
                                      subIndex2: number
                                    ) => {
                                      return (
                                        <>
                                          <Fragment key={subIndex2}>
                                            <TableRow
                                              columns={depositeColumns}
                                              item={{
                                                ...subItem2,
                                                merchantId: subKey,
                                              }}
                                              isExpendable={false}
                                            ></TableRow>
                                          </Fragment>
                                        </>
                                      );
                                    }
                                  )}
                                </Fragment>
                              );
                            }
                          )}
                        </Table>
                      </TableRow>
                    </Fragment>
                  );
                }
              )}
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deposit;
