import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  ControlledSwitch,
  ControlledTimePicker,
  DropDown,
  Icon,
  Input,
  Loader,
  Select,
} from "../../ui";
import RadioButton from "../../ui/elements/RadioButton";

// PROJECT IMPORT
import {
  vipUserLevelOption,
  criteriaNameOptions,
  frequencyOptions,
  betCriteriaOptions,
  referCriteriaOptions,
  typeOptions,
  bonusTypeOptions,
  paymentMethodOptions,
  campaignSubTypeOptions,
  onlyLimitedfrequencyOptions,
  frequencyNumberDepositOptions
} from "./mock";
import {
  useGetActiveDomainListQuery,
  useAddCashbackMutation,
  useUpdateCashbackMutation,
} from "./cashbackApi";
import { dateFormatter, showToaster } from "../../helperFunctions";
import { useCurrentCashback, useDomainIdData } from "./cashbackSlice";

// THIRD - PARTY IMPORT
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { IoMdCloseCircle } from "react-icons/io";
import { Editor } from "primereact/editor";
import moment from "moment";
import * as yup from "yup";
import dayjs from "dayjs";
import {questionOptions} from "./mock";

const CashbackForm = () => {
  const currentBonus = useCurrentCashback();
  const domainIdData: any = useDomainIdData();
  const navigate = useNavigate();
  const { id } = useParams();

  useGetActiveDomainListQuery(undefined, { refetchOnMountOrArgChange: true });
  const [createCashback] = useAddCashbackMutation();
  const [updateCashback] = useUpdateCashbackMutation();
  const [bonusType, setBonusTypeOption] = useState<string>("");
  const [image, setImage] = useState<any>();
  const [percentage, setPercentage] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [imageData, setImageData] = useState<any>({
    width: 0,
    height: 0,
    size: 0,
  });

  const schema = yup.object({
    maxUsage: yup.number().when("promoFrequency", {
      is: (value: string) => {
        return value === "limited";
      },
      then: (yup) => yup.min(1, "At least [1] promo code usage required"),
      otherwise: (yup) => yup.notRequired().optional().nullable(),
    }),
    startDate: yup.mixed().required("Please enter a start date"),
    endDate: yup.string().required("Please enter a end date"),
    startTime: yup.string().required("Please enter a start time"),
    endTime: yup
      .date()
      .when(
        ["startDate", "endDate", "startTime"],
        ([startDate, endDate, startTime], schema) => {
          const startDateTime = moment(startDate);
          const endDateTime = moment(endDate);
          const startTimeMoment = moment(startTime);

          startDateTime.set({
            hour: startTimeMoment.hours(),
            minute: startTimeMoment.minutes(),
            second: startTimeMoment.seconds(),
          });
          if (
            startDateTime.isSame(endDateTime, "day") &&
            startDateTime.isSameOrAfter(endDateTime)
          ) {
            return schema.min(
              startDateTime.toDate(),
              "End time must be after start time"
            );
          }

          return schema;
        }
      )
      .required("Please enter a end time"),
    image: yup
      .mixed()
      .test(
        "image",
        "File size exceeds the maximum limit (5MB)",
        (value: any) => {
          if (!value) {
            return true;
          }
          return value.size <= 5 * 1024 * 1024;
        }
      ),
  });

  const prepareDefaultValues = () => {
    if (id) {
      const game_contributions = JSON.parse(
        currentBonus?.game_contributions || "{}"
      );
      return {
        campaignName: currentBonus?.name,
        campaignType: currentBonus?.type,
        campaignSubType: currentBonus?.sub_type,
        promoCode: currentBonus?.promo_code,
        bonusType: currentBonus?.bonus_type,
        bonusAmount: currentBonus?.bonus_amount,
        minBetAmount: currentBonus?.min_bet_amount,
        status: currentBonus?.status,
        inPromotion: currentBonus?.in_promotion,
        isOnlyNewPlayer: currentBonus?.is_only_new_player,
        description: currentBonus?.description || "",
        frequencySettings: currentBonus?.frequency_settings,
        bonusCapAmount: currentBonus?.bonus_cap_amount,
        rollOver: currentBonus?.rollover,
        maxUsage: currentBonus?.max_usage,
        promoFrequency: currentBonus?.promo_frequency,
        minOdds: currentBonus?.min_odds,
        maxOdds: currentBonus?.max_odds,
        // useMinOdds: currentBonus?.use_min_odds || "",
        // useMaxOdds: currentBonus?.use_max_odds || "",
        wageringContribution: currentBonus?.wagering_contribution || "",
        rules: currentBonus?.rules || "",
        tC: currentBonus?.t_c || "",
        minDeposit: currentBonus?.min_deposit,
        maxDeposit: currentBonus?.max_deposit,
        criteriaName: currentBonus?.criteria_name,
        criteriaRangeFrom: currentBonus?.criteria_range_from,
        criteriaRangeTo: currentBonus?.criteria_range_to,
        image: imageData?.size,
        liveCasino: game_contributions?.live_casino,
        slot: game_contributions?.slot,
        tableGames: game_contributions?.table_games,
        virtualGames: game_contributions?.virtual_games,
        domainId: currentBonus?.domain_id ? currentBonus?.domain_id : "",
        vipUserLevel: currentBonus?.vip_user_level
          ? currentBonus?.vip_user_level
          : [],
        paymentMethod: currentBonus?.payment_method,
        startDate: new Date(currentBonus?.start_date),
        endDate: new Date(currentBonus?.end_date),
        startTime: dayjs(
          currentBonus?.start_date?.slice(11) || "00:00:00",
          "HH:mm:ss"
        ),
        endTime: dayjs(
          currentBonus?.end_date?.slice(11) || "00:00:00",
          "HH:mm:ss"
        ),
        // expiryDate: new Date(currentBonus?.expiry_date),
        // expiryTime: dayjs(
        //   currentBonus?.expiry_date?.slice(11) || "00:00:00",
        //   "HH:mm:ss"
        // ),
        validityDays: currentBonus?.validity_days,
        // dateType: "expiryDate",
      };
    } else {
      return {
        domainId: "",
        startTime: null,
        endTime: null,
        // expiryTime: null,
        // dateType: "expiryDate",
        maxUsage: 1,
        vipUserLevel: [],
        bonusType: "flat",
        inPromotion: 1,
        validityDays: 0,
      };
    }
  };

  const handleInputChange = (e: any) => {
    const inputValue: any = e.target.value;
    if (
      inputValue === "" ||
      inputValue === "%" ||
      (percentage.length > inputValue.length && inputValue.length === 1)
    ) {
      setPercentage("");
      setValue("bonusAmount", null);
    } else if (!inputValue.includes("%") && inputValue.length > 1) {
      const newValue: any = `${inputValue.slice(0, -1)}%`;
      setPercentage(newValue);
      setValue("bonusAmount", +inputValue.slice(0, -1));
    } else {
      const sanitizedValue: any = inputValue.replace("%", "");
      if ((+sanitizedValue || 0) >= 0) {
        const newValue =
          sanitizedValue + (sanitizedValue.includes("%") ? "" : "%");
        setPercentage(newValue);
        setValue("bonusAmount", +newValue.slice(0, -1));
      }
    }
  };

  const isDisabled: any = !!(
    new Date(currentBonus?.start_date) < new Date() && id
  );
  // const isValidityExpired = !!(
  //   new Date(currentBonus?.expiry_date) < new Date() && id
  // );
  const isCampaignExpired = !!(
    new Date(currentBonus?.end_date) < new Date() && id
  );

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    control,
    getValues,
    clearErrors,
    watch,
  }: any = useForm<any>({
    resolver: yupResolver<any>(schema),
    defaultValues: {
      ...prepareDefaultValues(),
    },
  });

  const closeImage = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    clearErrors("image", "");
    setValue("image", "");
    setImageData({ width: 0, height: 0, size: 0 });
    setImage("");
  };

  const onSubmit = async (values: any) => {
    // if (imageData?.width !== 530 || imageData?.height !== 237) {
    //   setError("image", {
    //     message: "File resolution should be width 530px * height 237px",
    //   });
    //   return;
    // }
    // if (!image) {
    //   setError("image", {
    //     message: "Enter Image",
    //   });
    //   return;
    // } else if (imageData?.width !== 530 || imageData?.height !== 237) {
    //   setError("image", {
    //     message: "File resolution should be width 381px * height 473px",
    //   });
    // }
    let formData: any = new FormData();
    formData.append("category", "cashback");
    formData.append("campaignName", values?.campaignName || "");
    formData.append("campaignType", values?.campaignType || "");
    formData.append("campaignSubType", values?.campaignSubType || "");
    formData.append("promoCode", values?.promoCode || "");
    formData.append("bonusType", bonusType || "");
    formData.append("bonusAmount", values?.bonusAmount || 0);
    formData.append("minBetAmount", values?.minBetAmount || 0);
    formData.append("status", 1);
    formData.append("inPromotion", values?.inPromotion || 0);
    formData.append("isOnlyNewPlayer", values?.isOnlyNewPlayer || 0);
    formData.append("description", values?.description || "");
    formData.append(
      "frequencySettings",
      +values?.frequencySettings > 0 ? +values?.frequencySettings : 0
    );
    formData.append("bonusCapAmount", values?.bonusCapAmount || 0);
    formData.append("rollOver", values?.rollOver || 0);
    formData.append("maxUsage", values?.maxUsage);
    formData.append("promoFrequency", values?.promoFrequency);
    formData.append("minOdds", values?.minOdds || 0);
    formData.append("maxOdds", values?.maxOdds || 0);
    // formData.append("useMinOdds", values?.useMinOdds || 0);
    // formData.append("useMaxOdds", values?.useMaxOdds || 0);
    formData.append("wageringContribution", values?.wageringContribution || 0);
    formData.append("rules", values?.rules || "");
    formData.append("tC", values?.tC || "");
    formData.append("minDeposit", values?.minDeposit || 0);
    formData.append("maxDeposit", values?.maxDeposit || 0);
    formData.append("criteriaName", values?.criteriaName || "");
    formData.append("criteriaRangeFrom", values?.criteriaRangeFrom || 0);
    formData.append("criteriaRangeTo", values?.criteriaRangeTo || 0);
    formData.append("image", values?.image || "");
    formData.append("liveCasino", values?.liveCasino || 0);
    formData.append("slot", values?.slot || 0);
    formData.append("tableGames", values?.tableGames || 0);
    formData.append("virtualGames", values?.virtualGames || 0);
    formData.append("paymentMethod", values?.paymentMethod || "");
    formData.append("domainId", values?.domainId || "");
    formData.append("imageHeight", imageData?.height || 0);
    formData.append("imageWidth", imageData?.width || 0);
    formData.append("imageSize", imageData?.size || 0);
    // formData.append("validityDays", values?.validityDays || 0);
    formData.append("validityDays", 0);
    formData.append("vipUserLevel", values?.vipUserLevel || "");

    // if (values?.expiryDate && values?.expiryTime) {
    //   const formateExpiryDate: any = values?.expiryDate
    //     ? dateFormatter(values?.expiryDate, "Default")
    //     : "";
    //   formData.append(
    //     "expiryDate",
    //     `${formateExpiryDate} ${
    //       dayjs(values?.expiryTime)?.format("HH:mm:ss") || "00:00:00"
    //     }`
    //   );
    // }

    const formateStartDate: any = values?.startDate
      ? dateFormatter(values?.startDate, "Default")
      : "";
    formData.append(
      "startDate",
      `${formateStartDate} ${
        moment(values?.startTime)?.format("HH:mm:ss") || "00:00:00"
      }`
    );

    const formateEndDate: any = values?.endDate
      ? dateFormatter(values?.endDate, "Default")
      : "";
    formData.append(
      "endDate",
      `${formateEndDate} ${
        moment(values?.endTime)?.format("HH:mm:ss") || "00:00:00"
      }`
    );
    formData.append(
      "vipUserLevel",
      values?.vipUserLevel?.length ? values?.vipUserLevel : ""
    );
    setLoading(true);
    if (id) {
      try {
        formData.append("id", currentBonus?.id);
        const res: any = await updateCashback(formData).unwrap();
        showToaster(res?.message);
        setLoading(false);
        navigate(-1);
      } catch (err) {
        setLoading(false);
      }
    } else {
      try {
        const res: any = await createCashback(formData).unwrap();
        showToaster(res?.message);
        setLoading(false);
        navigate(-1);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const BonusTypeChange = (value: string) => {
    setBonusTypeOption(value);
    setValue("bonusType", value);
  };

  useEffect(() => {
    if (id) {
      setImage(currentBonus?.image_url);
      setBonusTypeOption(currentBonus?.bonus_type);
      setPercentage(
        currentBonus?.bonus_amount ? `${currentBonus?.bonus_amount}%` : ""
      );
    } else {
      setBonusTypeOption("flat");
    }
    if (currentBonus?.image_detail && id) {
      const clonedImageData = JSON.parse(currentBonus?.image_detail);
      setImageData({
        ...imageData,
        height: clonedImageData?.image_height,
        width: clonedImageData?.image_width,
        size: clonedImageData?.image_size,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBonus, id]);

  const getDomainOptions = () => {
    const options = domainIdData?.data?.map((val: any) => {
      const clientDataOption = {
        value: val?.id,
        label: val?.domain_name,
      };
      return clientDataOption;
    });
    return options;
  };

  const resetData = (action: any, type: any) => {
    if (action === "custom_type") {
      setValue("campaignSubType", null);
      setValue("minDeposit", null);
      setValue("maxDeposit", null);
      setValue("minBetAmount", 0);
      setValue("frequencySettings", null);
      setValue("rollOver", 0);
      setValue("maxUsage", 1);
      setValue("promoFrequency", "limited");
      setValue("minOdds", null);
      setValue("maxOdds", null);
      setValue("criteriaName", null);
      setValue("criteriaRangeFrom", null);
      setValue("criteriaRangeTo", null);
      setBonusTypeOption("");
      setPercentage("");
      setValue("bonusType", "");
      setValue("bonusAmount", 0);
      setValue("bonusCapAmount", 0);
      setValue("validityDays", 0);
      setValue("paymentMethod", null);
    } else if (action === 'reset_frequency') {
      setValue("promoFrequency", "limited");
      setValue("maxUsage", 1);
    }
    if (type === 'criteria_deposit') {
      setValue("criteriaRangeFrom", null);
      setValue("criteriaRangeTo", null);
    } else if (type === 'criteria_sports_casino') {
      setValue("minBetAmount", null);
      setValue("rollOver", null);
      setValue("minOdds", null);
      setValue("maxOdds", null);
    } else if (type === "refer") {
      setValue("criteriaName", "instant");
    } else if (type === "signup") {
      setValue("isOnlyNewPlayer", 1);
    }
    if (action === 'reset_subtype') {
      setValue("minDeposit", null);
      setValue("maxDeposit", null);
      setValue("criteriaName", null);
      setValue("criteriaRangeFrom", null);
      setValue("criteriaRangeTo", null);
      if (type === 'number') {
        setValue('promoFrequency', 'once');
        setValue('maxUsage', -2);
      } else {
        setValue('promoFrequency', 'limited');
        setValue('maxUsage', 1);
      }
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col space-y-5"
      >
        <div className="cardcss">
          <div className="flex justify-between">
            <div className="text-lg font-semibold text-[#037756]">
              <h4>Cashback Details</h4>
            </div>
            <ControlledSwitch
              name="inPromotion"
              label="In Promotion Page? :"
              control={control}
            />
          </div>
          <div className="grid grid-cols-3 items-start gap-3 h-full max-h-full pt-2">
            <Select
              className="!w-full"
              name="campaignType"
              label="Cashback Type"
              placeholder="Please Select"
              options={typeOptions}
              register={register}
              disabled={isDisabled}
              onChange={(e) => {
                const value = e.target.value;
                setValue("campaignType", value);
                resetData("custom_type", value);
              }}
            />
            <Input
              placeholder="Enter Cashback Name"
              className="!w-full"
              name="campaignName"
              label="Cashback Name"
              register={register}
              disabled={isDisabled}
            />

            <Input
              placeholder="Enter Promo Code"
              className="!w-full"
              name="promoCode"
              label="Promo Code"
              disabled={isDisabled}
              register={register}
            />
            <DropDown
              name="domainId"
              label="Domain"
              options={getDomainOptions()}
              isDisabled={isDisabled}
              value={watch("domainId")}
              register={register}
              onChange={(e: any, i: any) => {
                setValue("domainId", e);
              }}
            />
            <div>
              <DropDown
                name="vipUserLevel"
                label="VIP Levels"
                options={vipUserLevelOption}
                isMulti
                value={watch("vipUserLevel")}
                register={register}
                isDisabled={isDisabled}
                onChange={(e: any, i: any) => {
                  setValue(
                    "vipUserLevel",
                    e?.map((item: any, index: any) => item)
                  );
                }}
              />
              <label className="font-semibold text-red-500 text-sm">
                (Note: If Empty Then Consider As All Levels.)
              </label>
            </div>
            <Select
                className="!w-full"
                label="Is Only For New Player?"
                name="isOnlyNewPlayer"
                disabled={isDisabled}
                options={questionOptions}
                register={register}
            />
            {watch("campaignType") === "deposit" ? (
              <Select
                className="!w-full"
                placeholder="Please Select"
                name="campaignSubType"
                label="Cashback Sub Type"
                options={campaignSubTypeOptions}
                register={register}
                disabled={isDisabled}
                onChange={(e) => {
                  const value = e.target.value;
                  setValue("campaignSubType", value);
                  resetData("reset_subtype", value);
                }}
              />
            ) : null}
            {watch("campaignSubType") === "number" ? (
              <Input
                type="number"
                className="!w-full"
                placeholder="Enter Deposite Number"
                name="frequencySettings"
                label="Deposite Number"
                register={register}
                disabled={isDisabled}
              />
            ) : null}
            {watch("campaignType") === "refer" ? (
              <Select
                className="!w-full"
                // placeholder="Please Select"
                name="criteriaName"
                label="Criteria Name"
                disabled={isDisabled}
                options={referCriteriaOptions}
                register={register}
                onChange={(e) => {
                  const value = e.target.value;
                  setValue("criteriaName", value);
                  resetData("reset_frequency", null);
                }}
              />
            ) : null}
            {watch("campaignType") === "paymentmethod" ? (
              <Select
                className="!w-full"
                placeholder="Please Select"
                name="paymentMethod"
                label="Payment Method"
                options={paymentMethodOptions}
                disabled={isDisabled}
                register={register}
                onChange={(e) => {
                  const value = e.target.value;
                  setValue("paymentMethod", value);
                  resetData("reset_frequency", null);
                }}
              />
            ) : null}
            {watch("campaignType") === "sports" ||
            watch("campaignType") === "casino" ? (
                <>
                  <Select
                      className="!w-full"
                      placeholder="Please Select"
                      name="criteriaName"
                      label="Criteria Name"
                      disabled={isDisabled}
                      options={betCriteriaOptions}
                      register={register}
                      onChange={(e) => {
                        const value = e.target.value;
                        setValue("criteriaName", value);
                        resetData('reset_frequency', 'criteria_sports_casino');
                      }}
                  />
                  {watch("criteriaName") ? (
                      <>
                        <Input
                            type="number"
                            placeholder="Enter Min Bet Amount"
                            className="!w-full"
                            name="minBetAmount"
                            label="Min Bet Amount"
                            register={register}
                            errors={errors}
                            disabled={isDisabled}
                        />
                        <Input
                            type="number"
                            placeholder="Enter Roll Over"
                            className="!w-full"
                            name="rollOver"
                            label="Roll Over"
                            register={register}
                            errors={errors}
                            disabled={isDisabled}
                        />
                        {watch("campaignType") === "sports" ? (
                            <div className="flex gap-2 items-end">
                              <Input
                                  type="number"
                                  placeholder="Enter Min Odds"
                                  className="!w-full"
                                  name="minOdds"
                                  label="Min Odds"
                                  register={register}
                                  disabled={isDisabled}
                              />
                              <Input
                                  type="number"
                                  placeholder="Enter Max Odds"
                                  className="!w-full"
                                  name="maxOdds"
                                  label="Max Odds"
                                  register={register}
                                  disabled={isDisabled}
                              />
                            </div>
                        ) : null}
                      </>
                  ) : null}
                </>
            ) : null}
            {watch("campaignType") === "deposit" && watch("campaignSubType") ? (
                <div className="flex gap-2 items-end">
                  <Input
                      placeholder="Enter Min Deposit"
                      type="number"
                      className="!w-full"
                      name="minDeposit"
                      label="Min Deposit"
                      register={register}
                      disabled={isDisabled}
                  />
                  <Input
                      placeholder="Enter Max Deposit"
                      type="number"
                      className="!w-full"
                      name="maxDeposit"
                      label="Max Deposit"
                      register={register}
                      disabled={isDisabled}
                  />
                </div>
            ) : null}
            {watch("campaignType") === "deposit" && watch("campaignSubType") ? (
                <Select
                    className="!w-full"
                    placeholder="Please Select"
                    name="criteriaName"
                    label="Criteria Name"
                    disabled={isDisabled}
                    options={criteriaNameOptions}
                    register={register}
                    onChange={(e) => {
                      const value = e.target.value;
                      setValue("criteriaName", value);
                      resetData('reset_frequency', 'criteria_deposit');
                    }}
                />
            ) : null}
            {watch("campaignType") === "deposit" && watch("criteriaName") ? (
                <div>
                  <label className="text-sm text-ev-secondary pb-[4px]">
                    Criteria Range
                  </label>
                  <div className="flex gap-2 items-center">
                    <Input
                        type="number"
                        className="!w-full"
                        placeholder="Enter Criteria Range"
                        name="criteriaRangeFrom"
                        register={register}
                        disabled={isDisabled}
                    />
                    <span className="text-sm text-ev-secondary">To</span>
                    <Input
                        type="number"
                        className="!w-full"
                        placeholder="Enter Criteria Range To"
                        name="criteriaRangeTo"
                        register={register}
                        disabled={isDisabled}
                    />
                  </div>
                </div>
            ) : null}
            {!watch("campaignType") ||
            (watch("campaignType") &&
              !watch("criteriaName") &&
              !watch("campaignSubType") &&
              !watch("paymentMethod")) ? (
              <Select
                className="!w-full"
                label="Cashback Frequency"
                name="promoFrequency"
                disabled={isDisabled}
                options={onlyLimitedfrequencyOptions}
                register={register}
              />
            ) : (
                <>
                  {watch('campaignSubType') === 'number' ? <Select
                      className="!w-full"
                      label="Cashback Frequency"
                      name="promoFrequency"
                      disabled={isDisabled}
                      options={frequencyNumberDepositOptions}
                      register={register}
                  /> : <Select
                      className="!w-full"
                      label="Cashback Frequency"
                      name="promoFrequency"
                      disabled={isDisabled}
                      options={frequencyOptions}
                      register={register}
                  />}
                </>
            )}
            {watch("promoFrequency") === "limited" ? (
              <Input
                type="number"
                placeholder="Enter Promo Code Usage"
                className="!w-full"
                name="maxUsage"
                label="Promo Code Usage"
                disabled={isDisabled}
                register={register}
                errors={errors}
              />
            ) : null}
          </div>
        </div>
        <div className="cardcss">
          <div className="flex justify-between">
            <div className="text-lg font-semibold text-[#037756]">
              <h4>Cashback Insights</h4>
            </div>
            <div className="flex items-center h-full space-x-4">
              <label className={`text-sm text-ev-secondary pb-[4px]`}>
                Cashback Category :
              </label>
              {bonusTypeOptions.map((option) => {
                if (
                  (watch("campaignType") === "signup" ||
                    watch("campaignType") === "sports" ||
                    watch("campaignType") === "casino" ||
                    (watch("campaignType") === "refer" &&
                      watch("criteriaName") === "instant")) &&
                  option?.value === "percentage"
                ) {
                  return <Fragment key={option.value}></Fragment>;
                }
                return (
                  <RadioButton
                    key={option.value}
                    label={option.label}
                    value={option.value}
                    disabled={isDisabled}
                    checked={bonusType === option.value}
                    onChange={() => {
                      const bonusAmount = getValues()?.bonusAmount;
                      if (option.value === "percentage") {
                        if ((+bonusAmount || 0) >= 0) {
                          BonusTypeChange(option.value);
                        }
                      } else {
                        BonusTypeChange(option.value);
                      }
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div className="grid grid-cols-3 items-end gap-3 pt-2">
            <Input
              placeholder="Enter Cashback Amount"
              className="!w-full"
              type={watch("bonusType") === "percentage" ? "text" : "number"}
              name="bonusAmount"
              label="Cashback Amount"
              value={
                watch("bonusType") === "percentage"
                  ? percentage
                  : watch("bonusAmount") || ""
              }
              disabled={isDisabled}
              onChange={(e) => {
                const bonusType = getValues()?.bonusType;
                if (bonusType === "percentage") {
                  handleInputChange(e);
                } else {
                  setValue("bonusAmount", e.target.value);
                }
              }}
            />
            {watch("bonusType") === "percentage" ? (
              <Input
                placeholder="Enter Cashback Maximum Amount"
                type="number"
                className="!w-full"
                name="bonusCapAmount"
                label="Cashback Maximum Amount"
                register={register}
                disabled={isDisabled}
              />
            ) : null}
            {/*<Select*/}
            {/*  className="!w-full"*/}
            {/*  name="dateType"*/}
            {/*  label="Type"*/}
            {/*  options={dateTypeOptions}*/}
            {/*  register={register}*/}
            {/*  disabled={isValidityExpired}*/}
            {/*  onChange={(e) => {*/}
            {/*    setValue("dateType", e.target.value);*/}
            {/*    setValue("validityDays", null);*/}
            {/*    setValue("expiryDate", null);*/}
            {/*    setValue("expiryTime", null);*/}
            {/*  }}*/}
            {/*/>*/}
            {/*{watch("dateType") === "validityDays" ? (*/}
            {/*  <Input*/}
            {/*    className="!w-full"*/}
            {/*    placeholder="Enter Validity Days"*/}
            {/*    name="validityDays"*/}
            {/*    label="Validity Days"*/}
            {/*    type="number"*/}
            {/*    register={register}*/}
            {/*    disabled={isValidityExpired}*/}
            {/*  />*/}
            {/*) : (*/}
            {/*  <div>*/}
            {/*    <label className={`text-sm text-ev-secondary pb-[4px]`}>*/}
            {/*      Cashback Expiry Date / Time*/}
            {/*    </label>*/}
            {/*    <div className="flex">*/}
            {/*      <div className="w-1/2">*/}
            {/*        <ControlledDatePicker*/}
            {/*          inputClassName="rounded-e-[0px]"*/}
            {/*          name="expiryDate"*/}
            {/*          placeholder="Expiry Date"*/}
            {/*          control={control}*/}
            {/*          minDate={new Date()}*/}
            {/*          errors={errors}*/}
            {/*          disabled={isValidityExpired}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*      <div className="w-1/2">*/}
            {/*        <ControlledTimePicker*/}
            {/*          inputClassName="bonus-time"*/}
            {/*          name="expiryTime"*/}
            {/*          placeholder="Expiry Time"*/}
            {/*          control={control}*/}
            {/*          errors={errors}*/}
            {/*          disabled={isValidityExpired}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}
            {/*<Input*/}
            {/*    className="!w-full"*/}
            {/*    placeholder="Enter Validity Days"*/}
            {/*    name="validityDays"*/}
            {/*    label="Validity Days"*/}
            {/*    type="number"*/}
            {/*    register={register}*/}
            {/*    disabled={isDisabled}*/}
            {/*/>*/}
          </div>
        </div>
        <div className="cardcss">
          <div className="text-lg font-semibold text-[#037756]">
            <h4>Other Details</h4>
          </div>
          <div className="grid grid-cols-2 items-start gap-3 pt-2F">
            <div className="textareacss">
              <label className={`text-sm text-ev-secondary pb-[4px]`}>
                Description
              </label>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <textarea
                    className="p-2 w-full bg-chatlook-secondary placeholder-ev-white text-xs text-ev-white h-10 rounded outline-none border-[1px] border-[#222A40] focus:border-chatlook-secondary"
                    placeholder="Enter Description"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="proof-sec">
              <label className={`text-sm text-ev-secondary pb-[4px]`}>
                Upload Banner Image ( Max image size 5MB ){" "}
                {`${imageData?.width} x ${imageData?.height}`}
              </label>
              <div className="file-input">
                {/* <Controller
                    name="image"
                    control={control}
                    render={({ field }) => ( */}
                <>
                  <input
                    type="file"
                    id="file-input"
                    accept="image/*"
                    className="file-input__input"
                    onChange={(e: any) => {
                      try {
                        const file: any = e.target.files?.[0];
                        const maxSize = 5 * 1024 * 1024;

                        if (file && file?.size < maxSize) {
                          const reader = new FileReader();
                          reader.onload = (e: any) => {
                            const image: any = new Image();
                            image.src = e.target.result;
                            image.onload = () => {
                              setImageData({
                                ...imageData,
                                size: file.size,
                                width: image.width,
                                height: image.height,
                              });
                              setImage(URL.createObjectURL(file));

                              setValue("image", file);
                            };
                          };
                          reader.readAsDataURL(file);
                        }
                        setImageData({ ...imageData, size: file?.size });
                      } catch (err) {
                        console.error("======", err);
                      }
                    }}
                  />
                  <label
                    className="file-input__label"
                    htmlFor="file-input"
                    style={{
                      backgroundImage: `url(${image})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={(e) => {
                      if (image) {
                        e.stopPropagation();
                        e.preventDefault();
                      }
                    }}
                  >
                    <span className="font-semibold pt-2 text-[#037756] text-xs">
                      {watch("image")?.name
                        ? `${watch("image")?.name}`
                        : "Click to Upload Banner Image"}{" "}
                      {image ? null : (
                        <Icon name="UploadIcon" className="w-10 h-10 m-auto" />
                      )}
                    </span>
                    {image ? (
                      <>
                        {/* <img
                                src={image}
                                alt="Selected file"
                                className="w-100 m-auto py-2 h-40"
                              /> */}
                        <IoMdCloseCircle
                          size={25}
                          className="absolute right-1 top-1"
                          style={{ color: "#037756" }}
                          onClick={(e) => closeImage(e)}
                        />
                      </>
                    ) : null}
                  </label>
                </>
                {/* )}
                  > */}
              </div>
              {errors?.image ? (
                <span className="text-red-500 text-sm">
                  {errors?.image?.message}
                </span>
              ) : null}
            </div>
          </div>
          <div className="grid grid-cols-2 items-start gap-3 pb-3">
            <div className="ckeditor-container">
              <label className={`text-sm text-ev-secondary pb-[4px]`}>
                Rules
              </label>
              <Editor
                style={{ height: 100 }}
                placeholder="Enter rules"
                value={watch("rules")}
                onTextChange={(e) => setValue("rules", e.htmlValue)}
              />
            </div>
            <div className="ckeditor-container">
              <label className={`text-sm text-ev-secondary pb-[4px]`}>
                Terms & Conditions
              </label>
              <Editor
                style={{ height: 100 }}
                placeholder="Enter Terms & Conditions"
                value={watch("tC")}
                onTextChange={(e) => setValue("tC", e.htmlValue)}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-3 pt-2">
            <div className="ckeditor-container">
              <label className={`text-sm text-ev-secondary pb-[4px]`}>
                Start Date / Time
              </label>
              <div className="flex">
                <div className="w-1/2">
                  <ControlledDatePicker
                    name="startDate"
                    inputClassName="rounded-e-[0px]"
                    placeholder="Start Date"
                    selectesStart
                    disabled={isDisabled}
                    startDate={watch("startDate")}
                    endDate={watch("endDate")}
                    maxDate={watch("endDate")}
                    control={control}
                    errors={errors}
                    minDate={new Date()}
                  />
                </div>

                <div className="w-1/2">
                  <ControlledTimePicker
                    inputClassName="bonus-time border-l-0"
                    name="startTime"
                    placeholder="Select Start Time"
                    disabled={isDisabled}
                    control={control}
                    errors={errors}
                  />
                </div>
              </div>
            </div>
            <div className="ckeditor-container">
              <label className={`text-sm text-ev-secondary pb-[4px]`}>
                End Date / Time
              </label>
              <div className="flex">
                <div className="w-1/2">
                  <ControlledDatePicker
                    inputClassName="rounded-e-[0px]"
                    name="endDate"
                    placeholder="End Date"
                    selectsEnd
                    startDate={watch("startDate")}
                    endDate={watch("endDate")}
                    minDate={watch("startDate") || new Date()}
                    control={control}
                    errors={errors}
                    disabled={isCampaignExpired}
                  />
                </div>

                <div className="w-1/2">
                  <ControlledTimePicker
                    inputClassName="bonus-time border-l-0"
                    name="endTime"
                    // label="End Time"
                    placeholder="Select End Time"
                    minTime={
                      watch("startDate")?.toISOString() ===
                      watch("endDate")?.toISOString()
                        ? watch("startTime")
                        : undefined
                    }
                    control={control}
                    errors={errors}
                    disabled={isCampaignExpired}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-2">
            <Button type="submit" className="mt-[10px] !h-9 !text-sm">
              {loading ? (
                <Loader loaderClassName="create-edit-bonus" />
              ) : id ? (
                "Update"
              ) : (
                "Add"
              )}
            </Button>
            <Button
              variant="Transaparent"
              className="mt-[10px] !text-sm !h-9"
              onClick={() => {
                reset();
                setImage(id ? currentBonus?.image_url : null);
                setImageData({
                  width: 0,
                  height: 0,
                  size: 0,
                });
                setBonusTypeOption("");
              }}
            >
              Clear
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CashbackForm;
