import { Fragment } from "react";

const intialOptions = [
  { value: 1, label: "Enabled", activeClassName: "green" },
  { value: 0, label: "Disabled", activeClassName: "red" },
];
const ToggleSwitch = (props: any) => {
  const {
    options = intialOptions,
    value = 0,
    disabled,
    onChange,
    reverse = false,
    className,
    isLoading = false,
    ...rest
  } = props;

  const condition = reverse ? 1 : 0;
  return (
    <>
      <div className="d-flex align-items-center switchmain">
        <div
          className={`toggle-switch ${className}`}
          style={{
            opacity: disabled ? 0.7 : 1,
            cursor: disabled ? "" : "pointer",
          }}
          onClick={(e) => {
            const index = options?.findIndex(
              (item: any) => item?.value === value
            );
            if (!disabled) {
              onChange?.(
                index > -1 && index !== options?.length - 1
                  ? options?.[index + 1]?.value
                  : options?.[0]?.value,
                e
              );
            }
          }}
          {...rest}
        >
          {isLoading ? (
            <div className="d-flex align-items-center  justify-content-center">
              ...Loading
            </div>
          ) : (
            options?.map((option: any, i: number) => {
              const { activeClassName, className = "" } = option;
              return (
                <Fragment key={i}>
                  <div className="d-flex" key={i}>
                    <div
                      className={`option ${
                        option?.value === value ? activeClassName : className
                      }`}
                      style={
                        i === condition
                          ? {}
                          : {
                              position: "absolute",
                              right: 4,
                            }
                      }
                    >
                      {/* {i !== condition ? <RxCross2 /> : <FaCheck />} */}
                    </div>
                    <span
                      className={`${
                        i === condition ? "rightposi" : "leftposi"
                      }`}
                    >
                      {option?.value === value ? `${option?.label}` : ""}
                    </span>
                  </div>
                </Fragment>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default ToggleSwitch;
