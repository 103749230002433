export const staticSummaryData = [
  // {
  //   img: "TotalBonusAmt",
  //   key: "totalBonusAmount",
  //   text: "Total Bonus Amount",
  //   borderBg:"purpleborder",
  // },
  // {
  //   img: "TotalActiveBonusAmt",
  //   key: "totalActiveBonusAmount",
  //   text: "Total Active Bonus Amont",
  //   borderBg:"orangeborder",
  // },
  // {
  //   img: "ExpireBonusAmt",
  //   key: "totalExpireBonusAmount",
  //   text: "Total Expire Bonus Amont",
  //   borderBg:"greenborder",
  // },
  // {
  //   img: "TotoalUser",
  //   key: "totalUser",
  //   text: "Total Users",
  //   borderBg:"yellowborder",
  //   rupee: false,
  // },
];

export const transactionSummaryData = [
  {
    img: "SucessIcon",
    key: "totalAmt",
    text: "Success",
  },
  {
    img: "PendingIcon",
    key: "totalAmt",
    text: "Pending",
  },
  {
    img: "IntializeIcon",
    key: "totalAmt",
    text: "Initialized",
  },
  {
    img: "ProcessingIcon",
    key: "totalAmt",
    text: "Processing",
  },
];

export const tableData: any[] = [
  {
    id: 1,
    bank_details: {
      account_holder_name: "John Doe",
      ifsc_code: "ABCD1234",
      upi_id: "john.doe@upi",
    },
    account_number: "1234567890",
    mmm: "kmk",
  },

  {
    id: 2,
    bank_details: {
      account_holder_name: "Jane Smith",
      ifsc_code: "EFGH5678",
      upi_id: "jane.smith@upi",
    },
    account_number: "9876543210",
  },
];

export const staticBonusCampaignData = [
  {
    img: "TotalBonusAmt",
    key: "totalCampaign",
    text: "Total Campaign",
    borderBg: "purpleborder",
    rupee: false,
  },
  {
    img: "TotalBonusAmt",
    key: "totalBonus",
    text: "Total Bonus",
    borderBg: "orangeborder",
    rupee: false,
  },
  {
    img: "TotalBonusAmt",
    key: "totalCashback",
    text: "Total Cashback",
    borderBg: "yellowborder",
    rupee: false,
  },
  {
    img: "TotalBonusAmt",
    key: "totalCustom",
    text: "Total Custom",
    borderBg: "purpleborder",
    rupee: false,
  },
  {
    img: "TotalBonusAmt",
    key: "totalPromotion",
    text: "Total Promotion",
    borderBg: "orangeborder",
    rupee: false,
  },
  {
    img: "TotoalUser",
    key: "totalUser",
    text: "Total Users",
    borderBg:"yellowborder",
    rupee: false,
  },
];
