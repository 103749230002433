import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  Button,
  ControlledDropDown,
  ControlledSwitch,
  Input,
  Loader,
} from "../../ui";
import { showToaster } from "../../helperFunctions";
import { useAppDispatch } from "../../utills/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { setCurrentAdmin, useCurrentAdmin, useRoles } from "./adminSlice";
import { useAddAdminMutation, useUpdateAdminMutation } from "./adminApi";

const AdminForm = () => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  const roles = useRoles();
  const dispatch = useAppDispatch();

  const currentAdmin = useCurrentAdmin();
  const [addAdmin, { isLoading: isAddLoading }] = useAddAdminMutation();
  const [updateAdmin, { isLoading: isUpdateLoading }] =
    useUpdateAdminMutation();

  const schema = yup.object({
    name: yup.string().required("Please enter a name"),
    username: yup.string().required("Please enter user name"),
    email: yup.string().required("Please enter email"),
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  }: any = useForm<any>({
    resolver: yupResolver<any>(schema),
    defaultValues: {
      ...(id && currentAdmin
        ? {
            ...currentAdmin,
            roles: currentAdmin?.roles?.map((item: any) => item?.name),
          }
        : {is_active:0}),
    },
  });

  const prepareRolesOptions = () => {
    const options =
      roles?.map((item) => ({
        value: item,
        label: item,
      })) || [];
    return [
      { value: "All", label: "All" },
      ...(options?.length ? options : []),
    ];
  };

  const onSubmit = async (values: any) => {
    try {
      let res: any;
      if (id) {
        res = await updateAdmin({ ...values, id: id }).unwrap();
      } else {
        res = await addAdmin(values).unwrap();
      }
      showToaster(res?.message);
      onBack();
    } catch (err) {}
  };

  const onBack = () => {
    dispatch(setCurrentAdmin(null));
    navigate(-1);
  };

  useEffect(() => {
    if (id) {
      if (+currentAdmin?.id !== +id) {
        showToaster("Something Went Wrong!", "Error");
        onBack();
      }
    } else {
      dispatch(setCurrentAdmin(null));
    }

    return () => {
      dispatch(setCurrentAdmin(null));
    };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col space-y-5"
        >
          <div className="cardcss">
            <div className="grid grid-cols-3 items-end gap-3 pt-2 ">
              <Input
                className="w-full"
                name="name"
                label="Name"
                placeholder="Enter a name"
                register={register}
                errors={errors}
              />
              <Input
                className="w-full"
                name="username"
                label="User Name"
                placeholder="Enter user name"
                register={register}
                errors={errors}
              />
              <Input
                className="w-full"
                type="email"
                name="email"
                label="Email"
                placeholder="Enter a email"
                register={register}
                errors={errors}
              />

              <ControlledDropDown
                name="roles"
                label="Roles"
                isMulti
                isClearable={true}
                options={prepareRolesOptions()}
                control={control}
                errors={errors}
                onChange={(e: any) => {
                  if (e?.includes("All")) {
                    setValue("roles", roles);
                  } else {
                    setValue("roles", e);
                  }
                }}
              />
              <ControlledSwitch
                name="is_active"
                label="Active"
                control={control}
              />
            </div>
          </div>
          <div className="flex items-center justify-end gap-2">
            <Button type="submit" className="mt-[10px] !h-9 !text-sm">
              {isAddLoading || isUpdateLoading ? (
                <Loader loaderClassName="create-edit-bonus" />
              ) : id ? (
                "Update"
              ) : (
                "Add"
              )}
            </Button>
            <Button
              variant="Transaparent"
              className="mt-[10px]  !text-sm !h-9"
              onClick={() => {
                reset();
              }}
            >
              Clear
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AdminForm;
