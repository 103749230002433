// THIRD - PARTY IMPORT
import { Controller } from "react-hook-form";
import Input, { InputProps } from "./Input";

interface ControlledInputProps extends InputProps {
  control?: any;
}

const ControlledInput = (props: ControlledInputProps) => {
  const { control, name, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, ...restt } }) => {
        return <Input {...restt} {...rest} />;
      }}
    />
  );
};

export default ControlledInput;
