// THIRD - PARTY IMPORT
import { Controller } from "react-hook-form";
import Switch, { SwitchProps } from "./Switch";

interface ControlledSwitchProps extends SwitchProps {
  control: any;
}

const ControlledSwitch = (props: ControlledSwitchProps) => {
  const { control, name = "", ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, value, onChange, ...restt } }) => {
        return (
          <Switch
            {...restt}
            checked={!!value}
            onChange={(value) => {
              onChange?.(value ? 1 : 0);
            }}
            {...rest}
          />
        );
      }}
    />
  );
};

export default ControlledSwitch;
