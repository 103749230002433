import { Icon, IconKeys } from "../icons";

export interface InputProps {
  className?: string;
  inputClassName?: string;
  lableClassName?: string;
  name: string;
  label?: string;
  value?: any;
  onChange?: (e?: any) => void;
  righticonClick?: (e?: any) => void;
  type?: any;
  icon?: IconKeys;
  rightIcon?: IconKeys | null;
  placeholder?: string;
  errors?: any;
  register?: any;
  disabled?: any;
  min?: number;
  max?: number;
  autoComplete?:any;
  style?:any;
}

const Input = (props: InputProps) => {
  const {
    className = "",
    inputClassName = "",
    label,
    icon,
    rightIcon,
    righticonClick,
    errors,
    register,
    name,
    min,
    max,
    type,
    disabled,
    lableClassName = "",
    style,
    ...rest
  } = props;

  const getRegister = (register: any) =>
    register && name ? { ...register?.(name) } : {};

  const message = errors?.[name]?.message || "";
  return (
    <div className={`${className} w-fit flex flex-col justify-center relative`}>
      {label ? (
        <label
        className={`${lableClassName} text-sm text-ev-secondary pb-[4px]`}
      >
          {label}
        </label>
      ) : null}
      <div className="flex flex-col justify-center">
        {icon ? (
          <Icon className="absolute me-4 text-xl ms-4" name={icon} />
        ) : null}
        <input
          disabled={disabled}
          type={type}
          step="any"
          minLength={min}
          maxLength={max}
          name={name}
          style={style}
          {...(type === "number" && {
            onFocus: (e) =>
              e.target.addEventListener("wheel", (e) => {
                e.preventDefault();
              }, { passive: false }),
          })}
          className={`${inputClassName} text-xs ${icon ? "pl-11" : "pl-3"} ${
            rightIcon ? "pr-11" : "pr-3"
          } ${
            message ? "border-red-500" : ""
          } w-full text-ev-white placeholder-ev-secondary text-base h-10 bg-chatlook-secondary rounded outline-none border-[1px] border-[#222A40] focus:border-chatlook-secondary`}
          {...getRegister(register)}
          {...rest}
        />
        {rightIcon ? (
          <Icon className="absolute right-0 me-4 text-xl" name={rightIcon} onClick={righticonClick} />
        ) : null}
      </div>
      {message ? <span className="text-red-500 text-xs">{message}</span> : null}
    </div>
  );
};

export default Input;
