import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Loader } from "../../ui";
import { responseToaster } from "../../helperFunctions";
import { useUpdateBlackListUserStatusMutation } from "./userApi";
import { useDispatch } from "react-redux";
import { setUser } from "./userSlice";

const BlackListModal = (props: any) => {
  const { isOpen, userData, onClose, blackListPayload } = props;
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [updateBlackListUserStatus] = useUpdateBlackListUserStatusMutation();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    try {
      setIsLoadingStatus(blackListPayload?.userId);
      const res = await updateBlackListUserStatus(blackListPayload).unwrap();
      const index = userData?.data?.findIndex(
        (item: any) => item?.id === blackListPayload?.userId
      );
      if (index > -1) {
        const newData = [...userData.data];
        newData[index] = {
          ...newData[index],
          account_status: blackListPayload?.status ? 0 : 1,
        };
        dispatch(setUser({ ...userData, data: newData }));
      }
      responseToaster(res);
      setIsLoadingStatus(false);
      onClose();
    } catch (err) {
      setIsLoadingStatus(false);
      console.error("Error =-=>", err);
    }
  };

  const onHide = () => {
    reset();
    onClose();
  };

  const { handleSubmit, reset } = useForm<any>({});

  if (!isOpen) return null;

  return (
    <form
      className="fixed inset-0 z-50 flex items-center justify-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          onHide();
        }}
      />

      <div className="modal-container bg-white w-4/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12  md:w-7/12 sm:w-7/12 md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-9 px-6 text-center">
          <p className="text-lg">
            {!blackListPayload?.status
              ? "Are you sure you want to remove this user from the blacklist?"
              : "Are you sure you want to add this user to the blacklist?"}
          </p>
          <div className="flex justify-center pt-4 items-center gap-3">
            {!isLoadingStatus ? (
              <Button type="submit">Yes</Button>
            ) : (
              <div className="flex item-center justify-center">
                <Loader />
              </div>
            )}
            <Button variant="Transaparent" onClick={onHide}>
              No
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default BlackListModal;
