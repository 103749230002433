import { api } from "../../utills/api";

export const manualApi = api.injectEndpoints({
  endpoints: (build) => ({
    getManual: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/getManualDetails",
        body: params,
        method: "POST",
      }),
    }),
    addManual: build.mutation<any, any>({
      query: (params) => ({
        url: "/addManualDetails",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetManualMutation, useAddManualMutation } = manualApi;
export const {
  endpoints: { getManual, addManual },
} = manualApi;
