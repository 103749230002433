import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { depositApi } from "./depositApi";

interface DepositState {
  depositData: any;
}

const initialState: DepositState = {
  depositData: {},
};

export const depositSlice = createSlice({
  name: "deposit",
  initialState,
  reducers: {
    clearDeposit: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      depositApi.endpoints.getDeposit.matchFulfilled,
      (state, { payload }) => {
        state.depositData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default depositSlice.reducer;
export const { clearDeposit } = depositSlice.actions;

export const selectDepositData = (state: RootState) =>
  state.deposit.depositData;
export const useDepositData = () => {
  const depoData = useSelector(selectDepositData);
  return useMemo(() => depoData, [depoData]);
};
