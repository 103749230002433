import { useEffect, useState, Fragment, useRef } from "react";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { CiFilter } from "react-icons/ci";

// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  ControlledInput,
  Export,
  Icon,
  Select,
  Switch,
  Table,
  TableRow,
} from "../../ui";

// PROJECT IMPORT
import { dateFormatter, responseToaster } from "../../helperFunctions";
import {
  useGetPromotionMutation,
  useUpdateStatusMutation,
  useUpdatePromotionTypeMutation,
} from "./promotionApi";
import {
  setPromotionStatus,
  setCurrentPromotion,
  usePromotionData,
} from "./promotionSlice";
import { FILTER } from "../../constant";
import { useDomainIdData } from "../cashback/cashbackSlice";
import { useGetActiveDomainListQuery } from "../cashback/cashbackApi";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import DeletePromotionModal from "./DeletePromotionModal";
import PromotionModal from "./PromotionModal";

const initialValues = {
  domainId: "All",
};

const statusOptions = [
  {
    value: 1,
    label: "Active",
    icon: <FaCheck />,
  },
  {
    value: 0,
    label: "Deactive",
    icon: <RxCross2 />,
  },
];

const Promotion = () => {
  const promotionData = usePromotionData();
  const domainIdData = useDomainIdData();
  const [statusManualLoading, setStatusManualLoading] = useState<any>(false);
  const [isBothPromotionLoading, setIsBothPromotionLoading] = useState<any>(false);
  const [filter, setFilter] = useState(FILTER);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<any>(false);
  const [deleteData, setDeleteData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [getPromotion, { isLoading }] = useGetPromotionMutation();
  const [showDiv, setShowDiv] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [isPromotionModalOpen, setIsPromotionModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const isInitialMount = useRef(true);
  useGetActiveDomainListQuery(undefined, { refetchOnMountOrArgChange: true });
  const [updateStatus] = useUpdateStatusMutation();
  const [updateType] = useUpdatePromotionTypeMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const statusOption = [
  //   { value: 1, label: "Active", activeClassName: "green" },
  //   { value: 0, label: "Deactive", activeClassName: "red" },
  // ];

  // const onUpdateStatus = async (payload: any) => {
  //   try {
  //     setIsLoading(true);
  //     const res: any = await updateStatus(payload).unwrap();
  //     if (res) {
  //       setIsLoading(false);
  //       await onGetData();
  //     }
  //   } catch (err) {
  //     setIsLoading(false);

  //     console.error(err);
  //   }
  // };
  // const onDeleteStatus = async (payload: any) => {
  //   try {
  //     const res: any = await deleteData({ id: payload }).unwrap();
  //     showToaster(res?.message);
  //     await onGetData();
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const onUpdateStatusManual = async (payload: any) => {
    try {
      setStatusManualLoading(payload?.id);

      const res: any = await updateStatus(payload).unwrap();

      const index = promotionData?.data?.findIndex(
        (item: any) => item?.id === payload?.id
      );
      if (index > -1) {
        const newData = [...promotionData.data];
        newData[index] = {
          ...newData[index],
          status: payload?.status,
        };
        dispatch(setPromotionStatus({ ...promotionData, data: newData }));
      }
      responseToaster(res);

      setStatusManualLoading(false);
    } catch (err) {
      setStatusManualLoading(false);
    }
  };
  const onUpdateTypeManual = async (payload: any) => {
    try {
      setIsBothPromotionLoading(payload?.id);

      const res: any = await updateType(payload).unwrap();

      const index = promotionData?.data?.findIndex(
          (item: any) => item?.id === payload?.id
      );
      if (index > -1) {
        const newData = [...promotionData.data];
        newData[index] = {
          ...newData[index],
          is_both_promotion: payload?.is_both_promotion,
        };
        dispatch(setPromotionStatus({ ...promotionData, data: newData }));
      }
      responseToaster(res);

      setIsBothPromotionLoading(false);
    } catch (err) {
      setIsBothPromotionLoading(false);
    }
  };

  const route = useLocation()?.pathname;

  const columns = [
    {
      title: "#",
      sortable: false,
      name: "View",
      Cell: (data: any) => (
        <div className="relative">
          <Icon
            name="EyeOn"
            onClick={() => {
              setIsPromotionModalOpen(true);
              setCurrentData(data);
            }}
          />
        </div>
      ),
    },
    {
      title: "Domain Name",
      name: "domain_name",
      sortable: true,
      sortableKey: "domain_name",
    },
    {
      title: "Image",
      name: "image",
      Cell: (item: any) => {
        return (
          <div className="max-w-md mx-auto">
            <img
              src={item?.image}
              alt="banner"
              className="w-full h-48 object-cover rounded-md shadow-lg"
            />
          </div>
        );
      },
    },
    {
      title: "Name",
      name: "name",
      sortable: true,
      sortableKey: "name",
    },
    // {
    //   title: "Description",
    //   name: "description",
    // },
    // {
    //   title: "Type",
    //   name: "type",
    //   sortable: true,
    //   sortableKey: "type",
    // },
    // {
    //   title: "Promotion Type",
    //   name: "promotion_type",
    //   sortable: true,
    //   sortableKey: "promotion_type",
    // },
    // {
    //   title: "Promo Code",
    //   name: "promo_code",
    //   sortable: true,
    //   sortableKey: "promo_code",
    // },
    // {
    //   title: "Promotion Amt/Per",
    //   name: "promotion_amount",
    //   sortable: true,
    //   sortableKey: "promotion_amount",
    //   Cell: (item: any) => {
    //     return (
    //       <span>
    //         {item?.promotion_type === "percentage"
    //           ? `${item?.promotion_amount}%`
    //           : seperator(item?.promotion_amount)}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: "Date",
    //   name: "start_date",
    //   Cell: (item: any) => {
    //     return (
    //       <>
    //         <span><b>Date : </b>{item?.start_date?.slice(0, 11)}</span>
    //         <p><b>Time : </b>{item?.start_date?.slice(11)}</p>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Scheduled",
    //   name: "start_date",
    //   sortable: true,
    //   type: "date",
    //   sortableKey: "start_date",
    //   Cell: (item: any) => {
    //     return (
    //       <span>
    //         {dateFormatter(item?.start_date)}
    //         <br />
    //         <small>( {item?.start_date_readable} )</small>
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: "End Date",
    //   name: "end_date",
    //   sortable: true,
    //   type: "date",
    //   sortableKey: "end_date",
    //   Cell: (item: any) => {
    //     return (
    //         <span>
    //           {dateFormatter(item?.end_date)}
    //           <br/>
    //           <small>( {item?.end_date_readable} )</small>
    //         </span>
    //     );
    //   },
    // },
    // {
    //   title: "Expired",
    //   name: "expiry_date",
    //   sortable: true,
    //   type: "date",
    //   sortableKey: "expiry_date",
    //   Cell: (item: any) => {
    //     return (
    //       <span>
    //         {dateFormatter(item?.expiry_date)}
    //         <br />
    //         <small>( {item?.expiry_date_readable} )</small>
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: "Last Updated",
    //   name: "updated_at",
    //   Cell: (item: any) => {
    //     return (
    //         <span>
    //           {dateFormatter(item?.updated_at)}
    //           <br/>
    //           <small>( {item?.updated_at_readable} )</small>
    //         </span>
    //     );
    //   },
    // },
    {
      title: "Created",
      name: "created_at",
      Cell: (item: any) => {
        return (
          <span>
            {dateFormatter(item?.created_at)}
            <br />
            <small>( {item?.created_at_readable} )</small>
          </span>
        );
      },
    },
    {
      title: "Status",
      name: "status",
      // sortable: true,
      // sortableKey: "status",
      Cell: (data: any) => {
        return (
          <div>
            {/* <ToggleSwitch
              isLoading={isStatusLoading === data?.domain_id}
              value={data?.status ? 1 : 0}
              options={statusOption}
              onChange={async (value: any, e: any) => {
                e.stopPropagation();
                try {
                  const payload = {
                    status: value,
                    id: data?.id,
                  };
                  await onUpdateStatus(payload);
                } catch (err) {}
              }}
            /> */}
            <Switch
              checked={!!data?.status}
              isLoading={statusManualLoading === data?.id}
              onChange={async (value: any, e: any) => {
                e.stopPropagation();
                try {
                  const payload = {
                    status: value ? 1 : 0,
                    id: data?.id,
                  };
                  await onUpdateStatusManual(payload);
                } catch (err) {}
              }}
            />
            {/*<span className={`${getStatusTypes(data?.status)}`}>*/}
            {/*  {data?.status ? "Active" : "Deactive"}*/}
            {/*</span>*/}
          </div>
        );
      },
    },
        {
      title: "Main Promotion Show",
      name: "is_both_promotion",
      // sortable: true,
      // sortableKey: "status",
      Cell: (data: any) => {
        return (
          <div>
            {/* <ToggleSwitch
              isLoading={isStatusLoading === data?.domain_id}
              value={data?.status ? 1 : 0}
              options={statusOption}
              onChange={async (value: any, e: any) => {
                e.stopPropagation();
                try {
                  const payload = {
                    status: value,
                    id: data?.id,
                  };
                  await onUpdateStatus(payload);
                } catch (err) {}
              }}
            /> */}
            <Switch
                checked={!!data?.is_both_promotion}
                isLoading={isBothPromotionLoading === data?.id}
                onChange={async (value: any, e: any) => {
                  e.stopPropagation();
                  try {
                    const payload = {
                      is_both_promotion: value ? 1 : 0,
                      id: data?.id,
                    };
                    await onUpdateTypeManual(payload);
                  } catch (err) {}
                }}
            />
            {/*<span className={`${getStatusTypes(data?.status)}`}>*/}
            {/*  {data?.status ? "Active" : "Deactive"}*/}
            {/*</span>*/}
          </div>
        );
      },
    },

    {
      title: "Actions",
      name: "actions",
      export: false,
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            <Button
              className="!h-7 w-8 !p-0"
              onClick={() => {
                dispatch(setCurrentPromotion(item));
                navigate(`${route}/${item?.id}`);
              }}
            >
              <MdEdit />
            </Button>
            <Button
              className="!h-7 w-8 !p-0"
              variant="Transaparent"
              onClick={() => {
                setIsDeleteModalOpen(true);
                setDeleteData(item);
              }}
            >
              <MdDelete />
            </Button>
          </div>
        );
      },
    },
  ];

  const { register, setValue, handleSubmit, reset, getValues, control } =
    useForm<any>({
      defaultValues: {
        ...initialValues,
      },
    });

  const onGetData = async () => {
    try {
      const values = getValues();
      const payload: any = {
        ...filter,
        filter_data: {
          searchData: searchTerm,
          category: "promotion",
          domainId: values?.domainId,
          type: values?.type,
          status: values?.status,
          createdStartDate: dateFormatter(values?.createdStartDate?.[0],"date"),
          createdEndDate: dateFormatter(values?.createdStartDate?.[1], "date"),
          startDate: dateFormatter(values?.startDate?.[0], "date"),
          endDate: dateFormatter(values?.startDate?.[1], "date"),
          endStartDate: dateFormatter(values?.endDate?.[0], "date"),
          endEndDate: dateFormatter(values?.endDate?.[1], "date"),
        },
      };
      delete payload["isFilter"];
      const res: any = await getPromotion(payload);
      if (filter?.isFilter) {
        responseToaster(res?.data);
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setTableData(promotionData?.data?.length ? [...promotionData?.data] : []);
  }, [promotionData]);

  const onSubmit = async () => {
    setFilter({ ...FILTER, isFilter: true });
  };

  const prepareDomainOptions = () => {
    const options: any = domainIdData?.data?.map((val: any) => {
      return {
        value: val?.id,
        label: val?.domain_name,
      };
    });
    return options?.length ? [{ value: "All", label: "All" }, ...options] : [];
  };

  // const prepareCampaignOptions = () => {
  //   const options: any = promotionData?.type?.map((val: any) => {
  //     return {
  //       value: val?.type,
  //       label: val?.type,
  //     };
  //   });
  //   return options?.length ? [{ value: "All", label: "All" }, ...options] : [];
  // };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const debounceFunction = setTimeout(() => {
      setFilter({ ...filter, page_no: 1 });
    }, 1000);

    return () => clearTimeout(debounceFunction);
    
    // eslint-disable-next-line
  }, [searchTerm]);

  return (
    <>
      <div className="">
        <div className="cardcss mb-3 flex items-center justify-between  pb-3">
          <ControlledInput
            name="searchData"
            className="w-100"
            rightIcon={searchTerm !== "" ? "Closeicon" : null}
            righticonClick={() => setSearchTerm("")}
            placeholder="Enter search value"
            control={control}
            value={searchTerm}
            type="text"
            autoComplete="off"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
          <div className="flex gap-2">
            <Button
              type="button"
              variant="Transaparent"
              onClick={() => {
                setShowDiv(!showDiv); // Toggle the value
              }}
            >
              <CiFilter className="text-lg" /> Apply Filter
            </Button>
            <Button
              type="button"
              onClick={() => {
                navigate(`${route}/create`);
              }}
            >
              Add Promotion
            </Button>
            <Export
              variant="Transaparent"
              filename="download.csv"
              data={promotionData?.data || []}
              headers={
                columns
                  ?.filter((item: any) => item?.export !== false)
                  ?.map((val) => ({
                    label: val?.title,
                    key: val?.name,
                  })) || []
              }
            >
              Export
            </Export>
          </div>
        </div>

        {showDiv && (
          <div className="cardcss mb-3">
            <form
              className="flex flex-wrap gap-2 items-end"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Select
                name="domainId"
                label="Domain"
                options={prepareDomainOptions()}
                register={register}
              />
              {/*<Select*/}
              {/*  name="type"*/}
              {/*  label="Type"*/}
              {/*  options={prepareCampaignOptions()}*/}
              {/*  register={register}*/}
              {/*/>*/}
              <ControlledDatePicker
                inputClassName="!w-52"
                name="createdStartDate"
                label="Promotion Create Date"
                placeholder="Select Create Date"
                selectsRange
                maxDate={new Date()}
                control={control}
              />
              <ControlledDatePicker
                name="startDate"
                label="Promotion Start Date"
                placeholder="Select Promotion Start Date"
                selectsRange
                control={control}
              />
              <ControlledDatePicker
                name="endDate"
                label="Promotion End Date"
                placeholder="Select Promotion End Date"
                selectsRange
                control={control}
              />
              <Select
                name="status"
                label="Status"
                placeholder="All"
                options={statusOptions}
                register={register}
              />
              <Button type="submit" className="!h-10 !text-sm">
                Apply
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm !h-10 "
                onClick={() => {
                  reset();
                  setFilter({ ...FILTER, isFilter: true });
                  isInitialMount.current = true;
                  setSearchTerm("");
                  setValue("searchData", "");
                }}
              >
                Clear
              </Button>
            </form>
          </div>
        )}

        <div>
          {/* <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-2 gap-4 mainbox">
            {summaryData?.map((item: any, index) => {
              return (
                <Fragment key={index}>
                  <Card
                    length={summaryData.length}
                    index={index}
                    value={promotionData?.summary?.[item.key]}
                    text={item?.text}
                    icon={item?.imgSrc}
                    rupee={item?.rupee}
                  />
                </Fragment>
              );
            })}
          </div> */}
          <div className="App tableborder bg-chatlook-white">
            <Table
              columns={columns}
              isLoading={isLoading}
              data={promotionData?.data}
              setData={setTableData}
              isExpendable={false}
              count={promotionData?.total_item || 0}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      item={item}
                      isExpendable={false}
                    />
                  </Fragment>
                );
              })}
            </Table>
          </div>
        </div>
      </div>
      <PromotionModal
        isOpen={isPromotionModalOpen}
        data={currentData}
        onClose={() => {
          setIsPromotionModalOpen(false);
          setCurrentData({});
        }}
      />
      <DeletePromotionModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setDeleteData({});
        }}
        onGet={() => setFilter({ ...filter, isFilter: false })}
        tobeDeleteData={deleteData}
      />
    </>
  );
};

export default Promotion;
