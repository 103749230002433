import {  useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Icon, Loader } from "../../ui";
import { useUploadUserCsvFileMutation } from "./userApi";
import { processCSV, showToaster } from "../../helperFunctions";
import { CSVLink } from "react-csv";

const UploadUserCsvModal = (props: any) => {
  const [fileUpload, setFileUpload] = useState<any>({});
  const [uploadUserCsvFile] = useUploadUserCsvFileMutation();
  const { handleSubmit } = useForm({});
  const { isOpen, onClose, onGet, setShowMsg } = props;
  const [isLoader, setIsLoader] = useState(false);
  const [csvData, setCsvData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const onHide = () => {
    setFileUpload({});
    onClose();
    setCsvData([]);
    setData([]);
  };
  const onSubmit = async (e: any) => {
    setIsLoader(true);
    const bulkUsersUpload = new FormData();
    bulkUsersUpload.append("blockUsers", fileUpload);
    try {
      const res: any = await uploadUserCsvFile(bulkUsersUpload).unwrap();
      if (res?.status === true) {
        showToaster(res?.message);
        setShowMsg(true);
        onGet();
        setIsLoader(false);
        onHide();
      } else {
        setIsLoader(false);
        onHide();
      }
    } catch (err: any) {
      setIsLoader(false);
      onHide();
    }
  };
  const sampleCSVData = [["player_id"], [123456789], [123456780]];

  if (!isOpen) return null;
  return (
    <form
      className="fixed inset-0 z-50 flex items-center justify-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          onHide();
        }}
      />

      <div className="modal-container bg-white w-5/12 md:w-8/12 sm:w-10/12 md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content p-5 text-left overflow-scroll max-h-[98vh] bg-chatlook-primary border rounded-[8px]">
          <div className="pb-3 text-center">
            <div className="relative">
              <p className="text-2xl  font-bold text-ev-secondary pb-2">
                {csvData?.data?.length ? "" : "Upload CSV File"}
              </p>
              {csvData?.data?.length ? null : (
                <CSVLink
                  data={sampleCSVData}
                  filename="sample.csv"
                  className="rounded h-10 flex items-center justify-center bg-chatlook-darksky absolute right-0 top-0 px-3 text-ev-white"
                >
                  Sample File
                </CSVLink>
              )}
            </div>
            {csvData?.data?.length ? (
              <p className=" text-2xl  text-ev-secondary">
                Are you sure you want to Block these user?
              </p>
            ) : (
              <p className=" text-sm font-bold text-ev-secondary pb-2">
                Upload and attach files to this Option.
              </p>
            )}
          </div>

          {csvData?.data?.length ? null : (
            <div className="flex flex-col justify-center items-center drag-file-area rounded-lg ">
              <div className="text-center py-4 px-5 mt-2.5 mb-3.5">
                <label className="label">
                  <div>
                    <Icon name="UploadIcon" className="m-auto" />
                  </div>
                  {/* <span className="browse-files"> */}
                  <input
                    // id="myfile"
                    accept="text/csv"
                    type="file"
                    className="opacity-0"
                    onChange={(e: any) => {
                      try {
                        const file = e.target.files?.[0];
                        setFileUpload(file);
                        const reader = new FileReader();
                        reader.onload = (e: any) => {
                          const res: any = processCSV(e.target.result);
                          const rows = e.target.result.split("\n");
                          const parsedData = rows.map((row: any) =>
                            row.split(",")
                          );
                          setData(parsedData);
                          setCsvData(res);
                        };
                        reader.readAsText(file);
                      } catch (err) {
                        console.error("Error =-=>", err);
                      }
                    }}
                  />
                  <div className="browse-files-text text-ev-secondary text-[18px] ">
                    Click to upload CSV File
                  </div>
                  <div className="text-ev-secondary">
                    {fileUpload ? fileUpload?.name : null}
                  </div>
                  {/* </span> */}
                </label>
              </div>
            </div>
          )}

          {/*  <div
            className={
              csvData?.data?.length ? "overflow-y-scroll h-[170px] my-4" : ""
            }
          >
            {/* <h2 className="text-ev-secondary">CSV Data:</h2> * /}
            <ul className="text-ev-primary ]">
              {csvData?.header?.map((row: any, index: any) => {
                return (
                  <table>
                    <thead>
                      <li key={index}>{row || ""}</li>
                    </thead>
                  </table>
                );
              })}
              {csvData?.data?.map((row: any, index: any) => {
                const text = JSON.stringify(Object.values(row)?.[0] || "");
                return (
                  <table>
                    <thead>
                      <li key={index} className="text-ev-secondary">
                        {text?.slice(1, text?.length - 1) || ""}
                      </li>
                    </thead>
                  </table>
                );
              })}
            </ul>
          </div> */}
          <table className="border-collapse border border-gray-700 m-auto">
            <thead>
              <tr className="bg-gray-800">
                {data[0] &&
                  data[0].map((item: any, index: number) => (
                    <th
                      key={index}
                      className="text-ev-secondary px-4 py-2 border"
                    >
                      {item}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {data?.map(
                (row: any, rowIndex: number) =>
                  rowIndex !== 0 && (
                    <tr
                      key={rowIndex}
                      className={
                        rowIndex % 2 === 0 ? "bg-gray-800" : "bg-gray-900"
                      }
                    >
                      {row.map((item: any, itemIndex: number) =>
                        item?.length ? (
                          <td
                            key={itemIndex}
                            className="text-ev-secondary px-4 py-2 border"
                          >
                            {item}
                          </td>
                        ) : null
                      )}
                    </tr>
                  )
              )}
            </tbody>
          </table>
          <div className="flex justify-end pt-2 items-center gap-3">
            <Button variant="Transaparent" onClick={onHide}>
              Close
            </Button>
            {!isLoader ? (
              <Button
                type="submit"
                // className="px-4 bg-indigo-500 p-3 rounded-lg text-white hover:bg-indigo-400"
              >
                Block
              </Button>
            ) : (
              <div className="flex item-center justify-center">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default UploadUserCsvModal;
