import { useEffect, useState, Fragment } from "react";
import { MdEdit } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

// UI IMPORT
import { Button, Table, TableRow } from "../../ui";

// PROJECT IMPORT
import { useGetPermissionsMutation } from "./permissionApi";
import { responseToaster } from "../../helperFunctions";
import { FILTER } from "../../constant";
import { setCurrentPermission, usePermissions } from "./permissionSlice";
import { useAppDispatch } from "../../utills/hooks";
import { useUser } from "../auth/authSlice";

const Permission = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const route = useLocation()?.pathname;

  const user = useUser();
  const permissions: any = usePermissions();

  const [filter, setFilter] = useState(FILTER);
  const [tableData, setTableData] = useState<any>([]);

  const [getPermissions, { isLoading }] = useGetPermissionsMutation();

  const columns: any[] = [
    {
      title: "ID",
      name: "id",
      sortable: true,
      sortableKey: "id",
    },
    {
      title: "Group Name",
      name: "group_name",
      sortable: true,
      sortableKey: "group_name",
    },
    {
      title: "Name",
      name: "name",
      sortable: true,
      sortableKey: "name",
    },
  ];

  if (user?.access_level?.PERMISSION_EDIT) {
    columns.push({
      title: "Actions",
      name: "actions",
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            <Button
              className="!h-7 w-8 !p-0"
              title="Edit"
              onClick={() => {
                dispatch(setCurrentPermission(item));
                navigate(`${route}/${item?.id}`);
              }}
            >
              <MdEdit />
            </Button>
          </div>
        );
      },
    });
  }

  const onGetData = async () => {
    try {
      const { isFilter, ...rest } = filter;
      const res: any = await getPermissions({
        ...rest,
      });
      if (filter?.isFilter) {
        responseToaster(res?.data);
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setTableData(
      permissions?.data?.data?.length ? [...permissions?.data?.data] : []
    );
  }, [permissions]);

  return (
    <>
      <div className="">
        {user?.access_level?.PERMISSION_CREATE ? (
          <div className="flex items-centere justify-between pb-3">
            <Button
              type="button"
              onClick={() => {
                dispatch(setCurrentPermission(null));
                navigate(`${route}/create`);
              }}
            >
              Add Permission
            </Button>
          </div>
        ) : null}

        <div>
          <div className="App tableborder bg-chatlook-white">
            <Table
              columns={columns}
              isLoading={isLoading}
              data={tableData}
              isExpendable={false}
              count={permissions?.total_item || 0}
              pagination={filter}
              setData={setTableData}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      item={item}
                      isExpendable={false}
                    />
                  </Fragment>
                );
              })}
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Permission;
