// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { endpoint, getState }) => {
      if (endpoint !== "login") {
        const state: any = getState();
        headers.set("Authorization", `Bearer ${state.auth.user?.token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: ["Bonus","Loyalty"],
});
