import React, { CSSProperties, PropsWithChildren } from "react";

interface TableBodyProps extends PropsWithChildren<{}> {
  className?: string;
  style?: CSSProperties;
}

const TableBody = (props: TableBodyProps) => {
  const { className = "", style = {}, children } = props;
  return (
    <tbody className={`${className} text-sm font-normal text-ev-primary	`} style={{ ...style }}>
      {children}
    </tbody>
  );
};

export default TableBody;
