import { api } from "../../utills/api";

export const depositApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDeposit: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/deposit",
        body: params,
        method: "POST",
      }),
    }),

  }),
});

export const { useGetDepositMutation } =
  depositApi;
export const {
  endpoints: { getDeposit },
} = depositApi;
