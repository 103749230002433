import { api } from "../../utills/api";

export const bonusHistoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBonusHistory: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/getAllBonusData",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetBonusHistoryMutation,
} = bonusHistoryApi;
export const {
  endpoints: {
    getBonusHistory,
  },
} = bonusHistoryApi;
