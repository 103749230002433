// UI IMPORT
import { KeyboardEvent } from "react";
import { useRef } from "react";
import { Icon, IconKeys } from "../icons";

// THIRD - PARTY IMPORT
import Picker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface DatePickerProps {
  className?: string;
  inputClassName?: string;
  name?: any;
  label?: string;
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
  selectesStart?: boolean;
  selectsEnd?: boolean;
  selectsRange?: boolean;
  showTimeSelect?: boolean;
  timeFormat?: string;
  dateFormat?: string;
  onChange?: (dates?: any) => void;
  icon?: IconKeys;
  rightIcon?: IconKeys;
  placeholder?: string;
  disabled?: any;
  errors?: any;
  startDate?: any;
  endDate?: any;
  clearDate?: boolean;
}

const DatePicker = (props: DatePickerProps) => {
  const {
    className = "",
    inputClassName = "",
    label,
    icon,
    rightIcon,
    errors,
    value,
    placeholder = "Enter Date",
    name,
    dateFormat = "dd/MM/yyyy",
    onChange,
    ...rest
  } = props;

  const calRef: any = useRef();
  const message = errors?.[name]?.message || "";

  return (
    <div className={`${className}  flex flex-col relative`}>
      {label ? (
        <label className="text-ev-secondary text-sm pb-[4px]">{label}</label>
      ) : null}
      {icon ? (
        <Icon className="absolute me-4 text-xl ms-4" name={icon} />
      ) : null}
      <Picker
        className={`${inputClassName} text-xs  ${icon ? "pl-11" : "pl-3"} ${
          rightIcon ? "pr-11" : "pr-3"
        } ${
          message ? "border-red-500" : ""
        } w-full text-ev-white placeholder-ev-secondary text-base bg-chatlook-secondary h-10 rounded-md  outline-none border-[1px] border-[#222A40] focus:border-chatlook-secondary `}
        placeholderText={placeholder}
        dateFormat={dateFormat}
        selected={!Array.isArray(value) ? value : null}
        startDate={Array.isArray(value) ? value?.[0] : null}
        endDate={Array.isArray(value) ? value?.[1] : null}
        onChange={(dates) => {
          onChange?.(dates);
        }}
        ref={calRef}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Backspace" || e.key === "Delete") {
            onChange?.([null, null]);
          }
        }}
        shouldCloseOnSelect={false}
        {...rest}
      >
        <div className="action-button-wrapper">
          <button
            className="action-button"
            onClick={() => {
              calRef.current.setOpen(false);
            }}
          >
            Apply
          </button>
        </div>
      </Picker>
      {rightIcon ? (
        <Icon className="absolute right-0 me-4 text-xl" name={rightIcon} />
      ) : null}
      {message ? <span className="text-red-500 text-sm">{message}</span> : null}
    </div>
  );
};

export default DatePicker;
