import { api } from "../../utills/api";

export const supportlogApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSupportlog: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/getSupportLogs",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetSupportlogMutation,
} = supportlogApi;

export const {
  endpoints: {
    getSupportlog,
  },
} = supportlogApi;
