import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Icon, Loader, Select, Switch } from "../../ui";
import {responseToaster, showToaster} from "../../helperFunctions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  useAddCustomAmountMutation, useGetActiveConversionBonusListMutation,
} from "./userApi";
import {useDispatch} from "react-redux";
import {useBonusListOption} from "./userSlice";
import {getValue} from "@testing-library/user-event/dist/utils";
const typeOptions = [
  {
    value: "bonus",
    label: "Bonus",
  },
  {
    value: "cashback",
    label: "Cashback",
  },
];

const defaultWalletTypeOptions = [
  {
    value: "sports",
    label: "Sports (INR 0)",
  },
  {
    value: "casino",
    label: "Casino (INR 0)",
  },
];

const CustomAmountModal = (props: any) => {
  const { isOpen, onClose, onGet, tobeCustomData, amountMethod } = props;
  const [isLoader, setIsLoader] = useState(false);
  const [walletTypeOptions, setWalletTypeOptions] = useState(defaultWalletTypeOptions);
  const [addCustomAmount] = useAddCustomAmountMutation();
  const [getActiveConversionBonusList] = useGetActiveConversionBonusListMutation();
  const bonusListOption = useBonusListOption();
  const schema = yup.object({
    amount_type: yup.string().required("Select amount type"),
    wallet_type: yup
        .string()
        .when("amount_type", {
          is: (value: any) => {
            return value === "bonus";
          },
          then: () => yup.string().required("Select wallet type"),
          otherwise: (yup) => yup.notRequired().optional(),
        }),
    bonus_id: yup
        .string()
        .when("amount_type", {
          is: (value: any) => {
            return value === "bonus";
          },
          then: () => yup.string().required("Select Campaign"),
          otherwise: (yup) => yup.notRequired().optional(),
        }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    resetField,
    formState: { errors },
    control,
    watch,
  }: any = useForm<any>({
    resolver: yupResolver<any>(schema),
    // defaultValues: {
    //     ...prepareDefaultValues(),
    // },
  });

  const prepareBonusOptions = () => {
    const options = bonusListOption?.data?.map((val: any) => {
      return {
        value: JSON.stringify({id: val?.id, wallet_per: val?.wallet_per}),
        label: val?.name + ' (' + val?.promo_code + ')',
      };
    });
    return options;
  };

  const onSubmit = async (values: any) => {
    let bonus_id = null;
    if (values?.bonus_id) {
      bonus_id = JSON.parse(values?.bonus_id).id;
    }
    const params = {
      type: amountMethod,
      id: tobeCustomData.id,
      bonus_id: bonus_id,
      amount_type: values?.amount_type,
      wallet_type: values?.wallet_type,
      amount: tobeCustomData.amount,
      is_notify: values?.is_notify,
      description: values?.description,
    };
    setIsLoader(true);

    try {
      if (tobeCustomData?.id) {
        const res: any = await addCustomAmount(params).unwrap();
        showToaster(res?.message);
        await onGet();
      }
      setIsLoader(false);
      onGet();
      onHide();
    } catch (err: any) {
      console.error("Error =-=>", err);
      setIsLoader(false);
    }
  };

  const onHide = () => {
    reset();
    onClose();
  };

  if (!isOpen) return null;

  const onGetData = async () => {
    try {
      const playerId = tobeCustomData?.id || null;
      const payload: any = {
        filter_data: {
          player_id: playerId
        },
      };
      await getActiveConversionBonusList(payload)
    } catch (err) {
    } finally {
    }
  };
  return (
    <form
      className="fixed inset-0 z-50 flex items-center justify-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          onHide();
        }}
      />

      <div className="modal-container bg-chatlook-secondary w-4/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12  md:w-7/12 sm:w-7/12 md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-9 px-6 border rounded-[8px]">
          <div className="text-lg font-semibold text-ev-green">
            <h4>
              {amountMethod === "deposit" ? "Deposit" : "Withdrawal"} Details
            </h4>
          </div>
          <div className="grid items-start gap-3 pb-3">
            <div className="flex gap-1">
              <Icon name="Rupee" width={10} height={7} />
              <p className="text-ev-secondary font-medium">
                {tobeCustomData.amount}
              </p>
            </div>
            <Select
              className="!w-full"
              name="amount_type"
              placeholder="Select Amount Type"
              options={typeOptions}
              register={register}
              errors={errors}
              onChange={(e) => {
                const value = e.target.value;
                setValue("amount_type", value);
                if (value === "bonus") {
                  onGetData();
                  resetField("wallet_type");
                  setWalletTypeOptions(defaultWalletTypeOptions);
                  setValue("bonus_id", null);
                }
              }}
            />
            {watch("amount_type") === "bonus" ? (
              <div className="textareacss">
                <Select
                  className="!w-full"
                  name="bonus_id"
                  placeholder="Select Campaign"
                  options={prepareBonusOptions()}
                  register={register}
                  errors={errors}
                  onChange={(e) => {
                    if(e.target.value) {
                      const value = JSON.parse(e.target.value);
                      setWalletTypeOptions(value.wallet_per);
                    } else {
                      setWalletTypeOptions(defaultWalletTypeOptions);
                      setValue("bonus_id", null);
                    }
                    resetField("wallet_type");
                  }}
                />
              </div>
            ) : null}
            {watch("amount_type") === "bonus" ? (
                <div className="textareacss">
                  <Select
                      className="!w-full"
                      name="wallet_type"
                      placeholder="Select Wallet"
                      options={walletTypeOptions}
                      register={register}
                      errors={errors}
                      onChange={(e) => {
                        const value = e.target.value;
                        setValue("wallet_type", value);
                      }}
                  />
                </div>
            ) : null}
            <div className="flex items-center gap-2">
              <Controller
                name="is_notify"
                control={control}
                render={({ field }) => (
                  <Switch
                    checked={field.value === 1}
                    onChange={(checked: any) =>
                      setValue("is_notify", checked ? 1 : 0)
                    }
                  />
                )}
              />
              <label className="text-ev-secondary text-sm">Is Notify?</label>
            </div>
            {watch("is_notify") ? (
              <div className="textareacss">
                <label className={`text-sm text-ev-secondary pb-[4px]`}>
                  Description
                </label>
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <textarea
                      className="p-2 w-full text-base h-10 rounded outline-none border-[1px] border-[#D0D5DD] focus:border-chatlook-secondary"
                      placeholder="Enter Description"
                      {...field}
                    />
                  )}
                />
              </div>
            ) : null}
          </div>
          <div className="flex pt-4 justify-end gap-3">
            {!isLoader ? (
              <Button type="submit">Confirm</Button>
            ) : (
              <div className="flex item-center justify-center">
                <Loader />
              </div>
            )}
            <Button variant="Transaparent" onClick={onHide}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CustomAmountModal;
