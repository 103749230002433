import { Fragment, useEffect, useState } from "react";

// UI IMPORT
import { Icon } from "../../ui";

// PROJECT IMPORT
import { toggleDrawer, useIsDrawerOpen } from "../common/commonSlice";
import { useAppDispatch } from "../../utills/hooks";
import { useUser } from "../auth/authSlice";
import { useMedia } from "../../components";
import SideBarMenu from "./SideBarMenu";
import { MdClear } from "react-icons/md";

const Sidebar = () => {
  const user = useUser();
  const isDrawerOpen = useIsDrawerOpen();
  const { media } = useMedia();

  const dispatch = useAppDispatch();

  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleOpenSidebar = () => {
    dispatch(toggleDrawer(true));
  };

  useEffect(() => {
    dispatch(toggleDrawer(media.width < 992 ? false : true));
    // eslint-disable-next-line
  }, [media]);

  return (
    <>
      <div className="mainSidebar">
        <div
          className={`sideBar relative whitespace-nowrap ${
            isDrawerOpen ? "w-[220px]" : "w-[75px]"
          } ${media.width < 768 && !isDrawerOpen ? "hidden" : ""}`}
        >
          <div className="sideBarLogo boxCenter">
            {isDrawerOpen ? (
              <div className="flex justify-between">
                <h4 className="text-xl font-semibold text-ev-green">
                  BONUS PANEL
                </h4>
                {/* <Icon className="w-36" name="LogoLastIcon" /> */}
                <Icon
                  className="w-6"
                  name="MenuSidebar"
                  onClick={() => {
                    dispatch(toggleDrawer());
                  }}
                />
              </div>
            ) : (
              <div className="flex justify-center">
                <Icon
                  className="w-6"
                  name="MenuSidebar"
                  onClick={() => {
                    dispatch(toggleDrawer());
                  }}
                />
              </div>
            )}
          </div>

          <div
            className={`${
              media.width <= 992 || !isDrawerOpen
                ? `flex justify-center py-[10px] ${
                    media.width <= 992 && isDrawerOpen
                      ? "serchsec px-[10px]"
                      : ""
                  }`
                : "serchsec px-[10px]"
            } `}
          >
            {(media.width >= 992 && isDrawerOpen) ||
            (media.width <= 992 && isDrawerOpen) ? (
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleChange}
              />
            ) : null}
            <Icon name="SearchIcon" onClick={handleOpenSidebar} />
            {searchTerm !== "" ? (
              <span onClick={()=>setSearchTerm("")}>
                <MdClear className="cancelbtn" />
              </span>
            ) : (
              ""
            )}
          </div>

          {/* ======= Navigation ======= */}
          <div className="navigation mt-4">
            <nav>
              {user?.sidebar
                ?.filter((res: any) =>
                  res.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
                ?.map((item: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      <SideBarMenu
                        name={item?.name}
                        route={item?.route}
                        icon_class={item?.icon_class}
                        onClick={() => {
                          if (media.width < 768) {
                            dispatch(toggleDrawer(false));
                          }
                        }}
                      />
                    </Fragment>
                  );
                })}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
