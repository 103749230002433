import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useEffect } from "react";

// UI IMPORT
import {
  Button,
  CheckBox,
  ControlledDropDown,
  Input,
  Loader,
  Table,
  TableRow,
} from "../../ui";

// PROJECT IMPORT
import {
  useAddRoleMutation,
  useGetRolesByGroupQuery,
  useUpdateRoleMutation,
} from "./roleApi";
import { setCurrentRole, useCurrentRole, useRolesByGroup } from "./roleSlice";
import { showToaster } from "../../helperFunctions";
import { useAppDispatch } from "../../utills/hooks";

// THIRD - PARTY IMPORT
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDomainIdData } from "../cashback/cashbackSlice";
import { useGetActiveDomainListQuery } from "../cashback/cashbackApi";

const RoleForm = () => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentRole = useCurrentRole();
  const rolesByGroup = useRolesByGroup();
  const domainIdData: any = useDomainIdData();

  const [addRole, { isLoading: isAddLoading }] = useAddRoleMutation();
  const [updateRole, { isLoading: isUpdateLoading }] = useUpdateRoleMutation();
  const { isLoading } = useGetRolesByGroupQuery();
  useGetActiveDomainListQuery(undefined, { refetchOnMountOrArgChange: true });

  const prepareDomainOptions = () => {
    const options =
      domainIdData?.data?.map((val: any) => {
        return {
          value: val?.id,
          label: val?.domain_name,
        };
      }) || [];

    return [
      { value: "All", label: "All" },
      ...(options?.length ? options : []),
    ];
  };

  const columns = [
    {
      title: "Name",
      name: "name",
      Cell: (item: any) => {
        return (
          <div className="flex flex-col items-start gap-2 flex-wrap">
            {/* <CheckBox
              label={item?.name}
              checked={permissions?.includes(item?.name)}
              onChange={(e) => {}}
            /> */}
            {item?.name}
          </div>
        );
      },
    },
    {
      title: "Permissions",
      name: "per",
      className: "!max-w-[300px]",
      Cell: (item: any) => {
        return (
          <div className="flex flex-col items-start gap-2 flex-wrap">
            {item?.list?.map((subItem: any) => {
              return (
                <>
                  <CheckBox
                    label={subItem?.name}
                    checked={!!watch("permissions")?.includes(subItem?.name)}
                    onChange={(e) => {
                      const permissions = watch("permissions");
                      if (!permissions?.includes(subItem?.name)) {
                        const items = [...permissions];
                        items.push(subItem?.name);
                        setValue("permissions", items);
                      } else {
                        setValue("permissions", [
                          ...(permissions?.filter(
                            (i: any) => i !== subItem?.name
                          ) || []),
                        ]);
                      }
                    }}
                  />
                </>
              );
            })}
          </div>
        );
      },
    },
  ];

  const schema = yup.object({
    name: yup.string().required("Please enter a name"),
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  }: any = useForm<any>({
    resolver: yupResolver<any>(schema),
    defaultValues: {
      ...(id
        ? {
            name: currentRole?.name,
            domain_id:
              Array.isArray(currentRole?.domain_id) && currentRole?.domain_id
                ? currentRole?.domain_id
                : [],
            permissions: currentRole?.per?.map((item: any) => item?.name) || [],
          }
        : { permissions: [] }),
    },
  });

  const onSubmit = async (values: any) => {
    try {
      if (id) {
        const res: any = await updateRole({
          ...values,
          id: id,
        }).unwrap();
        showToaster(res?.message);
        onBack();
      } else {
        const res: any = await addRole({ ...values }).unwrap();
        showToaster(res?.message);
        onBack();
      }
    } catch (err) {}
  };

  const onBack = () => {
    dispatch(setCurrentRole(null));
    navigate(-1);
  };

  useEffect(() => {
    if (id) {
      if (+currentRole?.id !== +id) {
        showToaster("Something Went Wrong!", "Error");
        onBack();
      }
    } else {
      dispatch(setCurrentRole(null));
    }
    return () => {
      dispatch(setCurrentRole(null));
    };
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col space-y-5"
        >
          <div className="cardcss">
            <div className="grid grid-cols-3 items-start gap-3 pt-2">
              <Input
                name="name"
                placeholder="Enter Name"
                className="!w-full"
                label="Name"
                register={register}
                errors={errors}
              />
              <ControlledDropDown
                name="domain_id"
                label="Domain"
                isMulti
                isClearable={true}
                options={prepareDomainOptions()}
                control={control}
                errors={errors}
                onChange={(e: any) => {
                  if (e?.includes("All")) {
                    setValue(
                      "domain_id",
                      domainIdData?.data?.map((item: any) => item?.id)
                    );
                  } else {
                    setValue("domain_id", e);
                  }
                }}
              />
            </div>
            <div className="App tableborder bg-chatlook-white">
              <Table
                columns={columns}
                isLoading={isLoading}
                data={rolesByGroup}
                isExpendable={false}
              >
                {rolesByGroup?.map((item: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      <TableRow
                        columns={columns}
                        item={item}
                        isExpendable={false}
                      />
                    </Fragment>
                  );
                })}
              </Table>
            </div>
          </div>
          <div className="flex items-center justify-end gap-2">
            <Button type="submit" className="mt-[10px] !h-9 !text-sm">
              {isAddLoading || isUpdateLoading ? (
                <Loader loaderClassName="create-edit-bonus" />
              ) : id ? (
                "Update"
              ) : (
                "Add"
              )}
            </Button>
            <Button
              variant="Transaparent"
              className="mt-[10px]  !text-sm !h-9"
              onClick={() => {
                reset();
              }}
            >
              Clear
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default RoleForm;
