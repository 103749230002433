import ReactSwitch from "react-switch";

export interface SwitchProps {
  name?: string;
  label?: string;
  checked?: boolean;
  onChange?: (value?: any, e?: any) => void;
  isLoading?: boolean;
  className?: string;
  isDisabled?: boolean;
}

const Switch = (props: SwitchProps) => {
  const {checked, label, onChange, isLoading = false, className = "", isDisabled = false} = props;
  return (
    <div className={`flex items-center ${className}`}>
      {isLoading ? (
        <div className="loading-dots">
          <div className="loading-dot"></div>
          <div className="loading-dot"></div>
          <div className="loading-dot"></div>
        </div>
      ) : (
        <div className={`${className} select-div flex justify-center gap-2`}>
          {label ? (
            <label className={`text-sm text-ev-secondary pb-[4px]`}>
              {label}
            </label>
          ) : null}
          <ReactSwitch
            onColor="#3A55EB"
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={40}
            checked={!!checked}
            disabled={isDisabled}
            onChange={(value, e) => {
              onChange?.(value, e);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Switch;
