import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { bonusHistoryApi } from "./bonusHistoryApi";

interface BonusHistoryState {
  items:any
}

const initialState: BonusHistoryState = {
  items:{}
};

export const bonusHistorySlice = createSlice({
  name: "bonusHistory",
  initialState,
  reducers: {
    clearBonusHistory: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bonusHistoryApi.endpoints.getBonusHistory.matchFulfilled,
      (state, { payload }) => {
        state.items = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default bonusHistorySlice.reducer;
export const { clearBonusHistory } = bonusHistorySlice.actions;

export const selectBonusHistoryItems = (state: RootState) =>
  state.bonusHistory.items;
export const useBonusHistoryItems = () => {
  const items = useSelector(selectBonusHistoryItems);
  return useMemo(() => items, [items]);
};
